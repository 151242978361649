import React, { Component } from 'react';

import './css/ProgresoCard.css';

class EstadoCard extends Component {

  /*constructor(props) {
    super(props);

  }*/

  render() {

    //Color Estado
    let colorEstado

    if(this.props.proyecto.estado === "OS"){
      colorEstado = "#2AE846"
    }else if(this.props.proyecto.estado === "BS"){
      colorEstado = "#FF6247"
    }else if(this.props.proyecto.estado === "AS"){
      colorEstado = "#57D6FF"
    }

    return (
      <div className="progresoCard col-12" style={{marginTop: '50px'}}>
        <div className="card">
          <div className="card-header">
            <h5 className="card-title text-center">Estado</h5>
          </div>
          <div className="card-body">

            <div className="row">
              <div className="col-12 text-center">
                <h1 style={{color: colorEstado}}>{this.props.proyecto.estado}</h1>
              </div>
            </div>
            

          </div>
        </div>
      </div>
    )
  }
}

export default EstadoCard