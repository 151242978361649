import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { connect } from 'react-redux';

import Loader from '../../../../Components/Loader.jsx';

import FetchNewImagenProyecto from '../NewImagenProyectoFetch/FetchNewImagenProyecto.js';

import imageCompression from 'browser-image-compression';

class SubirImagenes extends Component {

    constructor(props) {
        
        super(props);

        this.state = {
            insertImagenProyectoMensaje: null,
            imageTotal: 0,
            imageUp: 0,
            imgsArr: [],
            loading: false
        }

        this.handleInputFileChangeImagen = this.handleInputFileChangeImagen.bind(this);
        this.handleInputImagenChange = this.handleInputImagenChange.bind(this);
        this.handleSaveImages = this.handleSaveImages.bind(this);

    }

    async handleInputFileChangeImagen(){
        let This = this//,
        /*proyecto = this.props.proyecto,
        imgProyectoData = {
          idProyecto: proyecto.id
        }*/

        var selectedFiles = [...document.getElementById("imgProyecto").files];

        const functionWithPromise = (selectedFile, i) => { 
            //a function that returns a promise
            async function AsyncFunct(){
                let promise = new Promise((resolve, reject) => {

                    var fileToLoad = selectedFile

                    // FileReader function for read the file.
                    var fileReader = new FileReader();
                    //var base64

                    // Onload of file read the file content
                    fileReader.onload = function(fileLoadedEvent) {
                        let img = {
                            id: i,
                            idProyecto: This.props.proyecto.id,
                            b64: fileLoadedEvent.target.result,
                            descripcion: ""
                        }
                        resolve(img)   
                    };
                    // Convert data to base64
                    fileReader.readAsDataURL(fileToLoad)
                    
                })

                let result = await promise; // wait till the promise resolves (*)

                //console.log(result); // "done!"

                return result
            }
            return AsyncFunct()
        }

        if (selectedFiles.length > 0) {

            let selectedImages = async () => {
                return Promise.all(selectedFiles.map( async (selectedFile, i) => {

                    const options = {
                        maxSizeMB: 1,
                        maxWidthOrHeight: 1920
                    }

                    const compressedFile = await imageCompression(selectedFile, options);

                    return functionWithPromise(compressedFile, i)

                }))
            }

            selectedImages()
            .then( data => {
                This.setState({
                    imgsArr: data,
                    imageTotal: data.length
                })
            })

        }

    }

    handleInputImagenChange(event, id){

        let img = this.state.imgsArr.filter( imgData => imgData.id === id)[0]

        img.descripcion = event.target.value

        let imgArr = [img]

        let newStateimgs = this.state.imgsArr.map( obj => imgArr.find(o => o.id === obj.id) || obj )

        this.setState({
            imgsArr: newStateimgs
        })
    }

    handleSaveImages(){

        let This = this

        this.setState({
            loading: true
        })

        this.state.imgsArr.forEach( async img => {
            
            await FetchNewImagenProyecto(This, img)
            
        });

    }
    
    render(){

        return(
            <div className="modal-body container-fluid">
                <div className="row text-center">
                    <div className="col-11 col-md-10 mx-auto">
                        <div className="float-left">
                            <button style={{width: '28px', height: '28px', backgroundColor: '#FF9E00', borderRadius: '50%', border: 'none'}} onClick={ () =>{
                                let route ="DataProyecto"
                                this.props.handleChangeRoute(route)
                            }}>  
                                <FontAwesomeIcon icon='angle-left' style={{color: '#FFFFFF'}} />
                            </button>
                        </div>
                        <div className="col-12" style={{marginTop: '50px'}}>
                            <div className="row">
                                <div className="col-12 custom-file mx-auto" style={{marginTop: '35px', marginBottom: '45px'}}>
                                    <label htmlFor="archivo">Archivos: </label><br/>
                                    <input type="file" className="archivo1" id="imgProyecto" name="imgProyecto" required style={{width: '1px', height: '1px', opacity: '0', overflow: 'hidden', position: 'absolute', zIndex: '-1'}} onChange={this.handleInputFileChangeImagen} multiple/>
                                    <label className="btn btn-outline-primary mx-auto" htmlFor="imgProyecto" style={{cursor: 'pointer'}}><span>Selecciona Imagenes para Subir a Galería</span></label>
                                </div>
                                {
                                    this.state.imgsArr.length > 0 ? (
                                        <div className="col-12">
                                            <div className="row">
                                                {
                                                    this.state.imgsArr.map( img => (
                                                        <div key={img.id} className="col-12 col-md-4 mx-auto" style={{marginTop: '25px'}}>
                                                            <img className="img-fluid" src={img.b64} alt={`img-${img.id}`}/>
                                                            <div className="form-group" style={{marginTop: '15px'}}>
                                                                {/*<label for="descripcion">Descripción</label>*/}
                                                                <input type="text" className="form-control" id={`descripcion-${img.id}`} aria-describedby={`descripcionHelp-${img.id}`} placeholder="Descripción" value={img.descripcion} onChange={ (event) => {
                                                                    this.handleInputImagenChange(event, img.id)
                                                                }}/>
                                                                <small id={`descripcionHelp-${img.id}`} className="form-text text-muted">Descripción</small>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                                {
                                                    (this.state.loading === false) && (this.state.imageUp !== this.state.imageTotal) ? (
                                                        <div className="col-12" style={{marginTop: '25px'}}>
                                                            <p className="text-center">Imagenes a Subir: {this.state.imageTotal}</p>
                                                        </div>
                                                    ) : null
                                                }

                                                {
                                                    (this.state.imageUp > 0) && (this.state.imageUp !== this.state.imageTotal) ? (
                                                        <div className="col-12" style={{marginTop: '25px'}}>
                                                            <p className="text-center">Imagen Subidas: {this.state.imageUp} de {this.state.imageTotal}</p>
                                                        </div>
                                                    ) : null
                                                }

                                            </div>
                                        </div>
                                    ) : null
                                }
                                {
                                    this.state.loading === true ? (
                                        <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-5 col-lg-3 mx-auto">
                                            <Loader />
                                            </div>
                                        </div>
                                        </div>
                                    ) : null
                                }
                                {
                                  this.state.imgsArr.length > 0 ? (
                                      <button type="button" className="btn btn-default mx-auto" onClick={this.handleSaveImages} style={{'padding': '12px, 6px, 12px,6px', 'backgroundColor': '#1A3B65'}}><FontAwesomeIcon icon="save" style={{'fontSize': '150%','color': '#FFFFFF'}} /></button>
                                  ) : null
                                }
                            </div>

                            {
                                this.state.insertImagenProyectoMensaje !== null ? (
                                <div className="row" style={{marginTop: '25px'}}>
                                    {
                                    (this.state.insertImagenProyectoMensaje.response === 'success') ? (
                                        <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.insertImagenProyectoMensaje.mensaje}</div>
                                    ) : (
                                        <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.insertImagenProyectoMensaje.mensaje}</div>
                                    )
                                    }
                                </div>
                                ) : null
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

let mapStateToProps = (state, props) => {
    return {
        usuario: state.logIn.usuario
    }
}

export default connect(mapStateToProps)(SubirImagenes);