import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let EmpresaTable = props => (
  <div className="table-responsive">
    <div className="justify-content-end">
        <button onClick={() => {
            let typeModal = 'InsertEmpresa';
            props.handleOpenModal(props.dataEmpresas.nuevaEmpresa, typeModal)
        }}
        type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'float': 'right', 'color': '#1A3B65'}}>
          <FontAwesomeIcon icon="plus-circle" /> Nueva Empresa <FontAwesomeIcon icon="building" />
        </button>
    </div>
    <table className="table text-center table-bordered table-hover table-sm">
      <thead className="thead-light">
        <tr>
          <th scope="col">Nombre Comercial</th>
          <th scope="col" className="d-none d-md-table-cell">Nombre Fiscal</th>
          <th scope="col" className="d-none d-lg-table-cell">Teléfono</th>
          <th scope="col" className="d-none d-lg-table-cell">Correo</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {
          props.dataEmpresas.empresas.map( empresa => {
            return (
              <tr key={empresa.id}>
                {/*<th scope="row">{usuario.nombre}</th>*/}
                <td>{empresa.nombre_comercial}</td>
                <td className="d-none d-md-table-cell">{empresa.nombre_fiscal}</td>
                <td className="d-none d-lg-table-cell">{empresa.telefono}</td>
                <td className="d-none d-lg-table-cell">{empresa.correo}</td>
                <td>
                  <button type="button" onClick={() => {
                      let typeModal = 'EmpresaModalView';
                      props.handleOpenModal(empresa, typeModal)
                  }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65'}}>
                    <FontAwesomeIcon icon="eye" />
                  </button> {/*ref={props.setButtonViewUser}*/}

                  <button type="button" onClick={() => {
                      let typeModal = 'EmpresaModalEdit';
                      props.handleOpenModal(empresa, typeModal)
                  }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65'}}>
                    <FontAwesomeIcon icon="edit" />
                  </button>{/*ref={props.setButtonEditUser}*/}

                  <button onClick={ () => {
                        props.handleDeleteEmpresa(empresa)
                    }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65'}}>
                    <FontAwesomeIcon icon="trash-alt" />
                  </button>

                </td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  </div>
)

export default EmpresaTable
