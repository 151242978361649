import React from 'react';
import ReactDOM from 'react-dom';
import jquery from 'jquery';
import slick from 'slick-carousel/slick/slick.min.js';

window.React = React;
window.ReactDOM = ReactDOM;
window.jQuery = jquery;
window.jquery = jquery;
window.$ = jquery;
window.slick = slick;
