import React, { Component } from 'react';

import '../Components/css/Contacto.css';

class Contacto extends Component {

  render() {
    return (
      <section id="contacto" data-jarallax-element="0 0">
        <div className="container">
          <div className="row">

            <div id="logoContacto" className="col-6 col-sm-4 col-md-2 mx-auto">
              <img src="/img/svg/Logo.svg" alt="Logo" className="img-fluid" />
            </div>

            <div id="fondoContacto" className="col-12 col-md-8 mx-auto">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <h2>Contacto</h2>
                    <p>Dirección <br/>
                      6ta Avenida 4-17 zona 10 Edificio Las Bouganvilias Oficina 4A
                    </p>
                    <p>Teléfono<br/>
                      +502 5050-0997
                    </p> 
                    <p>Correo electrónico<br/>
                      info@pm502.com</p>
                    
                  </div>
                  <div className="col-12 col-md-6">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15443.314181620211!2d-90.510298!3d14.608841000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd991013555e21531!2sEdificio+Bugambilias!5e0!3m2!1ses!2sgt!4v1563404227009!5m2!1ses!2sgt" frameBorder="0" style={{height: '100%', width: '100%', border: '0'}} allowFullScreen title="Mapa PM502"/>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    )
  }

}

export default Contacto