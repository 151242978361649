import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let UsersTable = props => { 
  let tableClientes = props.usuarios.filter(usuario => { 
    return usuario.tipo === 'cliente'
  })

  if ((props.usuarioLogIn.colaborador !== null) && ((props.usuarioLogIn.colaborador.puesto === 'Proyect Manager') || (props.usuarioLogIn.colaborador.puesto === 'Secretaria'))) {
    return (
      <div className="table-responsive">
        <div className="justify-content-end">
          <button
            onClick={() => {
              let typeModal = 'InsertUser';
              props.handleOpenModal(props.nuevoUsuario, typeModal)
            }}
            type="button" style={{ 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'float': 'right', 'color': '#1A3B65' }}>
            Nuevo Usuario <FontAwesomeIcon icon="user-plus" />
          </button>
        </div>
        <table className="table text-center table-bordered table-hover table-sm">
          <thead className="thead-light">
            <tr>
              <th scope="col">Usuario</th>
              <th scope="col" className="d-none d-lg-table-cell" >Nombre</th>
              <th scope="col" className="d-none d-lg-table-cell" >Apellidos</th>
              <th scope="col" className="d-none d-md-table-cell" >Correo</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              tableClientes.map(usuario => {
                return (
                  <tr key={usuario.id}>
                    {/*<th scope="row">{usuario.nombre}</th>*/}
                    <td>{usuario.usuario}</td>
                    <td className="d-none d-lg-table-cell" >{usuario.nombre}</td>
                    <td className="d-none d-lg-table-cell" >{usuario.apellidos}</td>
                    <td className="d-none d-md-table-cell" >{usuario.email}</td>
                    <td>
                      <button type="button" onClick={() => {
                        let typeModal = 'UserModalView';
                        props.handleOpenModal(usuario, typeModal)
                      }} style={{ 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65' }}>
                        <FontAwesomeIcon icon="eye" />
                      </button> {/*ref={props.setButtonViewUser}*/}
    
                      <button type="button" onClick={() => {
                        let typeModal = 'UserModalEdit';
                        props.handleOpenModal(usuario, typeModal)
                      }} style={{ 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65' }}>
                        <FontAwesomeIcon icon="edit" />
                      </button>{/*ref={props.setButtonEditUser}*/}
    
                      <button onClick={() => {
                        props.handleDeleteUser(usuario)
                      }} type="button" style={{ 'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65' }}>
                        <FontAwesomeIcon icon="trash-alt" />
                      </button>
    
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  } else {
    return (
      <div className="table-responsive">
        <div className="justify-content-end">
            <button
              onClick={() => {
                  let typeModal = 'InsertUser';
                  props.handleOpenModal(props.nuevoUsuario, typeModal)
              }}
              type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'float': 'right', 'color' : '#1A3B65'}}>
              Nuevo Usuario <FontAwesomeIcon icon="user-plus" />
            </button>
        </div>
        <table className="table text-center table-bordered table-hover table-sm">
          <thead className="thead-light">
            <tr>
              <th scope="col">Usuario</th>
              <th scope="col" className="d-none d-lg-table-cell" >Nombre</th>
              <th scope="col" className="d-none d-lg-table-cell" >Apellidos</th>
              <th scope="col" className="d-none d-md-table-cell" >Correo</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              props.usuarios.map( usuario => {
                return (
                  <tr key={usuario.id}>
                    {/*<th scope="row">{usuario.nombre}</th>*/}
                    <td>{usuario.usuario}</td>
                    <td className="d-none d-lg-table-cell" >{usuario.nombre}</td>
                    <td className="d-none d-lg-table-cell" >{usuario.apellidos}</td>
                    <td className="d-none d-md-table-cell" >{usuario.email}</td>
                    <td>
                      <button type="button" onClick={() => {
                          let typeModal = 'UserModalView';
                          props.handleOpenModal(usuario, typeModal)
                      }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#1A3B65'}}>
                        <FontAwesomeIcon icon="eye" />
                      </button> {/*ref={props.setButtonViewUser}*/}
    
                      <button type="button" onClick={() => {
                          let typeModal = 'UserModalEdit';
                          props.handleOpenModal(usuario, typeModal)
                      }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#1A3B65'}}>
                        <FontAwesomeIcon icon="edit" />
                      </button>{/*ref={props.setButtonEditUser}*/}
    
                      <button onClick={ () => {
                            props.handleDeleteUser(usuario)
                        }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#1A3B65'}}>
                        <FontAwesomeIcon icon="trash-alt" />
                      </button>
    
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }

}

export default UsersTable
