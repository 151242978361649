let handleInputChange = (event, This) => {

  let value = event.target.value,
      empresa = event.target.dataset.empresa,
      type = event.target.dataset.valueType

  if((This.props.dataEmpresas.nuevaEmpresa.id === JSON.parse(empresa).id) === true){

      //console.log(empresa)

      if(type === 'nombre_comercial'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.nombre_comercial = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'nombre_fiscal'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.nombre_fiscal = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'telefono'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.telefono = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'correo'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.correo = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'NIT'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.NIT = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'direccion'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.direccion = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'tipoEmpresa'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.tipo_empresa = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'regimenFiscal'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.regimen_fiscal = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'cuenta_banco'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.cuenta_banco = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }

    }

}

export default handleInputChange;
