const initialState = {
  empresas: [],
  nuevaEmpresa: {
    nombre_comercial: "",
    nombre_fiscal: "",
    telefono: "",
    correo: "",
    NIT: "",
    direccion: "",
    tipo_empresa: "proveedor",
    regimen_fiscal: "Regimen 5%",
    cuenta_banco: ""
  }
}

let dataEmpresas = (state = initialState, action) => {
  switch (action.type) {

    case 'FETCH_EMPRESAS': {

      let empresas = action.payload.dataEmpresas

      let newState = {
        ...state,
        empresas: empresas
      }

      return newState
    }

    case 'CHANGE_DATA_EMPRESAS': {

      let empresa = action.payload.dataEmpresa

      let empresaArr = [empresa]

      let newStateEmpresas = state.empresas.map( obj => empresaArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        empresas: newStateEmpresas
      }

      return newState
    }

    case 'NEW_EMPRESA': {

      let nuevaEmpresa = action.payload.dataEmpresa

      let newState = {
        ...state,
        nuevaEmpresa
      }

      return newState

    }
      
    case 'CLEAN_NEW_EMPRESA': {

      let newState = {
        ...state,
        nuevaEmpresa: {
          nombre_comercial: "",
          nombre_fiscal: "",
          telefono: "",
          correo: "",
          NIT: "",
          direccion: "",
          tipo_empresa: "proveedor",
          regimen_fiscal: "Regimen 5%",
          cuenta_banco: ""
        }
      }

      return newState

    }

    case 'DELETE_EMPRESA': {

      let empresa = action.payload.dataEmpresa

      let newEmpresas = state.empresas.filter( company => company.id !== empresa.id)

      let newState = {
        ...state,
        empresas: newEmpresas
      }

      return newState

    }

    case 'ADD_EMPRESA_TO_EMPRESAS': {

      let empresa = action.payload.dataEmpresa,
          empresaArr = [empresa]

      let empresas = state.empresas.concat(empresaArr)

      let newState = {
        ...state,
        empresas
      }

      return newState
      //return state
    }

    case 'CHANGE_LOGO_EMPRESA': {

      let idEmpresa = action.payload.idEmpresa,
          logoEmpresa = action.payload.logoEmpresa

      let empresaArr = state.empresas.filter( empresa => empresa.id === idEmpresa)

      empresaArr[0].logoEmpresa = logoEmpresa

      let newStateEmpresas = state.empresas.map( obj => empresaArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        empresas: newStateEmpresas
      }
      return newState
    }

    //break;
    default:
      return state
  }
}

export default dataEmpresas
