import React, { Component } from 'react';

import { connect } from 'react-redux';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DeleteImagenFetch from '../DeleteImagenProyectoFetch/FetchDeleteImagenProyecto.js';

import Img from './Img.jsx'

class MinGaleria extends Component {

    constructor(props) {

        super(props);

        this.state = {
            img: null
        }

        this.handleOpenImageClick = this.handleOpenImageClick.bind(this);
        this.handleOpenImageClick = this.handleOpenImageClick.bind(this);

    }

    handleOpenImageClick(img){

        if(this.state.img === null){
            this.setState({
                img
            })
        }else{
            this.setState({
                img: null
            })
        }
    }

    handleDeleteImage(idProyecto, idImagen){

        let data = {
            idProyecto,
            idImagen
        }

        DeleteImagenFetch(this, data)

    }

    render(){

        //let orderByDate = this.props.proyecto.galImgs.sort( (a, b) => a.fecha - b.fecha)

        //console.log(orderByDate)

        let proyecto = this.props.proyectos.filter( proyect => proyect.id === this.props.proyecto.id)[0]

        let setSemana = proyecto.galImgs.map( img => {
            let momentSemana = moment(parseInt(img.fecha)).isoWeek(),
                year = moment(parseInt(img.fecha)).format("YYYY")

            let newImg = {
                semana: `${momentSemana}-${year}`,
                img: img
            }

            return newImg
        }),
            semanasRepetidas = setSemana.map( img => {
                let semana = img.semana
                return semana
            }),
            setSemanas = new Set(semanasRepetidas),
            semanas = [...setSemanas],
            newGalData = semanas.map( semana => {

                let newImgArr = setSemana.filter( img => img.semana === semana)

                return newImgArr

            })

        //console.log(newGalData)

        return(
            <div className="col-11 col-md-10 mx-auto">
                <div className="float-left">
                    <button style={{width: '28px', height: '28px', backgroundColor: '#FF9E00', borderRadius: '50%', border: 'none'}} onClick={ () =>{
                        let route ="Galeria"
                        this.props.handleChangeRoute(route)
                    }}>  
                        <FontAwesomeIcon icon='angle-left' style={{color: '#FFFFFF'}} />
                    </button>
                </div>
                {
                    this.state.img === null ? (
                        <div className="col-12" style={{marginTop: '50px'}}>
                            <div className="row">
                                {
                                    newGalData.map( (imgArr, i) => {
                                        return(
                                            <div key={i} className="col-12 mx-auto" style={{marginTop: '15px'}}>
                                                <h4 className="text-left">{moment(imgArr[0].semana, "WW-YYYY").format("DD/MM/YYYY")}</h4>
                                                <div className="row">
                                                    {
                                                        imgArr.map( imgObj => {
                                                            return(
                                                                <div key={imgObj.img.idImagen} className="col-4 col-md-3 col-lg-2 mx-auto" style={{marginTop: '15px'}}>
                                                                    <Img idImagen={imgObj.img.idImagen} imgObj={imgObj} handleOpenImageClick={this.handleOpenImageClick} type='minGaleria' />
                                                                    <p className="text-center">{moment(parseInt(imgObj.img.fecha)).format("DD/MM/YYYY")}</p>
                                                                    {
                                                                        this.props.usuario.tipo === "colaborador" ? (
                                                                            <button onClick={ () => {
                                                                                    this.handleDeleteImage(this.props.proyecto.id, imgObj.img.idImagen)
                                                                                }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65'}}>
                                                                                <FontAwesomeIcon icon="trash-alt" />
                                                                            </button>
                                                                        ) : null
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ) : null
                }
                {
                    this.state.img !== null ? (
                        <div className="col-12" style={{marginTop: '50px'}}>
                            <div className="row">
                                <div className="col-12">
                                    <Img idImagen={this.state.img.idImagen} />
                                    <p className="text-center">{moment(parseInt(this.state.img.fecha)).format("DD/MM/YYYY")}</p>
                                    {
                                        this.state.img.descripcion !== null ? (
                                            <p className="text-center"><b>Descripcion: </b>{this.state.img.descripcion}</p>
                                        ) : null
                                    }
                                </div>
                                <div className="col-12" style={{marginTop: '25px'}}>
                                    <button type="button" className="btn btn-outline-info mx-auto" onClick={this.handleOpenImageClick}>Cerrar Imagen</button>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        )
    }
}

let mapStateToProps = (state, props) => {
    return {
        usuario: state.logIn.usuario,
        proyectos: state.dataProyectos.proyectos
    }
}
  
export default connect(mapStateToProps)(MinGaleria);