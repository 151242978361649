import React, { Component } from 'react';

import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';

import GraphQL from '../../../../apiService/FetchGraphQLUsuarios.js';

import GraphQLEmpresas from '../../../../apiService/FetchGraphQLEmpresas.js';

import GraphQLColaboradores from '../../../../apiService/FetchGraphQLColaboradores.js';

import GraphQLProyectos from '../../../../apiService/FetchGraphQLProyectos.js';

import deleteUser from './DeleteUserFetch/FetchDeleteUser.js';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import UsersTable from '../components/UsersTable.jsx';

class AdminUserDashboard extends Component{

	constructor(props) {
    super(props);

		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleDeleteUser = this.handleDeleteUser.bind(this);
  }

	handleOpenModal(data, typeModal){
    this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				data,
				typeModal
			}
		})

  }

	handleDeleteUser(user){

		deleteUser(this, user)

	}

	componentDidMount() {

			if(this.props.conexion === false){
			   return <Redirect to='/login' />
			}else if(this.props.usuario.permisos.includes('adminUser') === false){
				return <Redirect to='/dashboard' />
				//console.log(this.usuario.conexion)
			}

			GraphQL(this);

			GraphQLEmpresas(this)
		
			GraphQLColaboradores(this)
			
			GraphQLProyectos(this)

  	}

		setButton = element => {
			if(element.dataset.modalType === 'viewUser'){
				this.button = element.id;
				//console.log(element.id);
			}else if(element.dataset.modalType === 'editUser'){
				this.button = element.id;
				//console.log(element);
			}

		}

	render(){
		if (this.props.conexion === false || this.props.usuario.permisos.includes('adminUser') === false) {
			return <Redirect to='/login' />
		}else{
			return(
				<DashboardComponent>
					<div className="container-fluid" style={{minHeight: '78vh'}}>
						<UsersTable setButtonViewUser={this.setButton} setButtonEditUser={this.setButton} usuarios={this.props.usuarios} handleOpenModal={this.handleOpenModal} usuarioLogIn={this.props.usuario} handleDeleteUser={this.handleDeleteUser}/>
		      </div>
				</DashboardComponent>
			)
		}
	}
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
		usuarios: state.dataUsers.usuarios
  }
}

export default connect(mapStateToProps)(AdminUserDashboard)
