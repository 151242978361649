let consulta = () => {
  let string = `{
    appInfo{
      proyectos{
        id
        idEmpresa
        nombre
        pais
        direccion
        telefono
        correo
        presupuesto
        presupuestoGastado
        informesSEP{
          idInformeSEP
          fecha
        }
        dataProyecto{
          Semana
          Inicio
          Fin
          PorcentajeEstimado
          PorcentajeReal
          Diferencia
        }
        dataFlujoProyecto{
          PresupuestoProyectado
          PresupuestoAcumulado
          PresupuestoEjecutado
          PresupuestoRealAcumulado
          PresupuestoAjustado
          PresupuestoAjustadoAcumulado
          Fecha
        }
        logoProyecto
        estado
        fase
        divisa
        galImgs {
          idImagen
          descripcion
          fecha
        }
        planos {
          id
          nombreDePlano
          tipoPlano
          fecha
        }
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
