import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		updateUserContrasenaMensaje: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const updatePass = (This, newPass, idUser) => {
	//console.log(newPass, idUser)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(newPass, idUser)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.setState({
			updateUserContrasenaMensaje: {
				response: 'success',
				mensaje: res.data.updatePassword.mensaje
			}
		})

	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default updatePass;
