import React from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProgresoCard from '../../Components/Cards/ProgresoCard.jsx';

let InsertProyecto = props => {

  let proyecto = props.proyecto,
      proyectoString = JSON.stringify(proyecto),
      modalVisible = props.modalVisible,
      handleCloseModal = props.handleCloseModal

  return(
      <ModalContainer>
        <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
              <img className="brand-modal" src="/img/svg/LogoBlanco.svg" alt="LogoBiolabBlanco" />
              <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                <div></div>
                <div></div>
              </button>
              </div>

              <div className="modal-body sin-margen-y-padding container-fluid">
                <div className="row text-center">


                  <div className="col-12">
                    <FontAwesomeIcon icon="city" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                    <h1>Proyecto: {proyecto.nombre}</h1>
                  </div>
                  <div className="col-12 row" style={{'marginTop': '25px'}}>

                    <div className="col-md-4 row" style={{marginTop: '25px'}}>
                      <label htmlFor="nombre" className="col"><b>Nombre:</b></label>
                      <input id="nombre" ref={props.setRef} data-proyecto={proyectoString} data-value-type="nombreProyecto" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={proyecto.nombre} style={{'top': '-3px'}}/>
                    </div>
                    
                    <div className="col-md-4 row" style={{marginTop: '25px'}}>
                      <label htmlFor="empresaId" className="col"><b>Empresa:</b></label>
                      <select id="empresaId" ref={props.setRef} data-proyecto={proyectoString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="empresaId" value={proyecto.idEmpresa}>
                        {
                          props.dataEmpresas.empresas.map( (empresa, index) => {
                            return <option key={index} value={empresa.id}>{empresa.nombre_comercial}</option>
                          })
                        }
                      </select>
                    </div>

                    <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                      <label htmlFor="paisProyecto" className="col"><b>País:</b></label>
                      <input id="paisProyecto" ref={props.setRef} data-proyecto={proyectoString} data-value-type="paisProyecto" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={proyecto.pais} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                      <label htmlFor="direccionProyecto" className="col"><b>Dirección:</b></label>
                      <input id="direccionProyecto" ref={props.setRef} data-proyecto={proyectoString} data-value-type="direccionProyecto" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={proyecto.direccion} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                      <label htmlFor="telefonoProyecto" className="col"><b>Teléfono:</b></label>
                      <input id="telefonoProyecto" ref={props.setRef} data-proyecto={proyectoString} data-value-type="telefonoProyecto" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={proyecto.telefono} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                      <label htmlFor="correoProyecto" className="col"><b>Correo:</b></label>
                      <input id="correoProyecto" ref={props.setRef} data-proyecto={proyectoString} data-value-type="correoProyecto" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={proyecto.correo} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                      <label htmlFor="estadoProyecto" className="col"><b>Divisa:</b></label>
                      <select id="estadoProyecto" ref={props.setRef} data-proyecto={proyectoString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="divisaProyecto" value={proyecto.divisa}>
                        <option value="Quetzales">Quetzales</option>
                        <option value="Dolares">Dolares</option>
                      </select>
                    </div>
                    
                    <div className="col-md-4 row" style={{marginTop: '25px'}}>
                      <label htmlFor="presupuesto" className="col"><b>Presupuesto:</b></label>
                      <input id="presupuesto" ref={props.setRef} data-proyecto={proyectoString} data-value-type="presupuesto" onChange={props.handleInputChange} type="number"  className="col form-control-plaintext form-control-sm inputUserVal" value={proyecto.presupuesto} style={{'top': '-3px'}}/>
                    </div>
                    
                    <div className="col-md-4 row" style={{marginTop: '25px'}}>
                      <label htmlFor="presupuestoGastado" className="col"><b>Presupuesto Gastado:</b></label>
                      <input id="presupuestoGastado" ref={props.setRef} data-proyecto={proyectoString} data-value-type="presupuestoGastado" onChange={props.handleInputChange} type="number"  className="col form-control-plaintext form-control-sm inputUserVal" value={proyecto.presupuestoGastado} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                      <label htmlFor="estadoProyecto" className="col"><b>Estado:</b></label>
                      <select id="estadoProyecto" ref={props.setRef} data-proyecto={proyectoString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="estadoProyecto" value={proyecto.estado}>
                        <option value="OS">OS (En Tiempo)</option>
                        <option value="BS">BS (Atrasado)</option>
                        <option value="AS">AS (Adelantado)</option>
                      </select>
                    </div>

                    <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                      <label htmlFor="faseProyecto" className="col"><b>Fase:</b></label>
                      <select id="faseProyecto" ref={props.setRef} data-proyecto={proyectoString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="faseProyecto" value={proyecto.fase}>
                        <option value="Desarrollo">Desarrollo</option>
                        <option value="Planificacion">Planificación</option>
                        <option value="Ejecucion">Ejecución</option>
                        <option value="Cierre">Cierre</option>
                      </select>
                    </div>

                  </div>
                  
                  <div className="col-12" style={{'marginTop': '25px'}}>
                    
                    <ProgresoCard proyecto={proyecto} />

                  </div>

                </div>
                {
                    props.insertProyectoMensaje !== null ? (
                      <div className="row" style={{marginTop: '25px'}}>
                        {
                          (props.insertProyectoMensaje.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.insertProyectoMensaje.mensaje}</div>
                          ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.insertProyectoMensaje.mensaje}</div>
                          )
                        }
                      </div>
                    ) : null
                  }
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default mx-auto" onClick={props.handleSaveNewProyecto}><FontAwesomeIcon icon="save" style={{'fontSize': '150%'}} /></button>
                <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
              </div>
            </div>
          </div>
      </ModalB4>
    </ModalContainer>
  )
}

export default InsertProyecto
