let consulta = (data) => {
  
  const string =
    `mutation deleteImagenProyecto {
      deleteImagenProyecto(idProyecto: "${data.idProyecto}", idImagen: "${data.idImagen}")
      {
        id
        idImagen
        mensaje
      }
    }`

    return {
      "query": string
    }

}

export default consulta
