import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		insertEmpresaMensaje: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const insertProyecto = (This, proyectoJSON) => {
	//console.log(proyectoJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(proyectoJSON)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		
		//console.log(res.data.insertProyecto)

		This.setState({
			insertProyectoMensaje: {
				response: 'success',
				mensaje: res.data.insertProyecto.mensaje
			}
		})

		res.data.insertProyecto.proyecto.informesSEP = null
		res.data.insertProyecto.proyecto.dataProyecto = null
		res.data.insertProyecto.proyecto.dataFlujoProyecto = null
		res.data.insertProyecto.proyecto.logoProyecto = null
		res.data.insertProyecto.proyecto.galImgs = null

		This.props.dispatch({
			type: 'ADD_PROYECTO_TO_PROYECTOS',
			payload: {
				dataProyecto: res.data.insertProyecto.proyecto
			}
		})

		return res
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default insertProyecto;
