let consulta = (data) => {
  
  const string =
    `mutation {
      deletePlano(idProyecto: "${data.idProyecto}", idPlano: "${data.idPlano}")
      {
        id
        idPlano
        mensaje
      }
    }`

    return {
      "query": string
    }

}

export default consulta
