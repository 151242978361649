import React, { Component } from 'react';

import { connect } from 'react-redux';

import GraphQLEmpresas from '../../../../../apiService/FetchGraphQLEmpresas.js';

import { RadialGauge } from 'chartjs-2-react';

import './css/AvancesCard.css';

class AvancesCard extends Component {

  constructor(props) {
    super(props);
    // No llames this.setState() aquí!
    //this.state = { counter: 0 };
    this.handleOpenModal = this.handleOpenModal.bind(this);
  }

  componentDidMount(){
    GraphQLEmpresas(this);
  }

  handleOpenModal(){

    let proyecto = this.props.proyecto,
        empresaProyecto = this.props.dataEmpresas.empresas.filter(empresa => {
          return empresa.id === proyecto.idEmpresa
        })

    let data = {
      proyecto,
      empresaProyecto
    }
    this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				data,
				typeModal: 'ProyectoModalView'
			}
		})

  }

  render() {

    //Empieza Sacar Valores para PorcentajeEstimado y PorcentajeReal
    let porcentajeRealVal

    if(this.props.proyecto.dataProyecto !== null){
      let dataProyectoPorcEstiYPorcRealArr = this.props.proyecto.dataProyecto.filter( proyectoData =>{
        return (proyectoData.PorcentajeEstimado !== null) && (proyectoData.PorcentajeReal !== null)
      })
  
      let mayorSemanaValue = 0
  
      dataProyectoPorcEstiYPorcRealArr.forEach(data => {
        if(parseInt(data.Semana) > mayorSemanaValue){
          mayorSemanaValue = parseInt(data.Semana)
        }
      });
  
      let dataProyectoPorcEstiYPorcReal = dataProyectoPorcEstiYPorcRealArr.filter(data => {
        return data.Semana === mayorSemanaValue.toString()
      })
      porcentajeRealVal = dataProyectoPorcEstiYPorcReal[0].PorcentajeReal
    }
    //Termina Sacar Valores para PorcentajeEstimado y PorcentajeReal

    let //fecha = new Date(),
      //dia = fecha.getDate(),
      //mes = fecha.getMonth() + 1,
      //year = fecha.getFullYear(),
      porcentaje = (porcentajeRealVal * 100).toFixed(2),
      porcentajeTexto = `${porcentaje} %`
    
    return (
      <div className="avancesCard col-12 col-sm-6 mx-auto" style={{marginTop: '50px', cursor: 'pointer'}} onClick={this.handleOpenModal}>
        <div className="card">
          <div className="card-header">
            <h5 className="card-title text-center">{this.props.proyecto.nombre}</h5>
          </div>
          <div className="card-body">
            {/*<div className="row">
              <h5 className="text-center col-12">Fecha: {`${dia}/${mes}/${year}`}</h5>
            </div>*/}
            <div className="row">
              {
                this.props.proyecto.logoProyecto !== null ? (
                  <div className="col-6 col-sm-5 col-lg-4 col-xl-3 mx-auto">
                    <img className="img-fluid" src={`data:image/png;base64, ${this.props.proyecto.logoProyecto}`} alt="logoProyecto"/>
                  </div>
                ) : (
                  <div className="col-6 col-sm-5 col-lg-4 col-xl-3 mx-auto">
                    <img className="img-fluid" src="/img/svg/LogoProyecto.svg" alt="logoProyecto" />
                  </div>
                )
              }
            </div>
            <div className="row">

            {
                this.props.proyecto.dataProyecto !== null ? (
                  <div className="col-12 col-xl-6 mx-auto d-flex align-items-center" style={{marginTop: '15px'}}>
                    <h5 className="text-center col-12">Avance del Proyecto:</h5>
                  </div>
                ) : null
              }
              
              <div className="col-12 col-lg-8 col-xl-5 mx-auto" style={{position: 'relative'}}>

                {
                  this.props.proyecto.dataProyecto !== null ? (
                    <RadialGauge 
                      config={{
                        data: {
                          datasets: {
                            data: porcentaje,
                            backgroundColor: "#4bc000"
                          }
                        },
                        options: {
                          centerArea: {
                            text: porcentajeTexto
                          }
                        }
                      }} 
                    />
                  ) : null
                }

              </div>
              
              
            </div>

          </div>
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
		conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    Modal: state.Modal,
    dataEmpresas: state.dataEmpresas,
    dataProyectos: state.dataProyectos
  }
}

export default connect(mapStateToProps)(AvancesCard)