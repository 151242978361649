import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err){
	console.log(`Request failed: ${err}`);
}

const deleteProyecto = (This, data) => {
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		
		//console.log(proyecto)

		This.props.dispatch({
			type: 'DELETE_IMAGEN_PROYECTO',
			payload: {
				idProyecto: res.data.deleteImagenProyecto.id,
				idImagen: res.data.deleteImagenProyecto.idImagen
			}
		})

		//console.log(res)
		//return res
	})
	.catch(err => handleError(err));

	//termina fetch
}

export default deleteProyecto;