import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ViewPlanosCarpetas extends Component {

    render(){

        let planosType = this.props.proyecto.planos.map( plano => {
            return plano.tipoPlano
        }),
        uniquePlanosTypeSet = [...new Set(planosType)],
        planosTypeArray = Array.from(uniquePlanosTypeSet)
    
        return(
            <div className="col-11 col-md-10 mx-auto">
                <div className="float-left">
                    <button style={{width: '28px', height: '28px', backgroundColor: '#FF9E00', borderRadius: '50%', border: 'none'}} onClick={ () =>{
                        let route ="DataProyecto"
                        this.props.handleChangeRoute(route)
                    }}>  
                        <FontAwesomeIcon icon='angle-left' style={{color: '#FFFFFF'}} />
                    </button>
                </div>
                <div className="col-12" style={{marginTop: '50px'}}>
                    <div className="row">
                        {
                            planosTypeArray.map( (planosType, index) => {
                                return(
                                    <div key={index} className="col-12 col-sm-6 col-lg-4 mx-auto" style={{cursor: 'pointer', marginTop: '25px'}}   onClick={ () => {
                                        this.props.handleChangeCarpetaRoute("TablePlanos", planosType)
                                    }}>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <h1><FontAwesomeIcon icon="layer-group" style={{fontSize: "80px", color: '#17a2b8'}} /></h1>
                                                        <h3>{planosType}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewPlanosCarpetas