let consulta = (newPass, idUser) => {

  //console.log(usuarioJSON.sexo)

  const string =
    `mutation updatePass {
      updatePassword(
       	idUser: "${idUser}"
      	newPass: "${newPass}"
      ) {
        idUser
        mensaje
      }
    }`

    return {
      "query": string
    }

}

export default consulta
