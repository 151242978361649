import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { connect } from 'react-redux';

class TablePlanos extends Component {

    render(){

        let proyecto = this.props.proyectos.filter( proyecto => proyecto.id === this.props.proyecto.id)[0]

        let planos = proyecto.planos.filter( plano => plano.tipoPlano === this.props.carpetaType)

        return(
            <div className="col-11 col-md-10 mx-auto">
                <div className="float-left">
                    <button style={{width: '28px', height: '28px', backgroundColor: '#FF9E00', borderRadius: '50%', border: 'none'}} onClick={ () =>{
                        let route ="ViewPlanosCarpetas"
                        this.props.handleChangeRoute(route)
                    }}>  
                        <FontAwesomeIcon icon='angle-left' style={{color: '#FFFFFF'}} />
                    </button>
                </div>
                <div className="table-responsive" style={{marginTop: '25px'}}>
                    <h2 className="text-center">Planos de {this.props.carpetaType}</h2>
                    <table className="table text-center table-bordered table-hover table-sm">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Nombre de Plano</th>
                                <th scope="col" className="d-none d-md-table-cell">Fecha</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                planos.map( plano => {
                                    
                                    let fecha = plano.fecha,
                                    year = fecha.getFullYear()
                                    let mes
                                    (fecha.getMonth()+1) < 10 ? mes = `0${fecha.getMonth()+1}` : mes = `${fecha.getMonth()+1}`
                                    let dia
                                    fecha.getDate() < 10 ? dia = `0${fecha.getDate()}` : dia = `${fecha.getDate()}`
                                    let hora
                                    fecha.getHours() < 10 ? hora = `0${fecha.getHours()}` : hora = `${fecha.getHours()}`
                                    let minutos
                                    fecha.getMinutes() < 10 ? minutos = `0${fecha.getMinutes()}` : minutos = `${fecha.getMinutes()}`
                                    let seg
                                    fecha.getSeconds() < 10 ? seg = `0${fecha.getSeconds()}` : seg = `${fecha.getSeconds()}`
        
                                    let fechaString = `Fecha: ${dia}/${mes}/${year} a las ${hora}:${minutos}:${seg} horas`
        
                                    return(
                                    <tr key={plano.id}>
                                        <td>{plano.nombreDePlano}</td>
                                        <td className="d-none d-md-table-cell">{fechaString}</td>
                                        <td>
        
                                            <button onClick={ () => {
                                                this.props.handleShowPlano(plano)
                                            }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#1A3B65'}}>
                                            <FontAwesomeIcon icon="eye" />
                                            </button>
        
                                            <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={ () => {
                                                this.props.downloadPlano(plano)
                                            }} >
                                            <FontAwesomeIcon icon="file-download" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#1A3B65'}}/>
                                            </button>
        
                                            {
                                                this.props.usuario.tipo === 'colaborador' ? (
                                                <button onClick={ () => {
                                                    this.props.handleDeletePlano(this.props.proyecto.id, plano.id)
                                                }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#1A3B65'}}>
                                                <FontAwesomeIcon icon="trash-alt" />
                                                </button>
                                            ) : null
                                            }
                                            
                                            
                                        </td>
                                    </tr> 
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        ) 
    }

}

let mapStateToProps = (state, props) => {
    return {
      proyectos: state.dataProyectos.proyectos
    }
  }
  
  export default connect(mapStateToProps)(TablePlanos);