import React from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let UserEdit = props => {

  //console.log(props.updateUserMensaje)

  let usuario = props.usuario,
      usuarioString = JSON.stringify(usuario),
      modalVisible = props.modalVisible,
      handleCloseModal = props.handleCloseModal,
      fechaInicial = new Date(usuario.fecha_de_nacimiento).getTime(),
      fecha_de_nacimiento_Convertido

      if(isNaN(fechaInicial) === true){
        fecha_de_nacimiento_Convertido = new Date(parseInt(usuario.fecha_de_nacimiento))
      }else if(typeof(fechaInicial) === 'number') {
        fecha_de_nacimiento_Convertido = new Date(fechaInicial)
      }

      let mesFecha
      (fecha_de_nacimiento_Convertido.getMonth()+1) < 10 ? mesFecha = `0${fecha_de_nacimiento_Convertido.getMonth()+1}` : mesFecha = `${fecha_de_nacimiento_Convertido.getMonth()+1}`
      let diaFecha
      fecha_de_nacimiento_Convertido.getDate() < 10 ? diaFecha = `0${fecha_de_nacimiento_Convertido.getDate()}` : diaFecha = `${fecha_de_nacimiento_Convertido.getDate()}`
      let fechaInput = `${fecha_de_nacimiento_Convertido.getFullYear()}-${mesFecha}-${diaFecha}`
      
      let proyectos = props.dataProyectos.proyectos
      
      return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/svg/LogoBlanco.svg" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>

                <div className="modal-body sin-margen-y-padding container-fluid">
                  <div className="row text-center">
                    <div className="col-12">
                      <FontAwesomeIcon icon="user-circle" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                      <h1>{usuario.nombre} {usuario.apellidos}</h1>
                    </div>
                    <div className="col-12" style={{'marginTop': '25px'}}>
                      <form>
                        <div className="form-group row">

                          <div className="col-md-4 row">
                            <label htmlFor="usuario" className="col"><b>Usuario:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="usuario" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="usuario" value={usuario.usuario} style={{'top': '-3px'}}/>
                          </div>

                          <div className="col-md-4 row">
                            <label htmlFor="nombre" className="col"><b>Nombre:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="nombre" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="nombre" value={usuario.nombre} style={{'top': '-3px'}}/>
                          </div>

                          <div className="col-md-4 row">
                            <label htmlFor="apellidos" className="col"><b>Apellidos:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="apellidos" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="apellidos" value={usuario.apellidos} style={{'top': '-3px'}}/>
                          </div>

                          <div className="col-md-4 row">
                            <label htmlFor="telefono" className="col"><b>Teléfono:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="telefono" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="telefono" value={usuario.telefono} style={{'top': '-3px'}}/>
                          </div>

                          <div className="col-md-4 row">
                            <label htmlFor="email" className="col"><b>Correo:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="email" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="email" value={usuario.email} style={{'top': '-3px'}}/>
                          </div>

                          <div className="col-md-4 row">
                            <label htmlFor="fecha_de_nacimiento" className="col"><b>Cumpleaños:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="fecha_de_nacimiento" onChange={props.handleInputChange} type="date"  className="col form-control-plaintext form-control-sm inputUserVal" id="fecha_de_nacimiento" value={fechaInput} style={{'top': '-3px'}}/>
                          </div>

                          <div className="col-md-4 row">
                            <label htmlFor="direccion" className="col"><b>Dirección:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="direccion" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="direccion" value={usuario.direccion} style={{'top': '-3px'}}/>
                          </div>

                          <div className="col-md-4 row">
                            <label htmlFor="sexo" className="col"><b>Sexo:</b></label>
                            <select id="sexo" ref={props.setRef} data-usuario={usuarioString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="sexo" value={usuario.sexo}>
                              {
                                props.dataUsers.dataInfo.sexoVals.map( (sexoVal, index) => {
                                  return <option key={index} value={sexoVal}>{sexoVal}</option>
                                })
                              }
                            </select>
                          </div>

                          {props.logInUser.permisos.includes("adminUser") ? (
                            <div className="col-md-4 row">
                              <label htmlFor="tipo" className="col"><b>Tipo:</b></label>
                              {
                                ((props.logInUser.colaborador !== null) && ((props.logInUser.colaborador.puesto === "Proyect Manager") || (props.logInUser.colaborador.puesto === "Secretaria"))) ? (
                                  <select id="tipo" ref={props.setRef} data-usuario={usuarioString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="tipo" value={usuario.tipo}>
                                    <option value="cliente">cliente</option>
                                  </select>
                                ) : (  
                                  <select id="tipo" ref={props.setRef} data-usuario={usuarioString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="tipo" value={usuario.tipo}>
                                    {
                                      props.dataUsers.dataInfo.tipoVals.map( (tipoVal, index) => {
                                        return <option key={index} value={tipoVal}>{tipoVal}</option>
                                      })
                                    }
                                  </select>
                                )
                              }
                            </div>
                          ) : (
                            null
                          )}
                          
                          {props.logInUser.permisos.includes("adminUser") ? (
                            <div className="col-md-4 row">
                              <label htmlFor="permisos" className="col"><b>Permisos:</b></label>
                              {
                                ((props.logInUser.colaborador !== null) && ((props.logInUser.colaborador.puesto === "Proyect Manager") || (props.logInUser.colaborador.puesto === "Secretaria"))) ? (
                                  <select multiple id="permisos" ref={props.setRef} data-usuario={usuarioString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="permisos" value={usuario.permisos}>
                                    <option value="adminProyectos">adminProyectos</option>
                                  </select>
                                ): (
                                  <select multiple id="permisos" ref={props.setRef} data-usuario={usuarioString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="permisos" value={usuario.permisos}>
                                    {
                                      props.dataUsers.dataInfo.permisoVals.map( (permisoVal, index) => {
                                        return <option key={index} value={permisoVal}>{permisoVal}</option>
                                      })
                                    }
                                  </select>
                                )
                              }
                            </div>
                          ) : (
                            null
                          )}

                          {props.logInUser.permisos.includes("adminUser") ? (
                            <div className="col-md-4 row">
                              <label htmlFor="EmpresaAsociada" className="col"><b>Empresa Asociada:</b></label>
                              {
                                ((usuario.empresa_asociada === null) || (usuario.empresa_asociada.id === null)) ? (
                                  <select id="EmpresaAsociada" ref={props.setRef} data-usuario={usuarioString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="EmpresaAsociada" value=''>
                                    <option value=''>Ninguna</option>
                                    {
                                      props.dataEmpresas.empresas.map( (empresa) => {
                                        return <option key={empresa.id} value={empresa.nombre_comercial}>{empresa.nombre_comercial}</option>
                                      })
                                    }
                                  </select>
                                ) : (
                                  <select id="EmpresaAsociada" ref={props.setRef} data-usuario={usuarioString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="EmpresaAsociada" value={usuario.empresa_asociada.nombre}>
                                    <option value=''>Ninguna</option>
                                    {
                                      props.dataEmpresas.empresas.map( (empresa) => {
                                        return <option key={empresa.id} value={empresa.nombre_comercial}>{empresa.nombre_comercial}</option>
                                      })
                                    }
                                  </select>
                                )
                              }
                              
                            </div>
                          ) : (
                            null
                          )}
                          
                          {
                            usuario.tipo === 'colaborador' ? (
                              <div className="col-12">
                                <div className="row">
                                  <h2 className="text-center col-12">Información de Colaborador</h2>

                                  <div className="col-md-4 row">
                                    <label htmlFor="puesto" className="col"><b>Puesto:</b></label>
                                    {
                                      usuario.colaborador.puesto === null ? (
                                        <select id="puesto" ref={props.setRef} data-usuario={usuarioString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="puesto" value="">
                                          <option value=''>Ninguna</option>
                                          <option value='Gerente'>Gerente</option>
                                          <option value='Proyect Manager'>Project Manager</option>
                                          <option value='Secretaria'>Secretaria</option>
                                        </select> 
                                      ): (
                                          <select id="puesto" ref={props.setRef} data-usuario={usuarioString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="puesto" value={usuario.colaborador.puesto}>
                                          <option value=''>Ninguna</option>
                                          <option value='Gerente'>Gerente</option>
                                          <option value='Proyect Manager'>Project Manager</option>
                                          <option value='Secretaria'>Secretaria</option>
                                        </select> 
                                      )
                                    }
                                  </div>
                                    
                                  <div className="col-md-4 row">
                                    <label htmlFor="puesto" className="col"><b>NIT:</b></label>
                                    {
                                      usuario.colaborador.NIT_colaborador === null ? (
                                        <input ref={props.setRef} data-usuario={usuarioString} data-value-type="NIT_colaborador" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="puesto" value="" style={{'top': '-3px'}}/>
                                      ): (  
                                        <input ref={props.setRef} data-usuario={usuarioString} data-value-type="NIT_colaborador" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="puesto" value={usuario.colaborador.NIT_colaborador} style={{'top': '-3px'}}/>
                                      )
                                    }
                                  </div>

                                  <div className="col-md-4 row">
                                    <label htmlFor="puesto" className="col"><b>Contrato:</b></label>
                                    {
                                      usuario.colaborador.contrato === null ? (
                                        <input ref={props.setRef} data-usuario={usuarioString} data-value-type="contrato" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="puesto" value="" style={{'top': '-3px'}}/>
                                      ): (
                                        <input ref={props.setRef} data-usuario={usuarioString} data-value-type="contrato" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="puesto" value={usuario.colaborador.contrato} style={{'top': '-3px'}}/>
                                       )
                                    }
                                  </div>


                                  {
                                    usuario.colaborador.puesto === 'Proyect Manager' ? (
                                    <div className="col-md-10 row" style={{marginTop: '15px'}}>
                                      <label htmlFor="proyectos" className="col"><b>Proyectos Asociados:</b></label>
                                      {
                                        <select style={{marginLeft: '15px'}} multiple id="proyectos" ref={props.setRef} data-usuario={usuarioString} className="inputUserVal" onChange={props.handleInputChange} data-value-type="proyectos" value={usuario.colaborador.ids_proyectos}>
                                        <option value=''>Ninguna</option>
                                        {
                                          proyectos.map( (proyecto, index) => {
                                            return <option key={index} value={proyecto.id}>{proyecto.nombre}</option>
                                          })
                                        }
                                        </select> 
                                      }
                                    </div>
                                    ) : null
                                  }

                                </div>
                              </div>
                            ) : null
                          }

                        </div>
                      </form>
                    </div>
                  </div>
                  {
                    props.updateUserMensaje !== null ? (
                      <div className="row">
                        {
                          (props.updateUserMensaje.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.updateUserMensaje.mensaje}</div>
                          ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.updateUserMensaje.mensaje}</div>
                          )
                        }
                      </div>
                    ) : null
                  }
                  
                </div>
                <div className="modal-footer row" style={{height: 'auto'}}>

                    <div className="col-12 col-md" style={{marginTop: '15px'}}>
                      <button type="button" className="btn btn-default mx-auto" onClick={props.handleSaveUser}><FontAwesomeIcon icon="save" style={{'fontSize': '150%'}} /></button>
                    </div>

                    <div className="col-12 col-md" style={{marginTop: '15px'}}>
                      <button type="button" onClick={() => {
                          let typeModal = 'UserModalPass';
                          props.handleOpenModal(usuario, typeModal)
                      }} className="btn btn-default mx-auto">
                        <FontAwesomeIcon icon="unlock-alt" style={{'fontSize': '150%'}} /> Cambio de Contraseña
                      </button>
                    </div>

                    <div className="col-12 col-md" style={{marginTop: '15px'}}>
                      <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                    </div>



                </div>
              </div>
            </div>
        </ModalB4>
      </ModalContainer>
      )
}

export default UserEdit
