let consulta = () => {
  let string = `{
    auth{
      getLogIn{
        conexion
        usuario{
          id
          usuario
          email
          nombre
          apellidos
          telefono
          fecha_de_nacimiento
          direccion
          sexo
          tipo
          permisos
          empresa_asociada{
            id
            nombre
            telefono
            correo
          }
          colaborador{
            id
            puesto
            NIT_colaborador
            contrato
            ids_proyectos
          }
        }
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
