import React, { Component } from 'react';

import {
    jarallax,
    jarallaxElement
} from 'jarallax';

import cookieToJson from '../../../utils/cookieToJson.js';

import { Redirect } from 'react-router';

import NavBar from '../Containers/NavBar.jsx';
import Inicio from '../Containers/Inicio.jsx';
import Servicios from '../Containers/Servicios.jsx';
import Proyectos from '../Containers/Proyectos.jsx';
import Contacto from '../Containers/Contacto.jsx';
import Footer from '../../Components/Footer.jsx';

class WebPage extends Component {

  constructor(props) {

    super(props);

    this.state = {
      native: false
    }

  }

  async componentDidMount(){

    if(document.cookie){

      let cookies = document.cookie,
      jsonCookies = cookieToJson(cookies),
      native = jsonCookies.native === "true" ? true : false

      this.setState({
        native
      })

      var date = new Date();
      date.setTime(date.getTime()-(1*24*60*60*1000));
      document.cookie = `native=true;expires=${date.toGMTString()}`

    }

    jarallaxElement()
    jarallax(document.querySelectorAll('#inicioSection'))
    jarallax(document.querySelectorAll('#servicios'))
    jarallax(document.querySelectorAll('#proyectos'))
    jarallax(document.querySelectorAll('#contacto'))

  }

  componentWillUnmount(){
    this.setState({
      native: false
    })
  }

  render(){

    if(this.state.native === true){
      return <Redirect to='/login' />
    }else{
      return(
        <div>
          <NavBar />
          <Inicio />
          <Servicios />
          <Proyectos />
          <Contacto />
          <Footer />
        </div>
      )
    }

  }

}

export default WebPage
