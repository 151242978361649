let consulta = (data) => {

  //let dataProyectoString = data.dataProyecto.toString()

  //let stringDataProyecto = `${data.dataProyecto}`
  
  let dataProyectoArrObjStr = data.dataProyecto.map( dataProyecto => {
    return JSON.stringify(dataProyecto).replace(/{"/g, '{').replace(/":/g, ':').replace(/"Semana/g, 'Semana').replace(/"Inicio/g, 'Inicio').replace(/"Fin/g, 'Fin').replace(/"PorcentajeEstimado/g, 'PorcentajeEstimado').replace(/"PorcentajeReal/g, 'PorcentajeReal').replace(/"Diferencia/g, 'Diferencia')
  })

  let dataProyectoString = dataProyectoArrObjStr.toString()

  const string =
    `mutation insertDataProyecto {
      insertDataProyecto(idProyecto: "${data.idProyecto}", dataProyecto: [${dataProyectoString}]){
        idProyecto
        dataProyecto{
          Semana
          Inicio
          Fin
          PorcentajeEstimado
          PorcentajeReal
          Diferencia
        }
        mensaje
      }
    }`

    return {
      "query": string
    }

}

export default consulta
