const initialState = {
  visible: false,
  data: null,
  typeModal: null
}

let Modal = (state = initialState, action) => {
  switch (action.type) {

    case 'OPEN_MODAL': {

      let data = action.payload.data,
          typeModal = action.payload.typeModal,
          visible = true

      return {
        visible,
        data,
        typeModal
      }

    }
    
    case 'UPDATE_MODAL': {

      let data = action.payload.data

      return {
        ...state,
        data
      }

    }
      
    case 'CLOSE_MODAL': {

      let data = null,
          visible = false

      return {
        visible,
        data,
        typeModal: null
      }

    }
      //break;
    default:
      return state
  }
}

export default Modal
