import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class LeftSideToggler extends Component{

  constructor(props){
		super(props)

    this.state = {
      leftRightIcon: 'angle-left'
    }

    // This binding is necessary to make `this` work in the callback
    this.toggleSide = this.toggleSide.bind(this);
	}

  // Toggle the side navigation
  toggleSide = (e) => {

    e.preventDefault();

    document.querySelector("body").classList.toggle("sidenav-toggled");
    document.querySelector(".body").classList.toggle("sidenav-toggled");
    document.querySelector(".navbar-sidenav").classList.add("collapsed");
    //document.querySelector(".nav-link-collapse").classList.add("collapsed");

    let secondLevel = document.querySelectorAll(".sidenav-second-level"),
        thirdLevel = document.querySelectorAll(".sidenav-third-level");

    secondLevel.forEach((item) => {
      item.classList.remove("show");
    });

    thirdLevel.forEach((item) => {
      item.classList.remove("show");
    });

    if(this.state.leftRightIcon === 'angle-left'){
      this.setState({
        leftRightIcon: 'angle-right'
      })
    } else if(this.state.leftRightIcon === 'angle-right'){
      this.setState({
        leftRightIcon: 'angle-left'
      })
    }

  }

	componentDidMount(){

	}

	render(){
		return(
      <ul className="navbar-nav sidenav-toggler d-sm-none d-md-block">
        <li className="nav-item">
          <a href="#link" className="nav-link text-center" id="sidenavToggler" onClick={this.toggleSide} >
            <div id="circleLeftRightAngle" className="mx-auto">
              <FontAwesomeIcon id="leftRightAngle" icon={this.state.leftRightIcon} />
            </div>
            {/*<i className="fa fa-fw fa-angle-left"></i>*/}
          </a>
        </li>
      </ul>
    )
	}
}

export default LeftSideToggler
