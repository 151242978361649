const initialState = {
  usuarios: [],
  colaboradores: [],
  nuevoUsuario: {
    usuario: "",
    password: "",
    email: "",
    nombre: "",
    apellidos: "",
    telefono: "",
    fecha_de_nacimiento: "",
    direccion:"",
    sexo: "Masculino",
    tipo: "cliente",
    permisos: [],
    empresa_asociada: {
      id: null,
      nombre: null,
      telefono: null,
      correo: null
    },
    colaborador: {
      puesto: null,
      NIT_colaborador: null,
      contrato: null,
      ids_proyectos: []
    }
  },
  dataInfo: {}
}

let dataUsers = (state = initialState, action) => {
  switch (action.type) {

    case 'CHANGE_DATA_USER': {

      let user = action.payload.dataUser

      let userArr = [user]

      let newStateUsuarios = state.usuarios.map( obj => userArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        usuarios: newStateUsuarios
      }

      return newState
    }

    case 'NEW_USER': {

      let nuevoUsuario = action.payload.dataUser

      let newState = {
        ...state,
        nuevoUsuario
      }

      return newState

    }
      
    case 'CLEAN_NEW_USER': {

      let newState = {
        ...state,
        nuevoUsuario: {
          usuario: "",
          password: "",
          email: "",
          nombre: "",
          apellidos: "",
          telefono: "",
          fecha_de_nacimiento: "",
          direccion:"",
          sexo: "Masculino",
          tipo: "cliente",
          permisos: [],
          empresa_asociada: {
            id: null,
            nombre: null,
            telefono: null,
            correo: null
          },
          colaborador: {
            puesto: null,
            NIT_colaborador: null,
            contrato: null,
            ids_proyectos: []
          }
        }
      }

      return newState

    }

    case 'DELETE_USER': {

      let user = action.payload.dataUser

      let newUsuarios = state.usuarios.filter( usuario => usuario.id !== user.id)

      let newState = {
        ...state,
        usuarios: newUsuarios
      }

      return newState

    }

    case 'FETCH_USERS': {

      let usuarios = action.payload.dataUsers

      let usuariosConEmpresa = usuarios.map( usuario => {
        if(usuario.empresa_asociada === null){
          usuario.empresa_asociada = {id: null, nombre: null, telefono: null, correo: null}
        }
        return usuario
      })

      //console.log(usuariosConEmpresa)

      let newState = {
        ...state,
        usuarios: usuariosConEmpresa
      }

      return newState
    }
      
    case 'FETCH_COLABORADORES': {

      //console.log(action.payload)

      let colaboradores = action.payload.dataColaboradores

      let newColaboradores = colaboradores.map( colaborador => {
        let ids_proyectos = colaborador.ids_proyectos === null ? colaborador.ids_proyectos = [] : colaborador.ids_proyectos
        return colaborador = {
          ...colaborador,
          ids_proyectos
        }
      });

      let newState = {
        ...state,
        colaboradores: newColaboradores
      }

      return newState
    }
      
    case 'ADD_COLABORADOR_TO_COLABORADORES': {

      let colaborador = action.payload.dataColaborador

      if (colaborador === null) {
        return state
      } else { 

        let colaboradorArr = [colaborador]
  
        let colaboradores = state.colaboradores.concat(colaboradorArr)
  
        let newState = {
          ...state,
          colaboradores
        }
  
        return newState

      }

    }
      
    case 'DELETE_COLABORADOR': {

      let colaborator = action.payload.dataColaborador

      //console.log(colaborator)

      let newColaboradores = state.colaboradores.filter( colaborador => colaborador.id !== colaborator.id)

      let newState = {
        ...state,
        colaboradores: newColaboradores
      }

      return newState

    }

    case 'FETCH_DATA_INFO': {
      let dataInfo = action.payload.dataInfo

      let newState = {
        ...state,
        dataInfo
      }

      return newState
    }

    case 'ADD_USER_TO_USERS': {

      let user = action.payload.dataUser,
          userArr = [user]

      let usuarios = state.usuarios.concat(userArr)

      let newState = {
        ...state,
        usuarios
      }

      return newState
    }

    case 'UNFETCH_USERS': {
      return action.payload.dataUsers
    }

    //break;
    default:
      return state
  }
}

export default dataUsers
