let consulta = (data) => {

  //let dataProyectoString = data.dataProyecto.toString()

  //let stringDataProyecto = `${data.dataProyecto}`
  
  let dataFlujoProyectoArrObjStr = data.dataFlujoProyecto.map( dataFlujoProyecto => {
    return JSON.stringify(dataFlujoProyecto).replace(/{"/g, '{').replace(/":/g, ':').replace(/"PresupuestoAcumulado/g, 'PresupuestoAcumulado').replace(/"PresupuestoEjecutado/g, 'PresupuestoEjecutado').replace(/"PresupuestoRealAcumulado/g, 'PresupuestoRealAcumulado').replace(/"PresupuestoAjustado/g, 'PresupuestoAjustado').replace(/"PresupuestoAjustadoAcumulado/g, 'PresupuestoAjustadoAcumulado').replace(/"Fecha/g, 'Fecha')
  })

  let dataFlujoProyectoString = dataFlujoProyectoArrObjStr.toString()

  const string =
    `mutation insertDataFlujoProyecto {
      insertDataFlujoProyecto(idProyecto: "${data.idProyecto}", dataFlujoProyecto: [${dataFlujoProyectoString}]){
        idProyecto
        dataFlujoProyecto{
          PresupuestoProyectado
          PresupuestoAcumulado
          PresupuestoEjecutado
          PresupuestoRealAcumulado
          PresupuestoAjustado
          PresupuestoAjustadoAcumulado
          Fecha
        }
        mensaje
      }
    }`

    return {
      "query": string
    }

}

export default consulta
