import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './css/ProgresoCard.css';

class ProgresoCard extends Component {

  /*constructor(props) {
    super(props);

  }*/

  render() {

    let porcentaje

    if(this.props.fase === "Desarrollo"){
      porcentaje = 0
    }else if(this.props.fase === "Planificacion"){
      porcentaje = 25
    }else if(this.props.fase === "Ejecucion"){
      porcentaje = 50
    }else if(this.props.fase === "Cierre"){
      porcentaje = 75
    }else if(this.props.fase === "Finalizado"){
      porcentaje = 100
    }

    return (
      <div className="progresoCard col-12" style={{marginTop: '50px'}}>
        <div className="card">
          <div className="card-header">
            <h5 className="card-title text-center">Fase</h5>
          </div>
          <div className="card-body">

            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="progress">
                      <div className="progress-bar bg-success" style={{ width: `${porcentaje}%` }} role="progressbar" aria-valuenow={porcentaje} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className="col-12" style={{marginTop: '15px'}}>
                    <div className="row">

                      <div className="col-3 text-center" style={{borderRight: 'solid #A9A9A9 1px'}}>
                        <div className="row">  
                          <h6 className="col-12" style={{textTransform: "uppercase"}}>Desarrollo</h6>
                          {
                            this.props.fase === "Desarrollo" ? (
                            <div className="col-12 text-center">
                              <FontAwesomeIcon icon="history" style={{fontSize: "30px", color: "#4CC1FF"}} />
                              <p>En Progreso</p>
                            </div>
                            ) : null
                          }
                          {
                            ((this.props.fase === "Planificacion") || (this.props.fase === "Ejecucion") || (this.props.fase === "Cierre") || (this.props.fase === "Finalizado")) ? (
                            <div className="col-12 text-center">
                              <FontAwesomeIcon icon="check" style={{fontSize: "30px", color: "#49E663"}} />
                              <p>Completado</p>
                            </div>
                            ) : null
                          }
                        </div>
                      </div>

                      <div className="col-3 text-center" style={{borderRight: 'solid #A9A9A9 1px'}}>
                        <div className="row">  
                          <h6 className="col-12" style={{textTransform: "uppercase"}}>Planificación</h6>
                          {
                            (this.props.fase === "Desarrollo") ? (
                            <div className="col-12 text-center">
                              <FontAwesomeIcon icon="clock" style={{fontSize: "30px", color: "#EBC82F"}} />
                              <p>Esperando</p>
                            </div>
                            ) : null
                          }
                          {
                            this.props.fase === "Planificacion" ? (
                            <div className="col-12 text-center">
                              <FontAwesomeIcon icon="history" style={{fontSize: "30px", color: "#4CC1FF"}} />
                              <p>En Progreso</p>
                            </div>
                            ) : null
                          }
                          {
                            ((this.props.fase === "Ejecucion") || (this.props.fase === "Cierre") || (this.props.fase === "Finalizado")) ? (
                            <div className="col-12 text-center">
                              <FontAwesomeIcon icon="check" style={{fontSize: "30px", color: "#49E663"}} />
                              <p>Completado</p>
                            </div>
                            ) : null
                          }
                        </div>
                      </div>

                      <div className="col-3 text-center" style={{borderRight: 'solid #A9A9A9 1px'}}>
                        <div className="row">  
                          <h6 className="col-12" style={{textTransform: "uppercase"}}>Ejecución</h6>
                          {
                            ((this.props.fase === "Desarrollo") || (this.props.fase === "Planificacion")) ? (
                            <div className="col-12 text-center">
                              <FontAwesomeIcon icon="clock" style={{fontSize: "30px", color: "#EBC82F"}} />
                              <p>Esperando</p>
                            </div>
                            ) : null
                          }
                          {
                            this.props.fase === "Ejecucion" ? (
                            <div className="col-12 text-center">
                              <FontAwesomeIcon icon="history" style={{fontSize: "30px", color: "#4CC1FF"}} />
                              <p>En Progreso</p>
                            </div>
                            ) : null
                          }
                          {
                            ((this.props.fase === "Cierre") || (this.props.fase === "Finalizado")) ? (
                            <div className="col-12 text-center">
                              <FontAwesomeIcon icon="check" style={{fontSize: "30px", color: "#49E663"}} />
                              <p>Completado</p>
                            </div>
                            ) : null
                          }
                        </div>
                      </div>

                      <div className="col-3 text-center">
                        <div className="row">  
                          <h6 className="col-12" style={{textTransform: "uppercase"}}>Cierre</h6>
                          {
                            ((this.props.fase === "Desarrollo") || (this.props.fase === "Planificacion")  || (this.props.fase === "Ejecucion")) ? (
                            <div className="col-12 text-center">
                              <FontAwesomeIcon icon="clock" style={{fontSize: "30px", color: "#EBC82F"}} />
                              <p>Esperando</p>
                            </div>
                            ) : null
                          }
                          {
                            this.props.fase === "Cierre" ? (
                            <div className="col-12 text-center">
                              <FontAwesomeIcon icon="history" style={{fontSize: "30px", color: "#4CC1FF"}} />
                              <p>En Progreso</p>
                            </div>
                            ) : null
                          }
                          {
                            (this.props.fase === "Finalizado") ? (
                            <div className="col-12 text-center">
                              <FontAwesomeIcon icon="check" style={{fontSize: "30px", color: "#49E663"}} />
                              <p>Completado</p>
                            </div>
                            ) : null
                          }
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

          </div>
          {/*<div className="card-footer bg-transparent">
            <h5 className="card-title text-center">Proyecto: Vidali</h5>
          </div>*/}
        </div>
      </div>
    )
  }
}

export default ProgresoCard