import React from 'react';

import './css/Loader.css';

let Loader = props => (
  <div className="container">
    <div className="row">
      {/*<div className="col-12">
        <img src="img/svg/Logo.svg" className="mx-auto d-block img-fluid" style={{marginTop: '100px',marginBottom: '50px'}} alt="logo" />
      </div>*/}
      <div className="col-12">
        <div className="lds-ring mx-auto d-block"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  </div>
)

export default Loader