let consulta = (proyecto) => {

  //console.log(usuarioJSON.sexo)

  const string =
    `mutation deleteProyecto {
      deleteProyecto( idProyecto: "${proyecto.id}")
      {
        mensaje
        id
      }
    }`

    return {
      "query": string
    }

}

export default consulta
