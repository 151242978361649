import React, { Component } from 'react';

import moment from 'moment';

import BarLineMixedMultiAxisCard from '../../../../Dashboard/dashboard/components/Cards/BarLineMixedMultiAxisCard.jsx';

class Flujo extends Component {

    constructor(props){

        super(props)
    
        let dataProyecto = this.props.proyecto.dataFlujoProyecto,
            firstDataProyectoLabel = "",
            lastDataProyectoLabel = ""
    
        if(dataProyecto !== null){
    
          dataProyecto = this.props.proyecto.dataFlujoProyecto
    
          let dataProyectoLabels = dataProyecto.filter( dataProyecto => {
          
            return dataProyecto.Fecha !== null
        
          })
    
          firstDataProyectoLabel = dataProyectoLabels[0]
          lastDataProyectoLabel = dataProyectoLabels.slice(-1)[0]
    
        }

        //console.log(firstDataProyectoLabel)
    
        let aspectRatio
    
        if((window.innerWidth <= 768) && (window.innerWidth >= 567)) {
          aspectRatio = 1.5
        }else if((window.innerWidth < 567)){
          aspectRatio = 0.8
        }else{
          aspectRatio = 2
        }


        //Config de prueba
        let config

        if(dataProyecto !== null){

          if(this.props.proyecto.dataFlujoProyecto !== null){
  
            config = {
              data: {
                  datasets: [],
                  labels: [],
              },
              options: {
                  aspectRatio: aspectRatio,
                  scales: {
                    yAxes: []
                  }
              }
            }
          }
          //Finaliza Config de Prueba    
    
        }
    
        this.state = {
          dataProyectoDesde: firstDataProyectoLabel !== "" ? firstDataProyectoLabel.Fecha : firstDataProyectoLabel,
          dataProyectoHasta: lastDataProyectoLabel !== "" ? lastDataProyectoLabel.Fecha : lastDataProyectoLabel,
          config
        }
    
        // This binding is necessary to make `this` work in the callback
        this.onSelectChangeDesde = this.onSelectChangeDesde.bind(this);
        this.onSelectChangeHasta = this.onSelectChangeHasta.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
    }

    componentDidMount(){

      window.addEventListener('resize', this.onWindowResize);
  
      let dataProyecto = this.props.proyecto.dataFlujoProyecto,
          This = this,
          //Config de prueba
          config
  
      if(dataProyecto !== null){

        if(this.props.proyecto.dataFlujoProyecto !== null){
          let dataFlujoProyecto = this.props.proyecto.dataFlujoProyecto,
              dataFlujoProyectoFechas = dataFlujoProyecto.map( dataFlujoProyecto => dataFlujoProyecto.Fecha ),
              dataFlujoProyectoPresupuestoProyectado = dataFlujoProyecto.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoProyectado ),
              dataFlujoProyectoPresupuestoEjecutado = dataFlujoProyecto.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoEjecutado ),
              dataFlujoProyectoPresupuestoAjustado = dataFlujoProyecto.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoAjustado ),
              dataFlujoProyectoPresupuestoAcumulado = dataFlujoProyecto.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoAcumulado ),
              dataFlujoProyectoPresupuestoAjustadoAcumulado = dataFlujoProyecto.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoAjustadoAcumulado ),
              dataFlujoProyectoPresupuestoRealAcumulado = dataFlujoProyecto.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoRealAcumulado ),
              dataFlujoProyectoFechasTransformadas = dataFlujoProyectoFechas.map( fecha => {
                const monthNames = ["Ene.", "Feb.", "Mar.", "Abr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
                ];

                let date = moment(fecha, "DD,MM,YYYY"),
                    mes = monthNames[date._d.getMonth()],
                    year = date._d.getFullYear()

                return `${mes} ${year}`
              })

          function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }

          config = {
            ...This.state.config,
            data: {
                datasets: [{
                    label: 'Presupuesto Proyectado',
                    data: dataFlujoProyectoPresupuestoProyectado,
                    yAxisID: 'A',
                    backgroundColor: 'rgba(58, 139, 232, 0.8)',
                    borderColor: 'rgba(58, 139, 232, 1)'
                },
                {
                label: 'Presupuesto Ejecutado',
                data: dataFlujoProyectoPresupuestoEjecutado,
                yAxisID: 'A',
                backgroundColor: 'rgba(179, 127, 71, 0.8)',
                borderColor: 'rgba(179, 127, 71, 1)'
                },
                {
                label: 'Presupuesto Ajustado',
                data: dataFlujoProyectoPresupuestoAjustado,
                yAxisID: 'A',
                backgroundColor: 'rgba(41, 179, 64, 0.8)',
                borderColor: 'rgba(41, 179, 64, 1)'
                },
                {
                // Changes this dataset to become a line
                type: 'line',
                yAxisID: 'B',
                label: 'Presupuesto Ajustado Acumulado',
                data: dataFlujoProyectoPresupuestoAjustadoAcumulado,
                backgroundColor: 'transparent',
                borderColor: 'rgba(255, 164, 35, 1)'
                },
                {
                // Changes this dataset to become a line
                type: 'line',
                yAxisID: 'B',
                label: 'Presupuesto Proyectado Acumulado',
                data: dataFlujoProyectoPresupuestoAcumulado,
                backgroundColor: 'transparent',
                borderColor: 'rgba(85, 36, 179, 1)'
                },
                {
                // Changes this dataset to become a line
                type: 'line',
                yAxisID: 'B',
                label: 'Presupuesto Real Acumulado',
                data: dataFlujoProyectoPresupuestoRealAcumulado,
                backgroundColor: 'transparent',
                borderColor: 'rgba(14, 155, 255, 1)'
                }],
                labels: dataFlujoProyectoFechasTransformadas,
            },
            options: {
                scales: {
                yAxes: [{
                    id: 'A',
                    type: 'linear',
                    position: 'left',
                    ticks: {
                    // Include a Quetzal sign in the ticks
                    callback: function(value, index, values) {
                        if(window.innerWidth < 567){

                          if(This.props.proyecto.divisa === "Quetzales"){
                            return 'Q.' + numberWithCommas(value).slice(0, -4);
                          }else if(This.props.proyecto.divisa === "Dolares"){
                            return '$.' + numberWithCommas(value).slice(0, -4);
                          }

                        }else{
                          if(This.props.proyecto.divisa === "Quetzales"){
                            return 'Q.' + numberWithCommas(value);
                          }else if(This.props.proyecto.divisa === "Dolares"){
                            return '$.' + numberWithCommas(value);
                          }

                        }
                    }
                    },
                    scaleLabel: {
                    display: true,
                    labelString: window.innerWidth > 567 ? 'Barras de Flujo Mensual': '',
                    fontSize: 18
                    }
                    /*ticks: {
                    max: 1,
                    min: 0
                    }*/
                }, {
                    id: 'B',
                    type: 'linear',
                    position: 'right',
                    ticks: {
                    // Include a Quetzal sign in the ticks
                    callback: function(value, index, values) {
                      if(window.innerWidth < 567){

                          if(This.props.proyecto.divisa === "Quetzales"){
                            return 'Q.' + numberWithCommas(value).slice(0, -4);
                          }else if(This.props.proyecto.divisa === "Dolares"){
                            return '$.' + numberWithCommas(value).slice(0, -4);
                          }

                        }else{
                          if(This.props.proyecto.divisa === "Quetzales"){
                            return 'Q.' + numberWithCommas(value);
                          }else if(This.props.proyecto.divisa === "Dolares"){
                            return '$.' + numberWithCommas(value);
                          }
                          
                        }
                    }
                    },
                    scaleLabel: {
                    display: true,
                    labelString: window.innerWidth > 567 ? 'Líneas de Flujo Acumulado': '',
                    fontSize: 18
                    }
                    /*ticks: {
                    max: 1,
                    min: 0
                    }*/
                }]
                }
            }
          }
        }
        //Finaliza Config de Prueba
  
        This.setState({
          config
        })      
  
      }
      
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize(){
      if((window.innerWidth <= 768) && (window.innerWidth >= 567) && (this.state.config !== undefined)) {
        this.setState({ 
          config: {
            ...this.state.config,
            options: {
              ...this.state.config.options,
              aspectRatio: 1.5
            }
          } 
        });
      }else if((window.innerWidth < 567) && (this.state.config !== undefined)){
        this.setState({ 
          config: {
            ...this.state.config,
            options: {
              ...this.state.config.options,
              aspectRatio: 0.8
            }
          } 
        });
      }else if(this.state.config !== undefined){
        this.setState({ 
          config: {
            ...this.state.config,
            options: {
              ...this.state.config.options,
              aspectRatio: 2
            }
          } 
        });
      }
    }

    onSelectChangeDesde(event){


      let dataProyecto = this.props.proyecto.dataFlujoProyecto,
          This = this
  
      if(dataProyecto !== null){

          let dataFlujoProyecto = this.props.proyecto.dataFlujoProyecto

          function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
  
          let dataFlujo = dataFlujoProyecto.filter( dataProyecto => {

            let desde = moment(event.target.value, "DD/MM/YYYY").unix()
            let hasta = moment(this.state.dataProyectoHasta, "DD/MM/YYYY").unix()
            let fecha = moment(dataProyecto.Fecha, "DD/MM/YYYY").unix()
  
          return ((fecha >= desde) && (fecha <= hasta))
      
          })

          let dataFlujoProyectoFechas = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.Fecha ),
          dataFlujoProyectoPresupuestoProyectado = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoProyectado ),
          dataFlujoProyectoPresupuestoEjecutado = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoEjecutado ),
          dataFlujoProyectoPresupuestoAjustado = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoAjustado ),
          dataFlujoProyectoPresupuestoAcumulado = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoAcumulado ),
          dataFlujoProyectoPresupuestoAjustadoAcumulado = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoAjustadoAcumulado ),
          dataFlujoProyectoPresupuestoRealAcumulado = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoRealAcumulado ),
          dataFlujoProyectoFechasTransformadas = dataFlujoProyectoFechas.map( fecha => {
            const monthNames = ["Ene.", "Feb.", "Mar.", "Abr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
            ];

            let date = moment(fecha, "DD,MM,YYYY"),
                mes = monthNames[date._d.getMonth()],
                year = date._d.getFullYear()

            return `${mes} ${year}`
          })
  
  
          This.setState({
          config: {
            ...this.state.config,
            data: {
                datasets: [{
                    label: 'Presupuesto Proyectado',
                    data: dataFlujoProyectoPresupuestoProyectado,
                    yAxisID: 'A',
                    backgroundColor: 'rgba(58, 139, 232, 0.8)',
                    borderColor: 'rgba(58, 139, 232, 1)'
                },
                {
                label: 'Presupuesto Ejecutado',
                data: dataFlujoProyectoPresupuestoEjecutado,
                yAxisID: 'A',
                backgroundColor: 'rgba(179, 127, 71, 0.8)',
                borderColor: 'rgba(179, 127, 71, 1)'
                },
                {
                label: 'Presupuesto Ajustado',
                data: dataFlujoProyectoPresupuestoAjustado,
                yAxisID: 'A',
                backgroundColor: 'rgba(41, 179, 64, 0.8)',
                borderColor: 'rgba(41, 179, 64, 1)'
                },
                {
                // Changes this dataset to become a line
                type: 'line',
                yAxisID: 'B',
                label: 'Presupuesto Ajustado Acumulado',
                data: dataFlujoProyectoPresupuestoAjustadoAcumulado,
                backgroundColor: 'transparent',
                borderColor: 'rgba(255, 164, 35, 1)'
                },
                {
                // Changes this dataset to become a line
                type: 'line',
                yAxisID: 'B',
                label: 'Presupuesto Proyectado Acumulado',
                data: dataFlujoProyectoPresupuestoAcumulado,
                backgroundColor: 'transparent',
                borderColor: 'rgba(85, 36, 179, 1)'
                },
                {
                // Changes this dataset to become a line
                type: 'line',
                yAxisID: 'B',
                label: 'Presupuesto Real Acumulado',
                data: dataFlujoProyectoPresupuestoRealAcumulado,
                backgroundColor: 'transparent',
                borderColor: 'rgba(14, 155, 255, 1)'
                }],
                labels: dataFlujoProyectoFechasTransformadas,
            },
            options: {
                scales: {
                yAxes: [{
                    id: 'A',
                    type: 'linear',
                    position: 'left',
                    ticks: {
                    // Include a Quetzal sign in the ticks
                    callback: function(value, index, values) {
                      if(window.innerWidth < 567){

                        if(This.props.proyecto.divisa === "Quetzales"){
                          return 'Q.' + numberWithCommas(value).slice(0, -4);
                        }else if(This.props.proyecto.divisa === "Dolares"){
                          return '$.' + numberWithCommas(value).slice(0, -4);
                        }

                      }else{
                        if(This.props.proyecto.divisa === "Quetzales"){
                          return 'Q.' + numberWithCommas(value);
                        }else if(This.props.proyecto.divisa === "Dolares"){
                          return '$.' + numberWithCommas(value);
                        }
                        
                      }
                    }
                    },
                    scaleLabel: {
                    display: true,
                    labelString: window.innerWidth > 567 ? 'Barras de Flujo Mensual': '',
                    fontSize: 18
                    }
                    /*ticks: {
                    max: 1,
                    min: 0
                    }*/
                }, {
                    id: 'B',
                    type: 'linear',
                    position: 'right',
                    ticks: {
                    // Include a Quetzal sign in the ticks
                    callback: function(value, index, values) {
                      if(window.innerWidth < 567){

                        if(This.props.proyecto.divisa === "Quetzales"){
                          return 'Q.' + numberWithCommas(value).slice(0, -4);
                        }else if(This.props.proyecto.divisa === "Dolares"){
                          return '$.' + numberWithCommas(value).slice(0, -4);
                        }

                      }else{
                        if(This.props.proyecto.divisa === "Quetzales"){
                          return 'Q.' + numberWithCommas(value);
                        }else if(This.props.proyecto.divisa === "Dolares"){
                          return '$.' + numberWithCommas(value);
                        }
                        
                      }
                    }
                    },
                    scaleLabel: {
                    display: true,
                    labelString: window.innerWidth > 567 ? 'Líneas de Flujo Acumulado': '',
                    fontSize: 18
                    }
                    /*ticks: {
                    max: 1,
                    min: 0
                    }*/
                }]
                }
            }
          },
          dataProyectoDesde: event.target.value
          })
  
      }
    }

    onSelectChangeHasta(event){


      let dataProyecto = this.props.proyecto.dataFlujoProyecto,
          This = this
  
      if(dataProyecto !== null){

          let dataFlujoProyecto = this.props.proyecto.dataFlujoProyecto

          function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
  
          let dataFlujo = dataFlujoProyecto.filter( dataProyecto => {

            let desde = moment(this.state.dataProyectoDesde, "DD/MM/YYYY").unix()
            let hasta = moment(event.target.value, "DD/MM/YYYY").unix()
            let fecha = moment(dataProyecto.Fecha, "DD/MM/YYYY").unix()
  
            return ((fecha >= desde) && (fecha <= hasta))
      
          })

          let dataFlujoProyectoFechas = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.Fecha ),
          dataFlujoProyectoPresupuestoProyectado = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoProyectado ),
          dataFlujoProyectoPresupuestoEjecutado = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoEjecutado ),
          dataFlujoProyectoPresupuestoAjustado = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoAjustado ),
          dataFlujoProyectoPresupuestoAcumulado = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoAcumulado ),
          dataFlujoProyectoPresupuestoAjustadoAcumulado = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoAjustadoAcumulado ),
          dataFlujoProyectoPresupuestoRealAcumulado = dataFlujo.map( dataFlujoProyecto => dataFlujoProyecto.PresupuestoRealAcumulado ),
          dataFlujoProyectoFechasTransformadas = dataFlujoProyectoFechas.map( fecha => {
            const monthNames = ["Ene.", "Feb.", "Mar.", "Abr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
            ];

            let date = moment(fecha, "DD,MM,YYYY"),
                mes = monthNames[date._d.getMonth()],
                year = date._d.getFullYear()

            return `${mes} ${year}`
          })
  
  
          This.setState({
          config: {
            ...this.state.config,
            data: {
                datasets: [{
                    label: 'Presupuesto Proyectado',
                    data: dataFlujoProyectoPresupuestoProyectado,
                    yAxisID: 'A',
                    backgroundColor: 'rgba(58, 139, 232, 0.8)',
                    borderColor: 'rgba(58, 139, 232, 1)'
                },
                {
                label: 'Presupuesto Ejecutado',
                data: dataFlujoProyectoPresupuestoEjecutado,
                yAxisID: 'A',
                backgroundColor: 'rgba(179, 127, 71, 0.8)',
                borderColor: 'rgba(179, 127, 71, 1)'
                },
                {
                label: 'Presupuesto Ajustado',
                data: dataFlujoProyectoPresupuestoAjustado,
                yAxisID: 'A',
                backgroundColor: 'rgba(41, 179, 64, 0.8)',
                borderColor: 'rgba(41, 179, 64, 1)'
                },
                {
                // Changes this dataset to become a line
                type: 'line',
                yAxisID: 'B',
                label: 'Presupuesto Ajustado Acumulado',
                data: dataFlujoProyectoPresupuestoAjustadoAcumulado,
                backgroundColor: 'transparent',
                borderColor: 'rgba(255, 164, 35, 1)'
                },
                {
                // Changes this dataset to become a line
                type: 'line',
                yAxisID: 'B',
                label: 'Presupuesto Proyectado Acumulado',
                data: dataFlujoProyectoPresupuestoAcumulado,
                backgroundColor: 'transparent',
                borderColor: 'rgba(85, 36, 179, 1)'
                },
                {
                // Changes this dataset to become a line
                type: 'line',
                yAxisID: 'B',
                label: 'Presupuesto Real Acumulado',
                data: dataFlujoProyectoPresupuestoRealAcumulado,
                backgroundColor: 'transparent',
                borderColor: 'rgba(14, 155, 255, 1)'
                }],
                labels: dataFlujoProyectoFechasTransformadas,
            },
            options: {
                scales: {
                yAxes: [{
                    id: 'A',
                    type: 'linear',
                    position: 'left',
                    ticks: {
                    // Include a Quetzal sign in the ticks
                    callback: function(value, index, values) {
                      if(window.innerWidth < 567){

                        if(This.props.proyecto.divisa === "Quetzales"){
                          return 'Q.' + numberWithCommas(value).slice(0, -4);
                        }else if(This.props.proyecto.divisa === "Dolares"){
                          return '$.' + numberWithCommas(value).slice(0, -4);
                        }

                      }else{
                        if(This.props.proyecto.divisa === "Quetzales"){
                          return 'Q.' + numberWithCommas(value);
                        }else if(This.props.proyecto.divisa === "Dolares"){
                          return '$.' + numberWithCommas(value);
                        }
                        
                      }
                    }
                    },
                    scaleLabel: {
                    display: true,
                    labelString: window.innerWidth > 567 ? 'Barras de Flujo Mensual': '',
                    fontSize: 18
                    }
                    /*ticks: {
                    max: 1,
                    min: 0
                    }*/
                }, {
                    id: 'B',
                    type: 'linear',
                    position: 'right',
                    ticks: {
                    // Include a Quetzal sign in the ticks
                    callback: function(value, index, values) {
                      if(window.innerWidth < 567){

                        if(This.props.proyecto.divisa === "Quetzales"){
                          return 'Q.' + numberWithCommas(value).slice(0, -4);
                        }else if(This.props.proyecto.divisa === "Dolares"){
                          return '$.' + numberWithCommas(value).slice(0, -4);
                        }

                      }else{
                        if(This.props.proyecto.divisa === "Quetzales"){
                          return 'Q.' + numberWithCommas(value);
                        }else if(This.props.proyecto.divisa === "Dolares"){
                          return '$.' + numberWithCommas(value);
                        }
                        
                      }
                    }
                    },
                    scaleLabel: {
                    display: true,
                    labelString: window.innerWidth > 567 ? 'Líneas de Flujo Acumulado': '',
                    fontSize: 18
                    }
                    /*ticks: {
                    max: 1,
                    min: 0
                    }*/
                }]
                }
            }
          },
          dataProyectoHasta: event.target.value
          })
  
      }
    }

    render(){

      let dataProyecto = this.props.proyecto.dataFlujoProyecto,
      dataProyectoLabels

      if(dataProyecto !== null){
        dataProyectoLabels = dataProyecto.filter( dataProyecto => {

            return dataProyecto.Fecha !== null

        })
      }

      let setDateString = fecha => {
        const monthNames = ["Ene.", "Feb.", "Mar.", "Abr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
              ];

        let date = moment(fecha, "DD,MM,YYYY"),
            mes = monthNames[date._d.getMonth()],
            year = date._d.getFullYear()

        return `${mes} ${year}`

      }

    return(
        <div className="col-12">
            {
                this.props.proyecto.dataFlujoProyecto !== null ? (
                    <div className="col-12">

                        <div className="row">
                            <BarLineMixedMultiAxisCard config={this.state.config} title={window.window.innerWidth < 567 ? `Gráfica de Flujo (Datos en Miles de ${this.props.proyecto.divisa})` : `Gráfica de Flujo (Datos en ${this.props.proyecto.divisa})`} />
                        </div>

                        <div className="row" style={{marginTop: '25px'}}>
                          <div className="col-12 col-md-6">
                          <div className="form-group">
                              <label htmlFor="SelectDesde">Desde Fecha:</label>
                              <select value={this.state.dataProyectoDesde} className="form-control" id="SelectDesde" onChange={this.onSelectChangeDesde}>
                                {
                                  dataProyectoLabels.map( (dataProyecto, key) => <option key={key} value={dataProyecto.Fecha}>{`${setDateString(dataProyecto.Fecha)}`}</option> )
                                }
                              </select>
                          </div>
                          </div>

                          <div className="col-12 col-md-6">
                          <div className="form-group">
                              <label htmlFor="SelectHasta">Hasta Fecha:</label>
                              <select value={this.state.dataProyectoHasta} onChange={this.onSelectChangeHasta} className="form-control" id="SelectHasta">
                                {
                                  dataProyectoLabels.map( (dataProyecto, key) => <option key={key} value={dataProyecto.Fecha}>{`${setDateString(dataProyecto.Fecha)}`}</option> )
                                }
                              </select>
                          </div>
                          </div>
                        </div>

                    </div>
                ) : null
            }
        </div>
    )

  }
}

export default Flujo