import React, { Component } from 'react';

import DashboardComponent from '../components/DashboardComponent.jsx';

//import ProyectoCard from '../components/Cards/ProyectoCard.jsx';
import AvancesCard from '../components/Cards/AvancesCard.jsx';
//import ProgresoCard from '../components/Cards/ProgresoCard.jsx';
//import BarrasCard from '../components/Cards/BarrasCard.jsx';

import GraphQLProyectos from '../../../../apiService/FetchGraphQLProyectos.js';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

class Dashboard extends Component{

	componentDidMount() {

			if(this.props.conexion === false){
			   return <Redirect to='/login' />
				//console.log(this.state.conexion)
			}else {
				//console.log(this.usuario.conexion)
			}
			
			GraphQLProyectos(this)

  	}

	render(){
		if (this.props.conexion === false) {
			return <Redirect to='/login' />
		} else {

			let proyectosDeEmpresaUsuario = this.props.proyectos.proyectos.filter(proyecto => {
				return proyecto.idEmpresa === this.props.usuario.empresa_asociada.id
			})

			let proyectosDeProyectManager = ((this.props.usuario.colaborador !== null) && (this.props.usuario.colaborador.ids_proyectos !== null)) ?  (this.props.proyectos.proyectos.filter(proyecto => {
				return this.props.usuario.colaborador.ids_proyectos.includes(proyecto.id)
			})) : null

			return(
				<DashboardComponent>
					<div className="container-fluid" style={{minHeight: '78vh'}}>
						{/*<ProyectoCard />
						<AvancesCard />
						<ProgresoCard />
						<BarrasCard />*/}
						{
							this.props.usuario.colaborador === null ? null : (
								<div>
									{
										((this.props.usuario.colaborador.puesto === 'Gerente') || (this.props.usuario.colaborador.puesto === 'Secretaria')) ? (
												<div className="row">
													{
														this.props.proyectos.proyectos.map(proyecto => { 
															return <AvancesCard key={proyecto.id} proyecto={proyecto}/>
														})
													}
												</div>
										) : null
									}
									{
										((this.props.usuario.colaborador.puesto === 'Proyect Manager') && (this.props.usuario.colaborador.ids_proyectos !== null)) ? (
												<div className="row">
													{
														proyectosDeProyectManager.map(proyecto => {
															return <AvancesCard key={proyecto.id} proyecto={proyecto}/>
														})
													}
												</div>
										) : null
									}
								</div>
							)
						}
						{
							(this.props.usuario.tipo === 'cliente') ? (
									<div className="row">
										{
											proyectosDeEmpresaUsuario.map(proyecto => {
												return <AvancesCard key={proyecto.id} proyecto={proyecto}/>
											})
										}
									</div>
							) : null
						}
		      </div>
				</DashboardComponent>
			)
		}
	}
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
		usuario: state.logIn.usuario,
		proyectos: state.dataProyectos
  }
}

export default connect(mapStateToProps)(Dashboard)
