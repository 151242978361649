const initialState = {
  conexion: false,
  usuario: null
}

let logIn = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN': {

      //Obtener datos almacenados Res
		  let logIn = action.payload.logIn;

      //console.log(conexionSession)

      if(logIn === null){ //|| conexionSession.conexion === false
			  return state
		  } else {
        return logIn
      }

    }
      //break;
    default:
      return state
  }
}

export default logIn
