import React from 'react';

import './css/AvancesCard.css';

import { Line } from 'chartjs-2-react';

//col-sm-6

let LineChartCart = props => {

  return(
    <div className="avancesCard col-12 mx-auto" style={{marginTop: '50px'}}>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title text-center">{props.title !== undefined ? props.title : "Grafica de lineas"}</h5>
        </div>
        <div className="card-body">
          <Line config={props.config !== undefined ? props.config : {} }/>
        </div>
      </div>
    </div>
  )

}

export default LineChartCart