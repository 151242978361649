let consulta = (proyectoJSON) => {

  //console.log(proyectoJSON)

  const string =
    `mutation insertProyecto {
      insertProyecto(
        insertProyecto: {
          idEmpresa: "${proyectoJSON.idEmpresa}",
          nombre: "${proyectoJSON.nombre}",
          pais: "${proyectoJSON.pais}",
          direccion: "${proyectoJSON.direccion}",
          telefono: "${proyectoJSON.telefono}",
          correo: "${proyectoJSON.correo}",
          presupuesto: ${proyectoJSON.presupuesto},
          presupuestoGastado: ${proyectoJSON.presupuestoGastado},
          estado: "${proyectoJSON.estado}",
          fase: "${proyectoJSON.fase}",
          divisa: ${proyectoJSON.divisa}
        }
      )
      {
        mensaje
        proyecto{
          id
          idEmpresa
          nombre
          pais
          direccion
          telefono
          correo
          presupuesto
          presupuestoGastado
          estado
          fase
          divisa
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
