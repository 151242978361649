import { Component } from 'react'; //React
import { createPortal } from 'react-dom';

class ModalPortal extends Component {
	render(){
		return createPortal(
			this.props.children,
			document.getElementById('modal-container')
		)
	}
}

export default ModalPortal;
