import React, { Component } from 'react';

import './css/ProgresoCard.css';

class ProgresoCard extends Component {

  /*constructor(props) {
    super(props);

    this.state = {
      progreso: 50000,
      presupuesto: 100000
    }

  }*/

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {

    let porcentaje = (this.props.proyecto.presupuestoGastado * 100) / this.props.proyecto.presupuesto

    return (
      <div className="progresoCard col-12 col-sm-6 mx-auto" style={{marginTop: '50px'}}>
        <div className="card">
          <div className="card-header">
            <h5 className="card-title text-center">Progreso Financiero</h5>
          </div>
          <div className="card-body row">
            
            <div className="col-12">
              <div className="row">
                <div className="col-6"><h5>{this.props.proyecto.nombre}</h5></div>
                <div className="col-6"><p className="text-right">{this.props.proyecto.divisa === "Quetzales" ? "Q." : null} {this.props.proyecto.divisa === "Dolares" ? "$." : null} {this.numberWithCommas(this.props.proyecto.presupuestoGastado)}/{this.props.proyecto.divisa === "Quetzales" ? "Q." : null} {this.props.proyecto.divisa === "Dolares" ? "$." : null} {this.numberWithCommas(this.props.proyecto.presupuesto)}</p></div>
                <div className="col-12">
                  <div className="progress">
                    <div className="progress-bar" style={{ width: `${porcentaje}%` }} role="progressbar" aria-valuenow={porcentaje} aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {/*<div className="card-footer bg-transparent">
            <h5 className="card-title text-center">Proyecto: Vidali</h5>
          </div>*/}
        </div>
      </div>
    )
  }
}

export default ProgresoCard