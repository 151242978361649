let consulta = () => {
  let string = `{
    appInfo{
      sexoVals
      permisoVals
      tipoVals
      puestoVals
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
