let consulta = (empresaJSON) => {

  //console.log(empresaJSON)

  const string =
    `mutation updateEmpresa {
      updateEmpresa(
        empresaData: {
          id: "${empresaJSON.id}"
          nombre_comercial: "${empresaJSON.nombre_comercial}"
          nombre_fiscal: "${empresaJSON.nombre_fiscal}"
          telefono: ${empresaJSON.telefono}
          correo: "${empresaJSON.correo}"
          NIT: "${empresaJSON.NIT}"
          direccion: "${empresaJSON.direccion}"
          tipo_empresa: "${empresaJSON.tipo_empresa}"
          regimen_fiscal: "${empresaJSON.regimen_fiscal}"
          cuenta_banco: "${empresaJSON.cuenta_banco}"
        }
      ) {
        id
        mensaje
      }
    }`

    return {
      "query": string
    }

}

export default consulta
