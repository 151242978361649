import React, { Component } from 'react';

import { connect } from 'react-redux';

import sessionService from '../../sessionService/sessionService';
import url from '../../apiService/ApiUrl.js';

//import css
import '../../MyModules/css/slick.css';
import '../../MyModules/css/slick-theme.css';

//Importando Jquery y módulos
//import $ from 'jquery';
import '../../MyModules/jquery.easing.1.3.js';


//Importando Bootstrap
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

//import slick from 'slick-carousel/slick/slick.min.js';

//Router
import { BrowserRouter as Router } from 'react-router-dom';

//Components
import PrincipalRoutes from '../Components/PrincipalRoutes.jsx';

//Importando Iconos de FontAwesome

import { library } from '@fortawesome/fontawesome-svg-core'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUsersCog, faEdit, faTachometerAlt, faAngleLeft, faAngleRight, faBars, faTimes, faSave, faEye, faTrashAlt, faPlusCircle, faUserCircle, faSearch, faBuilding, faUnlockAlt, faUsers, faUserPlus, faQrcode, faDollyFlatbed, faClipboardList, faMotorcycle, faUser, faEnvelope, faLock, faHome, faCity, faFileDownload, faPlus, faMinus, faCogs, faHistory, faClock, faCheck, faImages, faFileContract, faFileInvoice, faFileInvoiceDollar, faLayerGroup, faUndo, faArrowsAltH } from '@fortawesome/free-solid-svg-icons';

import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';


library.add(faSignOutAlt, faUsersCog, faEdit, faTachometerAlt, faAngleLeft, faAngleRight, faBars, faTimes, faSave, faEye, faTrashAlt, faPlusCircle, faUserCircle, faSearch, faBuilding, faUnlockAlt, faUsers, faUserPlus, faQrcode, faDollyFlatbed, faClipboardList, faMotorcycle, faUser, faFacebookF, faInstagram, faLinkedinIn, faEnvelope, faLock, faHome, faCity, faFileDownload, faPlus, faMinus, faCogs, faHistory, faClock, faCheck, faImages, faFileContract, faFileInvoice, faFileInvoiceDollar, faLayerGroup, faUndo, faArrowsAltH)

class App extends Component{

  /*constructor(props) {

    super(props);

    this.state = {
      native: false
    }

  }*/

  componentDidMount(){

    sessionService.getLogIn(url, this)

    //Soporte a clases de android e ios
    var classNames = [];
    if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) classNames.push('device-ios');
    if (navigator.userAgent.match(/android/i)) classNames.push('device-android');

    var html = document.getElementsByTagName('html')[0];

    if (html.classList) html.classList.add.apply(html.classList, classNames);

	}

  render(){
    return (
      <Router>
				<PrincipalRoutes />
			</Router>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
		conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(App);
