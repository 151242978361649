import React from 'react';

import './css/Modal.css';

const Modal = (props) => (
	<div>
		{props.children}
	</div>
)

export default Modal;
