import logIn from './logIn';
import Modal from './Modal';
import dataUsers from './dataUsers';
import dataEmpresas from './dataEmpresas';
import dataProyectos from './dataProyectos';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  logIn,
  Modal,
  dataUsers,
  dataEmpresas,
  dataProyectos
})

export default rootReducer
