let consulta = (data) => {

  let descripcion

  if(data.descripcion === ""){
    descripcion = null
  }else{
    descripcion = `"${data.descripcion}"`
  }

  const string =
    `mutation insertImagenProyecto {
      insertImagenProyecto(idProyecto:"${data.idProyecto}", imagenProyecto: "${data.b64}", descripcion: ${descripcion})
      {
        idProyecto
        imagenProyecto
        descripcion
        fecha
        mensaje
      }
    }`

    return {
      "query": string
    }

}

export default consulta
