import React from 'react';


import AvancesCard from '../../../Components/Cards/AvancesCard.jsx';
import ProgresoCard from '../../../Components/Cards/ProgresoCard.jsx';

import Loader from '../../../../Components/Loader.jsx';


let DataProyecto = props => {
    return(
        <div className="modal-body container-fluid">

            {
                props.state.subirReporte === false ? (
                <div className="row text-center">

                    <div className="col-12">

                    <div className="row">

                        {
                        props.empresa.logoEmpresa !== null ? (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" style={{marginTop: "25px"}}>
                            <img className="img-fluid mx-auto" src={`data:image/png;base64, ${props.empresa.logoEmpresa}`} alt="logoEmpresa" style={{maxWidth: "150px"}}/>
                            </div>
                        ) : (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" style={{marginTop: "25px"}}>
                            <img className="img-fluid mx-auto" src="/img/svg/LogoEmpresa.svg" alt="logoEmpresa" style={{maxWidth: "150px"}} />
                            </div>
                        )
                        }

                        {
                        props.proyecto.logoProyecto !== null ? (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" style={{marginTop: "25px"}}>
                            <img className="img-fluid mx-auto" src={`data:image/png;base64, ${props.proyecto.logoProyecto}`} alt="logoProyecto" style={{maxWidth: "150px"}}/>
                            </div>
                        ) : (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" style={{marginTop: "25px"}}>
                            <img className="img-fluid mx-auto" src="/img/svg/LogoProyecto.svg" alt="logoProyecto" style={{maxWidth: "150px"}}/>
                            </div>
                        )
                        } 

                    </div>

                    <div className="row">
                        <div className="col" style={{marginTop: "25px"}}>
                        {/*<FontAwesomeIcon icon="city" style={{'fontSize': '400%', 'marginTop': '25px'}} />*/}
                        <h1>PROYECTO: {props.proyecto.nombre}</h1>
                        </div>
                    </div>

                    </div>

                    <div className="col-12" style={{'marginTop': '25px'}}>

                    <div className="row">

                        <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                        <label htmlFor="nombreProyecto" className="col"><b>Nombre:</b></label>
                        <input id="nombreProyecto" ref={props.This.props.setRef} data-proyecto={props.proyectoString} data-value-type="nombreProyecto" onChange={props.This.props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.proyecto.nombre} style={{'top': '-3px'}}/>
                        </div>
                        
                        <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                        <label htmlFor="empresaId" className="col"><b>Empresa o Desarrollador:</b></label>
                        <select id="empresaId" ref={props.This.props.setRef} data-proyecto={props.proyectoString} className="inputUserVal" onChange={props.This.props.handleInputChange} data-value-type="empresaId" value={props.proyecto.idEmpresa}>
                            {
                            props.This.props.dataEmpresas.empresas.map( (empresa, index) => {
                                return <option key={index} value={empresa.id}>{empresa.nombre_comercial}</option>
                            })
                            }
                        </select>
                        </div>

                        <div className="col-md-4 row mx-auto" style={{paddingRight: '50px', marginTop: '25px'}}>
                        <label htmlFor="paisProyecto" className="col"><b>País:</b></label>
                        <input id="paisProyecto" ref={props.This.props.setRef} data-proyecto={props.proyectoString} data-value-type="paisProyecto" onChange={props.This.props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.proyecto.pais} style={{'top': '-3px'}}/>
                        </div>

                        <div className="col-md-4 row mx-auto" style={{paddingRight: '50px', marginTop: '25px'}}>
                        <label htmlFor="direccionProyecto" className="col"><b>Dirección:</b></label>
                        <input id="direccionProyecto" ref={props.This.props.setRef} data-proyecto={props.proyectoString} data-value-type="direccionProyecto" onChange={props.This.props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.proyecto.direccion} style={{'top': '-3px'}}/>
                        </div>

                        <div className="col-md-4 row mx-auto" style={{paddingRight: '50px', marginTop: '25px'}}>
                        <label htmlFor="telefonoProyecto" className="col"><b>Teléfono:</b></label>
                        <input id="telefonoProyecto" ref={props.This.props.setRef} data-proyecto={props.proyectoString} data-value-type="telefonoProyecto" onChange={props.This.props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.proyecto.telefono} style={{'top': '-3px'}}/>
                        </div>

                        <div className="col-md-4 row mx-auto" style={{paddingRight: '50px', marginTop: '25px'}}>
                        <label htmlFor="correoProyecto" className="col"><b>Correo:</b></label>
                        <input id="correoProyecto" ref={props.This.props.setRef} data-proyecto={props.proyectoString} data-value-type="correoProyecto" onChange={props.This.props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.proyecto.correo} style={{'top': '-3px'}}/>
                        </div>

                        <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                            <label htmlFor="divisaProyecto" className="col"><b>Divisa:</b></label>
                            <select id="divisaProyecto" ref={props.This.props.setRef} data-proyecto={props.proyectoString} className="inputUserVal" onChange={props.This.props.handleInputChange} data-value-type="divisaProyecto" value={props.proyecto.divisa}>
                                <option value="Quetzales">Quetzales</option>
                                <option value="Dolares">Dolares</option>
                            </select>
                        </div>
                        
                        <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                        <label htmlFor="presupuesto" className="col"><b>Presupuesto:</b></label>
                        <input id="presupuesto" ref={props.This.props.setRef} data-proyecto={props.proyectoString} data-value-type="presupuesto" onChange={props.This.props.handleInputChange} type="number"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.proyecto.presupuesto} style={{'top': '-3px'}}/>
                        </div>
                        
                        <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                        <label htmlFor="presupuestoGastado" className="col"><b>Presupuesto Gastado:</b></label>
                        <input id="presupuestoGastado" ref={props.This.props.setRef} data-proyecto={props.proyectoString} data-value-type="presupuestoGastado" onChange={props.This.props.handleInputChange} type="number"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.proyecto.presupuestoGastado} style={{'top': '-3px'}}/>
                        </div>

                        <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                        <label htmlFor="estadoProyecto" className="col"><b>Estado:</b></label>
                        <select id="estadoProyecto" ref={props.This.props.setRef} data-proyecto={props.proyectoString} className="inputUserVal" onChange={props.This.props.handleInputChange} data-value-type="estadoProyecto" value={props.proyecto.estado}>
                            <option value="OS">OS (En Tiempo)</option>
                            <option value="BS">BS (Atrasado)</option>
                            <option value="AS">AS (Adelantado)</option>
                        </select>
                        </div>

                        <div className="col-md-4 row mx-auto" style={{marginTop: '25px'}}>
                        <label htmlFor="faseProyecto" className="col"><b>Fase:</b></label>
                        <select id="faseProyecto" ref={props.This.props.setRef} data-proyecto={props.proyectoString} className="inputUserVal" onChange={props.This.props.handleInputChange} data-value-type="faseProyecto" value={props.proyecto.fase}>
                            <option value="Desarrollo">Desarrollo</option>
                            <option value="Planificacion">Planificación</option>
                            <option value="Ejecucion">Ejecución</option>
                            <option value="Cierre">Cierre</option>
                            <option value="Finalizado">Finalizado</option>
                        </select>
                        </div> 

                    </div>


                    </div>

                    <div className="col-12" style={{marginTop: '50px'}}>
                    <div className="row" style={{paddingLeft: '25px', paddingRight: '25px'}}>
                        {/*<AvancesCard proyecto={proyecto} />*/}

                        {
                        props.proyecto.dataProyecto !== null ? (
                            <AvancesCard title="Porcentaje Avance Estimado" porcentaje={props.porcentajeEstimadoVal} proyecto={props.proyecto} backgroundColor="#4bc0c0" />
                        ) : null
                        }

                        {
                        props.proyecto.dataProyecto !== null ? (
                            <AvancesCard title="Porcentaje Avance Real" porcentaje={props.porcentajeRealVal} proyecto={props.proyecto} backgroundColor="#4bc000" />
                        ) : null
                        }

                        
                        <ProgresoCard proyecto={props.proyecto} />
                    </div>
                    </div>
                    

                    <div className="col-12" style={{'marginTop': '25px'}}>
                        <button type="button" className="mx-auto btn btn-primary" onClick={props.This.handleChangeSubirReporte}>Agregar Reporte SEP</button>
                    </div>

                    <div className="col-12 custom-file mx-auto">
                    <label htmlFor="logo">Logo: </label><br/>
                    <input type="file" className="archivo1" id="logo" name="logo" required style={{width: '1px', height: '1px', opacity: '0', overflow: 'hidden', position: 'absolute', zIndex: '-1'}} onChange={props.This.handleInputFileChangeLogo}/>
                    <label className="btn btn-outline-primary mx-auto" htmlFor="logo" style={{cursor: 'pointer'}}><span>Seleccionar Imagen de Logo</span></label>
                    </div>

                    <div className="col-12 custom-file mx-auto" style={{marginTop: '35px', marginBottom: '45px'}}>
                    <label htmlFor="archivo">Archivo: </label><br/>
                    <input type="file" className="archivo1" id="excelAvance" name="excelAvance" required style={{width: '1px', height: '1px', opacity: '0', overflow: 'hidden', position: 'absolute', zIndex: '-1'}} onChange={props.This.handleInputFileChangeAvanceExcel}/>
                    <label className="btn btn-outline-primary mx-auto" htmlFor="excelAvance" style={{cursor: 'pointer'}}><span>Selecciona Excel para Actualizar Datos de Avance del Proyecto</span></label>
                    </div>

                    <div className="col-12 custom-file mx-auto" style={{marginTop: '35px', marginBottom: '45px'}}>
                    <label htmlFor="archivo">Archivo: </label><br/>
                    <input type="file" className="archivo1" id="excelFlujo" name="excelFlujo" required style={{width: '1px', height: '1px', opacity: '0', overflow: 'hidden', position: 'absolute', zIndex: '-1'}} onChange={props.This.handleInputFileChangeFlujoExcel}/>
                    <label className="btn btn-outline-primary mx-auto" htmlFor="excelFlujo" style={{cursor: 'pointer'}}><span>Selecciona Excel para Actualizar Datos de Flujo del Proyecto</span></label>
                    </div>

                    <div className="col-12 col-md" style={{marginTop: '15px'}}>
                        <button type="button" className="btn btn-outline-info mx-auto" onClick={ () =>{
                            let route ="SubirImagenes"
                            props.This.handleChangeRoute(route)
                        }}>Subir Imagenes</button>
                    </div>

                    <div className="col-12 col-md" style={{marginTop: '15px'}}>
                        <button type="button" className="btn btn-outline-info mx-auto" onClick={ () =>{
                            let route ="SubirPlano"
                            props.This.handleChangeRoute(route)
                        }}>Subir Plano</button>
                    </div>

                    {
                    props.state.loading === true ? (
                        <div className="col-12">
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-3 mx-auto">
                            <Loader />
                            </div>
                        </div>
                        </div>
                    ) : null
                    }
                    
                </div>
                ) : null
            }

            {
                props.state.subirReporte === true ? (
                <div className="row text-center" style={{marginBottom: '50px'}}>

                    <div className="col-12 custom-file mx-auto">
                    <label htmlFor="archivo">Archivo: </label><br/>
                    <input type="file" className="archivo1" id="archivo" name="archivo" required style={{width: '1px', height: '1px', opacity: '0', overflow: 'hidden', position: 'absolute', zIndex: '-1'}} onChange={props.This.handleInputFileChangeSEP}/>
                    <label className="btn btn-outline-primary mx-auto" htmlFor="archivo" style={{cursor: 'pointer'}}><span>Seleccionar Reporte SEP</span></label>
                    </div>

                    <div className="col-12 mx-auto" style={{'marginTop': '35px'}}>
                        <button type="button" className="btn btn-outline-danger mx-auto" onClick={props.This.handleChangeSubirReporte}> Cancelar Agregar Reporte SEP</button>
                    </div>

                    {
                    props.state.loading === true ? (
                        <div className="col-12">
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-3 mx-auto">
                            <Loader />
                            </div>
                        </div>
                        </div>
                    ) : null
                    }

                </div>
                ) : null
            }

            {/*<div className="row text-center">
                <div className="col-12 col-md" style={{marginTop: '15px'}}>
                <button type="button" className="btn btn-outline-info mx-auto" onClick={ () =>{
                    let route ="SubirImagenes"
                    props.This.handleChangeRoute(route)
                }}>Subir Imagenes</button>
                </div>
            </div>*/}


            {
                props.This.props.updateProyectoMensaje !== null ? (
                    <div className="row" style={{marginTop: '25px'}}>
                    {
                        (props.This.props.updateProyectoMensaje.response === 'success') ? (
                        <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.This.props.updateProyectoMensaje.mensaje}</div>
                        ) : (
                        <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.This.props.updateProyectoMensaje.mensaje}</div>
                        )
                    }
                    </div>
                ) : null
            }

            {
                props.state.insertReporteSEPMensaje !== null ? (
                    <div className="row" style={{marginTop: '25px'}}>
                    {
                        (props.state.insertReporteSEPMensaje.response === 'success') ? (
                        <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.state.insertReporteSEPMensaje.mensaje}</div>
                        ) : (
                        <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.state.insertReporteSEPMensaje.mensaje}</div>
                        )
                    }
                    </div>
                ) : null
            }

            {
                props.state.insertProyectoDataMensaje !== null ? (
                    <div className="row" style={{marginTop: '25px'}}>
                    {
                        (props.state.insertProyectoDataMensaje.response === 'success') ? (
                        <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.state.insertProyectoDataMensaje.mensaje}</div>
                        ) : (
                        <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.state.insertProyectoDataMensaje.mensaje}</div>
                        )
                    }
                    </div>
                ) : null
            }

            {
                props.state.insertProyectoLogoMensaje !== null ? (
                    <div className="row" style={{marginTop: '25px'}}>
                    {
                        (props.state.insertProyectoLogoMensaje.response === 'success') ? (
                        <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.state.insertProyectoLogoMensaje.mensaje}</div>
                        ) : (
                        <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.state.insertProyectoLogoMensaje.mensaje}</div>
                        )
                    }
                    </div>
                ) : null
            }

        </div>
    )
}

export default DataProyecto