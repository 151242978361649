import React from 'react';

import './css/footer.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let Footer = props => (
		<footer>

		<div className="container-fluid" id="contenedor-footer">

			<div className="row">
				
				<div className="col-12 col-md-6" style={{marginBottom: '15px'}}>
					<div className="row justify-content-center">
						<a className="footerIcon" href="https://www.facebook.com/pm502/">
							<FontAwesomeIcon icon={['fab', 'facebook-f']} />
						</a>
						<a className="footerIcon" href="#instagram">
							<FontAwesomeIcon icon={['fab', 'instagram']} />
						</a>
						<a className="footerIcon" href="#linkedin">
							<FontAwesomeIcon icon={['fab', 'linkedin-in']} />
						</a>
					</div>
				</div>

				<div className="col-12 col-md-6">
					<p className="text-center">Copyright &copy; <img src="/img/svg/logoP-lao.svg" alt="Logo P-lao" /></p>
				</div>

			</div>

		</div>

	</footer>
	)

export default Footer;
