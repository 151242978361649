let consulta = (data) => {

  const string =
    `mutation insertLogoEmpresa{
      insertLogoEmpresa(idEmpresa:"${data.idEmpresa}" ,logoEmpresa: "${data.logoEmpresa}")
      {
        idEmpresa
        logoEmpresa
        mensaje
      }
    }`

    return {
      "query": string
    }

}

export default consulta
