let consulta = () => {
  let string = `{
    appInfo{
      empresas{
        id
        nombre_comercial
        nombre_fiscal
        telefono
        correo
        NIT
        direccion
        tipo_empresa
        regimen_fiscal
        cuenta_banco
        contacto_directo{
          nombre
          apellidos
          correo
          telefono
          direccion
          sexo
        }
        logoEmpresa
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
