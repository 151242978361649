import React, { Component } from 'react';

import { connect } from 'react-redux';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from '../../../Components/Loader.jsx';

import GraphQLLogoEmpresa from './NewLogoEmpresaFetch/FetchNewLogoEmpresa.js';

class EmpresaEdit extends Component {

  constructor(props){
    super(props)
    
    this.state = {
      loading: false,
      insertEmpresaLogoMensaje: null
    }

    // This binding is necessary to make `this` work in the callback
    this.handleInputFileChangeLogo = this.handleInputFileChangeLogo.bind(this);
  }

  handleInputFileChangeLogo(){
    let This = this,
    empresa = this.props.empresa,
    logoEmpresaData = {
      idEmpresa: empresa.id
    }

    function convertToBase64() {
        //Read File
        var selectedFile = document.getElementById("logo").files;
        //Check File is not Empty
        if (selectedFile.length > 0) {

            This.setState({
              loading: true
            })

            // Select the very first file from list
            var fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            var fileReader = new FileReader();
            var base64;
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                //console.re.log(base64);
                //console.log(base64);
                logoEmpresaData.logoEmpresa = base64

                //console.log(logoEmpresaData)

                GraphQLLogoEmpresa(This, logoEmpresaData)
                
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }
    convertToBase64()
  }

  render(){
    let empresa = this.props.empresa,
      empresaString = JSON.stringify(empresa),
      modalVisible = this.props.modalVisible,
      handleCloseModal = this.props.handleCloseModal

    return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/svg/LogoBlanco.svg" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>

                <div className="modal-body sin-margen-y-padding container-fluid">
                  <div className="row text-center">


                    <div className="col-12">

                      <div className="row">
                      {
                          empresa.logoEmpresa !== null ? (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" style={{marginTop: "25px"}}>
                              <img className="img-fluid" src={`data:image/png;base64, ${empresa.logoEmpresa}`} alt="logoEmpresa" style={{maxWidth: "150px"}}/>
                            </div>
                          ) : (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" style={{marginTop: "25px"}}>
                              <img className="img-fluid" src="/img/svg/LogoEmpresa.svg" alt="logoEmpresa" style={{maxWidth: "150px"}} />
                            </div>
                          )
                        }
                      </div>

                      <div className="row">
                        <div className="col" style={{marginTop: "25px"}}>
                          {/*<FontAwesomeIcon icon="building" style={{'fontSize': '400%', 'marginTop': '25px'}} />*/}
                          <h1>Empresa: {empresa.nombre_comercial}</h1>
                        </div>
                      </div>

                    </div>
                    <div className="col-12 row" style={{'marginTop': '25px'}}>

                      <div className="col-md-4 row">
                        <label htmlFor="nombreComercialEmpresa" className="col"><b>Nombre Comercial:</b></label>
                        <input id="nombreComercialEmpresa" ref={this.props.setRef} data-empresa={empresaString} data-value-type="nombre_comercial" onChange={this.props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.nombre_comercial} style={{'top': '-3px'}}/>
                      </div>

                      <div className="col-md-4 row">
                        <label htmlFor="nombreFiscal" className="col"><b>Nombre Fiscal:</b></label>
                        <input id="nombreFiscal" ref={this.props.setRef} data-empresa={empresaString} data-value-type="nombre_fiscal" onChange={this.props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.nombre_fiscal} style={{'top': '-3px'}}/>
                      </div>

                      <div className="col-md-4 row">
                        <label htmlFor="TelefonoEmpresa" className="col"><b>Teléfono:</b></label>
                        <input id="TelefonoEmpresa" ref={this.props.setRef} data-empresa={empresaString} data-value-type="telefono" onChange={this.props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.telefono} style={{'top': '-3px'}}/>
                      </div>

                      <div className="col-md-4 row">
                        <label htmlFor="correo" className="col"><b>Correo:</b></label>
                        <input id="correo" ref={this.props.setRef} data-empresa={empresaString} data-value-type="correo" onChange={this.props.handleInputChange} type="email"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.correo} style={{'top': '-3px'}}/>
                      </div>

                      <div className="col-md-4 row">
                        <label htmlFor="NIT" className="col"><b>NIT:</b></label>
                        <input id="NIT" ref={this.props.setRef} data-empresa={empresaString} data-value-type="NIT" onChange={this.props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.NIT} style={{'top': '-3px'}}/>
                      </div>

                      <div className="col-md-4 row">
                        <label htmlFor="Direccion" className="col"><b>Dirección:</b></label>
                        <input id="Direccion" ref={this.props.setRef} data-empresa={empresaString} data-value-type="direccion" onChange={this.props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.direccion} style={{'top': '-3px'}}/>
                      </div>

                      <div className="col-md-4 row">
                        <label htmlFor="tipoEmpresa" className="col"><b>Tipo de Empresa:</b></label>
                        <select id="tipoEmpresa" ref={this.props.setRef} data-empresa={empresaString}className="inputUserVal" onChange={this.props.handleInputChange} data-value-type="tipoEmpresa" value={empresa.tipo_empresa}>
                          <option value='proveedor'>proveedor</option>
                          <option value='consumidor'>consumidor</option>
                        </select>
                      </div>

                      <div className="col-md-4 row">
                        <label htmlFor="regimenFiscal" className="col"><b>Regimen Fiscal:</b></label>
                        <select id="regimenFiscal" ref={this.props.setRef} data-empresa={empresaString}className="inputUserVal" onChange={this.props.handleInputChange} data-value-type="regimenFiscal" value={empresa.regimen_fiscal}>
                          <option value='Regimen 5%'>Regimen 5%</option>
                          <option value='Regimen 25%'>Regimen 25%</option>
                        </select>
                      </div>

                      <div className="col-md-4 row">
                        <label htmlFor="CuentaDeBanco" className="col"><b>Cuenta De Banco:</b></label>
                        <input id="CuentaDeBanco" ref={this.props.setRef} data-empresa={empresaString} data-value-type="cuenta_banco" onChange={this.props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.cuenta_banco} style={{'top': '-3px'}}/>
                      </div>

                      <div className="col-12 custom-file mx-auto" style={{marginBottom: '75px', marginTop: '25px'}}>
                        <label htmlFor="logo">Logo: </label><br/>
                        <input type="file" className="archivo1" id="logo" name="logo" required style={{width: '1px', height: '1px', opacity: '0', overflow: 'hidden', position: 'absolute', zIndex: '-1'}} onChange={this.handleInputFileChangeLogo}/>
                        <label className="btn btn-outline-primary mx-auto" htmlFor="logo" style={{cursor: 'pointer'}}><span>Seleccionar Imagen de Logo</span></label>
                      </div>


                    </div>

                  </div>

                  <div className="row">
                    {
                      this.state.loading === true ? (
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12 col-md-5 col-lg-3 mx-auto">
                              <Loader />
                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                  </div>

                  {
                      this.props.updateEmpresaMensaje !== null ? (
                        <div className="row" style={{marginTop: '25px'}}>
                          {
                            (this.props.updateEmpresaMensaje.response === 'success') ? (
                              <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.props.updateEmpresaMensaje.mensaje}</div>
                            ) : (
                              <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.props.updateEmpresaMensaje.mensaje}</div>
                            )
                          }
                        </div>
                      ) : null
                    }

                    {
                      this.state.insertEmpresaLogoMensaje !== null ? (
                        <div className="row" style={{marginTop: '25px'}}>
                          {
                            (this.state.insertEmpresaLogoMensaje.response === 'success') ? (
                              <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.insertEmpresaLogoMensaje.mensaje}</div>
                            ) : (
                              <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.insertEmpresaLogoMensaje.mensaje}</div>
                            )
                          }
                        </div>
                      ) : null
                  }

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default mx-auto" onClick={this.props.handleSaveEmpresa}><FontAwesomeIcon icon="save" style={{'fontSize': '150%'}} /></button>
                  <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                </div>
              </div>
            </div>
        </ModalB4>
      </ModalContainer>
    )
  }

}

let mapStateToProps = (state, props) => {
  /*return {
    dataProyectos: state.dataProyectos,
    dataEmpresas: state.dataEmpresas
  }*/
  return props
}

export default connect(mapStateToProps)(EmpresaEdit);
