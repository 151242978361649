import React, { Component } from 'react';

import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';

import GraphQL from '../../../../apiService/FetchGraphQLEmpresas.js';

import deleteEmpresa from './DeleteEmpresaFetch/FetchDeleteEmpresa.js';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import EmpresaTable from '../components/EmpresaTable.jsx';

class AdminEmpresaDashboard extends Component{

	constructor(props) {
    super(props);

		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleDeleteEmpresa = this.handleDeleteEmpresa.bind(this);
  }

	handleOpenModal(data, typeModal){
    this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				data,
				typeModal
			}
		})

  }

	handleDeleteEmpresa(empresa) {
		
		//console.log(empresa)

		deleteEmpresa(this, empresa)

	}

	componentDidMount() {

			if(this.props.conexion === false){
			   return <Redirect to='/login' />
			}else if(this.props.usuario.permisos.includes('adminEmpresa') === false){
				return <Redirect to='/dashboard' />
				//console.log(this.usuario.conexion)
			}

			GraphQL(this);

  	}

		setButton = element => {
			if(element.dataset.modalType === 'viewEmpresa'){
				this.button = element.id;
				//console.log(element.id);
			}else if(element.dataset.modalType === 'editEmpresa'){
				this.button = element.id;
				//console.log(element);
			}

		}

	render(){
		if (this.props.conexion === false || this.props.usuario.permisos.includes('adminUser') === false) {
			return <Redirect to='/login' />
		}else{
			return(
				<DashboardComponent>
					<div className="container-fluid" style={{minHeight: '78vh'}}>
						<EmpresaTable setButtonViewUser={this.setButton} setButtonEditEmpresa={this.setButton} dataEmpresas={this.props.dataEmpresas}  handleOpenModal={this.handleOpenModal} handleDeleteEmpresa={this.handleDeleteEmpresa}/>
		      </div>
				</DashboardComponent>
			)
		}
	}
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
		usuario: state.logIn.usuario,
		dataEmpresas: state.dataEmpresas
  }
}

export default connect(mapStateToProps)(AdminEmpresaDashboard)
