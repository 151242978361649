let handleInputChange = (event, This) => {

  let value = event.target.value,
      proyecto = event.target.dataset.proyecto,
      type = event.target.dataset.valueType

  This.props.dataProyectos.proyectos.filter(obj => {

    if((obj.id === JSON.parse(proyecto).id) === true){

      if(type === 'nombreProyecto'){

        //console.log(obj)

        obj.nombre = value

        This.props.dispatch({
          type:'CHANGE_DATA_PROYECTOS',
          payload: {
            dataProyecto: obj
          }
        })

      }else if(type === 'empresaId'){

        //console.log(obj)

        obj.idEmpresa = value

        This.props.dispatch({
          type:'CHANGE_DATA_PROYECTOS',
          payload: {
            dataProyecto: obj
          }
        })

      }else if(type === 'presupuesto'){

        //console.log(obj)

        obj.presupuesto = parseInt(value)

        This.props.dispatch({
          type:'CHANGE_DATA_PROYECTOS',
          payload: {
            dataProyecto: obj
          }
        })

      }else if(type === 'presupuestoGastado'){

        //console.log(obj)

        obj.presupuestoGastado = parseInt(value)

        This.props.dispatch({
          type:'CHANGE_DATA_PROYECTOS',
          payload: {
            dataProyecto: obj
          }
        })

      }else if(type === 'paisProyecto'){

        //console.log(obj)

        obj.pais = value

        This.props.dispatch({
          type:'CHANGE_DATA_PROYECTOS',
          payload: {
            dataProyecto: obj
          }
        })

      }else if(type === 'direccionProyecto'){

        //console.log(obj)

        obj.direccion = value

        This.props.dispatch({
          type:'CHANGE_DATA_PROYECTOS',
          payload: {
            dataProyecto: obj
          }
        })

      }else if(type === 'telefonoProyecto'){

        //console.log(obj)

        obj.telefono = value

        This.props.dispatch({
          type:'CHANGE_DATA_PROYECTOS',
          payload: {
            dataProyecto: obj
          }
        })

      }else if(type === 'correoProyecto'){

        //console.log(obj)

        obj.correo = value

        This.props.dispatch({
          type:'CHANGE_DATA_PROYECTOS',
          payload: {
            dataProyecto: obj
          }
        })

      }else if(type === 'estadoProyecto'){

        //console.log(obj)

        obj.estado = value

        This.props.dispatch({
          type:'CHANGE_DATA_PROYECTOS',
          payload: {
            dataProyecto: obj
          }
        })

      }else if(type === 'faseProyecto'){

        //console.log(obj)

        obj.fase = value

        This.props.dispatch({
          type:'CHANGE_DATA_PROYECTOS',
          payload: {
            dataProyecto: obj
          }
        })

      }else if(type === 'divisaProyecto'){

        //console.log(obj)

        obj.divisa = value

        This.props.dispatch({
          type:'CHANGE_DATA_PROYECTOS',
          payload: {
            dataProyecto: obj
          }
        })

      }

    }

    return null

  })

}

export default handleInputChange;
