import React, { Component } from 'react';
import LogInComponent from '../Components/LogIn.jsx';
import { Redirect } from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import url from '../../../../apiService/ApiUrl.js';

import { connect } from 'react-redux';

import sessionService from '../../../../sessionService/sessionService';

class LogIn extends Component{

  constructor(props) {
    super(props);

    this.OnLogIn = this.OnLogIn.bind(this);

  }

  componentDidMount() {

      if(this.props.conexion === true){
  			   return <Redirect to='/dashboard' />
  		}else {
  			//console.log(this.usuario.conexion)
  		}

  }

  async OnLogIn(event){

  		event.preventDefault();

  		let pass = document.querySelector('#password').value,
          usuarioOEmail = document.querySelector('#usuarioOEmail').value

      //let url = 'http://localhost:8080/graphql';

      let usuarioData = {
        usuarioOEmail,
        pass
      }
      
    let sessionServiceCallback = () => { 
      if(this.props.conexion === true){
        return <Redirect to='/dashboard' />
      }else if((this.props.usuario === null) && (this.props.conexion === false)) {
        document.querySelector('#incorrect-data').style.display = 'block';
      }
    }

    sessionService.logIn(url, usuarioData, this, sessionServiceCallback)

      //GraphQL(usuarioOEmail, valpass, this);

      

  }

  render() {
    if (this.props.conexion === true) {
		  return <Redirect to='/dashboard' />
		}else{
      return (
        <LogInComponent>
          <div id="LogoContainer">
            <img src="img/svg/LogoBlanco.svg" alt="logoimg" />
          </div>
          <div id="LogInCard" className="d-flex align-items-center" style={{marginTop: '80px'}}>
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-7 col-lg-5 mx-auto">
                  <div className="card card-login mx-auto mt-5">
                    <div className="card-header text-center">
                      <h3 className="text-left">Log In</h3>
                    </div>
                    <div className="card-body">
                      <form onSubmit={this.OnLogIn} >
                        <div className="form-group">
                          <label htmlFor="usuarioOEmail"><FontAwesomeIcon icon="envelope" /> Usuario o Correo Electrónico</label>
                          <input className="form-control" id="usuarioOEmail" type="text" aria-describedby="emailHelp" placeholder="Escribe tu usuario o correo electrónico" />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password"><FontAwesomeIcon icon="lock" /> Contraseña</label>
                          <input className="form-control" id="password" type="password" placeholder="Escribe tu contraseña" />
                        </div>
                        <button className="btn btn-outline-light btn-block" type="submit">Ingresa</button>
                        <div id="incorrect-data" style={{'marginTop': '15px', 'display': 'none', 'transition': 'all 0.5s ease-in-out'}} className="alert alert-danger" role="alert">Ingresaste datos incorrectos, por favor prueba de Nuevo</div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LogInComponent>
      )
    }
  }

}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(LogIn)
