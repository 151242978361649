import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);

	This.setState({
		updateUserMensaje: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})

}

const updateUser = (This, usuarioJSON, existColaborador) => {
	//console.log(usuarioJSON)

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(usuarioJSON, existColaborador)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		
		//console.log(res)

		This.setState({
			updateUserMensaje: {
				response: 'success',
				mensaje: res.data.updateUser.mensaje
			}
		})

		if (res.data.updateUser.usuario !== null) { 

			This.props.dispatch({
				type: 'CHANGE_DATA_USER',
				payload: {
					dataUser: res.data.updateUser.usuario
				}
			})

			This.props.dispatch({
				type: 'ADD_COLABORADOR_TO_COLABORADORES',
				payload: {
					dataColaborador: res.data.updateUser.usuario.colaborador
				}
			})
		}

		if (usuarioJSON.tipo === "cliente") {
				
			This.props.dispatch({
				type: 'DELETE_COLABORADOR',
				payload: {
					dataColaborador: usuarioJSON.colaborador
				}
			})

		}

		return res
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default updateUser;
