import React from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let UserView = props => {

  let usuario = props.usuario

  let fecha_de_nacimiento_Convertido = new Date(parseInt(usuario.fecha_de_nacimiento))
  let mesFecha
  (fecha_de_nacimiento_Convertido.getMonth()+1) < 10 ? mesFecha = `0${fecha_de_nacimiento_Convertido.getMonth()+1}` : mesFecha = `${fecha_de_nacimiento_Convertido.getMonth()+1}`
  let diaFecha
  fecha_de_nacimiento_Convertido.getDate() < 10 ? diaFecha = `0${fecha_de_nacimiento_Convertido.getDate()}` : diaFecha = `${fecha_de_nacimiento_Convertido.getDate()}`
  let fechaFinal = `${diaFecha}/${mesFecha}/${fecha_de_nacimiento_Convertido.getFullYear()}`

  return(
      <ModalContainer>
        <ModalB4 visible={props.modalVisible} fade={true}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
              <img className="brand-modal" src="/img/svg/LogoBlanco.svg" alt="LogoPM502" />
              <button type="button" className="close cerrar-cruz" onClick={props.handleCloseModal} aria-label="Close">
                <div></div>
                <div></div>
              </button>
              </div>

              <div className="modal-body sin-margen-y-padding container-fluid">
                <div className="row text-center">
                  <div className="col-12">
                    <FontAwesomeIcon icon="user-circle" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                    <h1>{usuario.nombre} {usuario.apellidos}</h1>
                  </div>
                  <div className="col-12" style={{'marginTop': '25px'}}>

                      <div className="row">

                        <div className="col-md-4 row">
                          <p className="col" id="usuario" style={{'marginTop': '0px'}}><b>Usuario:</b> {usuario.usuario}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="nombre" style={{'marginTop': '0px'}}><b>Nombre:</b> {usuario.nombre}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="apellidos" style={{'marginTop': '0px'}}><b>Apellidos:</b> {usuario.apellidos}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="telefono" style={{'marginTop': '0px'}}><b>Teléfono:</b> {usuario.telefono}</p>
                        </div>
                        
                        <div className="col-md-4 row">
                          <p className="col" id="email" style={{'marginTop': '0px'}}><b>Correo:</b> {usuario.email}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="fecha-de-naciemiento" style={{'marginTop': '0px'}}><b>Fecha de Nacimiento:</b> {fechaFinal}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="direccion" style={{'marginTop': '0px'}}><b>Dirección:</b> {usuario.direccion}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="sexo" style={{'marginTop': '0px'}}><b>Sexo:</b> {usuario.sexo}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="tipo" style={{'marginTop': '0px'}}><b>Tipo:</b> {usuario.tipo}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="tipo" style={{'marginTop': '0px'}}><b>Permisos:</b>
                            {
                              usuario.permisos.map( (permiso, index) => {
                                return <i key={index}> {permiso}, </i>
                              })
                            }
                          </p>
                        </div>

                      </div>

                  </div>
                  
                  {
                    (usuario.colaborador !== null) ? (
                      (usuario.colaborador.id !== null) ? (
                        <div className="col-12">
                          <h1>Datos de Colaborador</h1>
                        </div>
                      ) : null
                    ) : null
                  }
                  {
                    (usuario.colaborador !== null) ? (
                      (usuario.colaborador.id !== null) ? (
                        <div className="col-12 row" style={{'marginTop': '25px'}}>
  
                          <div className="col-md-4 row">
                            <p className="col" id="puesto" style={{'marginTop': '0px'}}><b>Puesto de Colaborador:</b> {usuario.colaborador.puesto}</p>
                          </div>
                          
                          <div className="col-md-4 row">
                            <p className="col" id="NITColaborador" style={{'marginTop': '0px'}}><b>NIT de Colaborador:</b> {usuario.colaborador.NIT_colaborador}</p>
                          </div>
  
                          <div className="col-md-4 row">
                            <p className="col" id="contrato" style={{'marginTop': '0px'}}><b>Contrato de Colaborador:</b> {usuario.colaborador.contrato}</p>
                          </div>
                          
                          {/* 
                            (usuario.colaborador.ids_Empresas !== null) ? (
                              <div className="col-md-4 row">
                                <p className="col" id="idsEmpresas" style={{'marginTop': '0px'}}><b>Empresas de Proyect Manager:</b> {usuario.colaborador.ids_Empresas}</p>
                              </div>
                            ): null
                          */}
                        
                        </div>
                      ) : null
                    ) : null
                  }

                  {
                    (usuario.empresa_asociada !== null) ? (
                      (usuario.empresa_asociada.id !== null) ? (
                        <div className="col-12">
                          <FontAwesomeIcon icon="building" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                          <h1>Empresa Asociada: {usuario.empresa_asociada.nombre}</h1>
                        </div>
                      ) : null
                    ) : null
                  }
                  {
                    (usuario.empresa_asociada !== null) ? (
                      (usuario.empresa_asociada.id !== null) ? (
                        <div className="col-12 row" style={{'marginTop': '25px'}}>
  
                          <div className="col-md-4 row">
                            <p className="col" id="nombreEmpresa" style={{'marginTop': '0px'}}><b>Nombre de Empresa:</b> {usuario.empresa_asociada.nombre}</p>
                          </div>
  
                          <div className="col-md-4 row">
                            <p className="col" id="TelefonoEmpresa" style={{'marginTop': '0px'}}><b>Teléfono de Empresa:</b> {usuario.empresa_asociada.telefono}</p>
                          </div>
  
                          <div className="col-md-4 row">
                            <p className="col" id="CorreoEmpresa" style={{'marginTop': '0px'}}><b>Correo de Empresa:</b> {usuario.empresa_asociada.correo}</p>
                          </div>
  
                        </div>
                      ) : null
                    ) : null
                  }
                  
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default mx-auto" onClick={props.handleCloseModal}>Cerrar</button>
              </div>
            </div>
          </div>
      </ModalB4>
    </ModalContainer>
  )
}

export default UserView
