import React, { Component } from 'react';

import LineChartCard from '../../../../Dashboard/dashboard/components/Cards/LineChartCard.jsx';

class AvanceRealVrsProyectado extends Component {
    
    constructor(props){

        super(props)
    
        let dataProyecto = this.props.proyecto.dataProyecto,
            firstDataProyectoLabel = "",
            lastDataProyectoLabel = ""
    
        if(dataProyecto !== null){
    
          dataProyecto = this.props.proyecto.dataProyecto
    
          let dataProyectoLabels = dataProyecto.filter( dataProyecto => {
          
            return dataProyecto.Semana !== null
        
          })
    
          firstDataProyectoLabel = dataProyectoLabels[0]
          lastDataProyectoLabel = dataProyectoLabels.slice(-1)[0]
    
        }
    
        let aspectRatio
    
        if((window.innerWidth <= 768) && (window.innerWidth >= 567)) {
          aspectRatio = 1.5
        }else if((window.innerWidth < 567)){
          aspectRatio = 0.8
        }else{
          aspectRatio = 2
        }
    
        this.state = {
          dataProyectoDesde: firstDataProyectoLabel !== "" ? firstDataProyectoLabel.Semana : firstDataProyectoLabel,
          dataProyectoHasta: lastDataProyectoLabel !== "" ? lastDataProyectoLabel.Semana : lastDataProyectoLabel,
          config: {
            data: {
              labels: [],
              datasets: []
            },
            options: {
              aspectRatio: aspectRatio,
              responsive: true,
              scales: {
                  xAxes: [{
                    gridLines: {
                    },
                    ticks: {
                      autoSkipPadding: 15
                    },
                  }],
                  yAxes: [{
                    gridLines: {   
                    },
                    ticks: {
                      beginAtZero: true,
                      //max: 105
                    }
                  }]
              },
              legend: {
                  display: true,
                  position: 'bottom'
              }
            }
          }
        }
    
        // This binding is necessary to make `this` work in the callback
        this.onSelectChangeDesde = this.onSelectChangeDesde.bind(this);
        this.onSelectChangeHasta = this.onSelectChangeHasta.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
    }

    componentDidMount(){

        window.addEventListener('resize', this.onWindowResize);
    
        let dataProyecto = this.props.proyecto.dataProyecto,
            This = this
    
        if(dataProyecto !== null){
    
          let dataProyectoPorcentajeEstimado = dataProyecto.filter( dataProyecto => {
    
            let SemanaParseInt = parseInt(dataProyecto.Semana),
                SemanaDesdeParseInt = parseInt(this.state.dataProyectoDesde),
                SemanaHastaParseInt = parseInt(this.state.dataProyectoHasta)
    
            return (dataProyecto.PorcentajeEstimado !== null) && (dataProyecto.Semana !== null) && ((SemanaParseInt >= SemanaDesdeParseInt) && (SemanaParseInt <= SemanaHastaParseInt))
        
          })
        
          let porcentajeEstimado = dataProyectoPorcentajeEstimado.map( dataProyecto => {
            return dataProyecto.PorcentajeEstimado * 100
          })
        
          let dataProyectoPorcentajeReal = dataProyecto.filter( dataProyecto => {
    
            let SemanaParseInt = parseInt(dataProyecto.Semana),
            SemanaDesdeParseInt = parseInt(this.state.dataProyectoDesde),
            SemanaHastaParseInt = parseInt(this.state.dataProyectoHasta)
        
            return (dataProyecto.PorcentajeReal !== null) && (dataProyecto.Semana !== null) && ((SemanaParseInt >= SemanaDesdeParseInt) && (SemanaParseInt <= SemanaHastaParseInt))
        
          })
        
          let porcentajeReal = dataProyectoPorcentajeReal.map( dataProyecto => {
        
            return dataProyecto.PorcentajeReal * 100
        
          })
        
          let dataProyectoLabels = dataProyecto.filter( dataProyecto => {
    
            let SemanaParseInt = parseInt(dataProyecto.Semana),
            SemanaDesdeParseInt = parseInt(this.state.dataProyectoDesde),
            SemanaHastaParseInt = parseInt(this.state.dataProyectoHasta)
        
            return dataProyecto.Semana !== null && ((SemanaParseInt >= SemanaDesdeParseInt) && (SemanaParseInt <= SemanaHastaParseInt))
        
          })
        
          let labels = dataProyectoLabels.map( dataProyecto => {
            return `Semana ${dataProyecto.Semana}`
          })
    
          This.setState({
            config: {
              ...this.state.config,
              data: {
                labels: labels,
                datasets: [
                  {
                    label: 'Porcentaje Estimado',
                    fill: false,
                    data: porcentajeEstimado,
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1
                  },
                  {
                    label: 'Porcentaje Real',
                    fill: false,
                    data: porcentajeReal,
                    backgroundColor: 'rgba(75, 192, 0, 0.2)',
                    borderColor: 'rgba(75, 192, 0, 1)',
                    borderWidth: 1
                  }
                ]
              }
            }
          })      
    
        }
        
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onWindowResize);
    }

    onSelectChangeDesde(event){


    let dataProyecto = this.props.proyecto.dataProyecto,
        This = this

    if(dataProyecto !== null){

        let dataProyectoPorcentajeEstimado = dataProyecto.filter( dataProyecto => {

        let SemanaParseInt = parseInt(dataProyecto.Semana),
            SemanaDesdeParseInt = parseInt(event.target.value),
            SemanaHastaParseInt = parseInt(this.state.dataProyectoHasta)

        return (dataProyecto.PorcentajeEstimado !== null) && (dataProyecto.Semana !== null) && ((SemanaParseInt >= SemanaDesdeParseInt) && (SemanaParseInt <= SemanaHastaParseInt))
    
        })
    
        let porcentajeEstimado = dataProyectoPorcentajeEstimado.map( dataProyecto => {
        return dataProyecto.PorcentajeEstimado * 100
        })
    
        let dataProyectoPorcentajeReal = dataProyecto.filter( dataProyecto => {

        let SemanaParseInt = parseInt(dataProyecto.Semana),
        SemanaDesdeParseInt = parseInt(event.target.value),
        SemanaHastaParseInt = parseInt(this.state.dataProyectoHasta)
    
        return (dataProyecto.PorcentajeReal !== null) && (dataProyecto.Semana !== null) && ((SemanaParseInt >= SemanaDesdeParseInt) && (SemanaParseInt <= SemanaHastaParseInt))
    
        })
    
        let porcentajeReal = dataProyectoPorcentajeReal.map( dataProyecto => {
    
        return dataProyecto.PorcentajeReal * 100
    
        })
    
        let dataProyectoLabels = dataProyecto.filter( dataProyecto => {

        let SemanaParseInt = parseInt(dataProyecto.Semana),
        SemanaDesdeParseInt = parseInt(event.target.value),
        SemanaHastaParseInt = parseInt(this.state.dataProyectoHasta)
    
        return dataProyecto.Semana !== null && ((SemanaParseInt >= SemanaDesdeParseInt) && (SemanaParseInt <= SemanaHastaParseInt))
    
        })
    
        let labels = dataProyectoLabels.map( dataProyecto => {
        return `Semana ${dataProyecto.Semana}`
        })


        This.setState({
        config: {
            ...this.state.config,
            data: {
            labels: labels,
            datasets: [
                {
                label: 'Porcentaje Estimado',
                fill: false,
                data: porcentajeEstimado,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
                },
                {
                label: 'Porcentaje Real',
                fill: false,
                data: porcentajeReal,
                backgroundColor: 'rgba(75, 192, 0, 0.2)',
                borderColor: 'rgba(75, 192, 0, 1)',
                borderWidth: 1
                }
            ]
            }
        },
        dataProyectoDesde: event.target.value
        })

    }
    }
    
    onSelectChangeHasta(event){

    let dataProyecto = this.props.proyecto.dataProyecto,
        This = this

    if(dataProyecto !== null){

        let dataProyectoPorcentajeEstimado = dataProyecto.filter( dataProyecto => {

        let SemanaParseInt = parseInt(dataProyecto.Semana),
            SemanaDesdeParseInt = parseInt(this.state.dataProyectoDesde),
            SemanaHastaParseInt = parseInt(event.target.value)

        return (dataProyecto.PorcentajeEstimado !== null) && (dataProyecto.Semana !== null) && ((SemanaParseInt >= SemanaDesdeParseInt) && (SemanaParseInt <= SemanaHastaParseInt))
    
        })
    
        let porcentajeEstimado = dataProyectoPorcentajeEstimado.map( dataProyecto => {
        return dataProyecto.PorcentajeEstimado * 100
        })
    
        let dataProyectoPorcentajeReal = dataProyecto.filter( dataProyecto => {

        let SemanaParseInt = parseInt(dataProyecto.Semana),
        SemanaDesdeParseInt = parseInt(this.state.dataProyectoDesde),
        SemanaHastaParseInt = parseInt(event.target.value)
    
        return (dataProyecto.PorcentajeReal !== null) && (dataProyecto.Semana !== null) && ((SemanaParseInt >= SemanaDesdeParseInt) && (SemanaParseInt <= SemanaHastaParseInt))
    
        })
    
        let porcentajeReal = dataProyectoPorcentajeReal.map( dataProyecto => {
    
        return dataProyecto.PorcentajeReal * 100
    
        })
    
        let dataProyectoLabels = dataProyecto.filter( dataProyecto => {

        let SemanaParseInt = parseInt(dataProyecto.Semana),
        SemanaDesdeParseInt = parseInt(this.state.dataProyectoDesde),
        SemanaHastaParseInt = parseInt(event.target.value)
    
        return dataProyecto.Semana !== null && ((SemanaParseInt >= SemanaDesdeParseInt) && (SemanaParseInt <= SemanaHastaParseInt))
    
        })
    
        let labels = dataProyectoLabels.map( dataProyecto => {
        return `Semana ${dataProyecto.Semana}`
        })


        This.setState({
        config: {
            ...this.state.config,
            data: {
            labels: labels,
            datasets: [
                {
                label: 'Porcentaje Estimado',
                fill: false,
                data: porcentajeEstimado,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
                },
                {
                label: 'Porcentaje Real',
                fill: false,
                data: porcentajeReal,
                backgroundColor: 'rgba(75, 192, 0, 0.2)',
                borderColor: 'rgba(75, 192, 0, 1)',
                borderWidth: 1
                }
            ]
            }
        },
        dataProyectoHasta: event.target.value
        })

    }
    }

    onWindowResize(){
        if((window.innerWidth <= 768) && (window.innerWidth >= 567)) {
          this.setState({ 
            config: {
              ...this.state.config,
              options: {
                ...this.state.config.options,
                aspectRatio: 1.5
              }
            } 
          });
        }else if((window.innerWidth < 567)){
          this.setState({ 
            config: {
              ...this.state.config,
              options: {
                ...this.state.config.options,
                aspectRatio: 0.8
              }
            } 
          });
        }else{
          this.setState({ 
            config: {
              ...this.state.config,
              options: {
                ...this.state.config.options,
                aspectRatio: 2
              }
            } 
          });
        }
    }

    render(){

        let dataProyecto = this.props.proyecto.dataProyecto,
            dataProyectoLabels

        if(dataProyecto !== null){
          dataProyectoLabels = dataProyecto.filter( dataProyecto => {

              return dataProyecto.Semana !== null

          })
        }

        return(
           <div className="col-12">
                {
                this.props.proyecto.dataProyecto !== null ? (
                    <div className="col-12">

                      <div className="row">
                          <LineChartCard config={this.state.config} title="Gráfica de avance real acumulado vs avance programado acumulado" />
                      </div>

                      <div className="row" style={{marginTop: '25px'}}>
                          <div className="col-12 col-md-6">
                          <div className="form-group">
                              <label htmlFor="SelectDesde">Desde Semana:</label>
                              <select value={this.state.dataProyectoDesde} className="form-control" id="SelectDesde" onChange={this.onSelectChangeDesde}>
                              {
                                  dataProyectoLabels.map( dataProyecto => <option key={dataProyecto.Semana} value={dataProyecto.Semana}>{`Semana ${dataProyecto.Semana}`}</option> )
                              }
                              </select>
                          </div>
                          </div>

                          <div className="col-12 col-md-6">
                          <div className="form-group">
                              <label htmlFor="SelectHasta">Hasta Semana:</label>
                              <select value={this.state.dataProyectoHasta} onChange={this.onSelectChangeHasta} className="form-control" id="SelectHasta">
                              {
                                  dataProyectoLabels.map( dataProyecto => <option key={dataProyecto.Semana} value={dataProyecto.Semana}>{`Semana ${dataProyecto.Semana}`}</option> )
                              }
                              </select>
                          </div>
                          </div>
                      </div>

                    </div>
                ) : null
                }
           </div> 
        )
    } 

}

export default AvanceRealVrsProyectado