import React, { Component } from 'react';

// import { Plugins, Directory } from '@capacitor/core';

import { Device } from '@capacitor/device';

import { Filesystem, Directory } from '@capacitor/filesystem';

import url from '../../../../../apiService/ApiUrl.js';

import { connect } from 'react-redux';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../Components/Modal.jsx';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DataProyecto from './DataProyecto.jsx';
import TableInformesSEP from './TableInformesSEP.jsx';
import PDFInformeSEP from './PDF.jsx';
import Galeria from './Galeria.jsx';
import MinGaleria from './MinGaleria.jsx';
import ViewPlanosCarpetas from './ViewPlanosCarpetas.jsx';
import TablePlanos from './TablePlanos.jsx';

import Loader from '../../../../Components/Loader.jsx';

import DeleteReporteSEPFetch from '../DeleteReporteSEPFetch/FetchDeleteReporteSEPPromise.js';
import DeletePlanoFetch from '../DeletePlanoProyectoFetch/FetchDeletePlanoProyecto.js';

import FetchUsuarios from '../../../../../apiService/FetchGraphQLUsuarios.js';
import FetchColaboradores from '../../../../../apiService/FetchGraphQLColaboradores.js';

class ProyectoView extends Component {

  constructor(props){

    super(props)

    this.state = {
      loading: false,
      route: "DataProyecto",
      deleteReporteSEPMensaje: null,
      carpetaType:"",
      pdf: ""
    }

    // This binding is necessary to make `this` work in the callback
    this.handleDeleteInformeSEP = this.handleDeleteInformeSEP.bind(this);
    this.handleShowInformeSEP = this.handleShowInformeSEP.bind(this);
    this.handleChangeRoute = this.handleChangeRoute.bind(this);
    this.downloadInformeSEP = this.downloadInformeSEP.bind(this);
    this.handleChangeCarpetaRoute = this.handleChangeCarpetaRoute.bind(this);
    this.handleShowPlano = this.handleShowPlano.bind(this);
    this.downloadPlano = this.downloadPlano.bind(this);
    this.handleDeletePlano = this.handleDeletePlano.bind(this);
    this.resetPDFViewer = this.resetPDFViewer.bind(this);
  }

  componentDidMount(){
    FetchColaboradores(this)
    FetchUsuarios(this)
  }

  handleDeleteInformeSEP(informe){

    let arrProyecto = this.props.dataProyectos.proyectos.filter( proyecto =>{
      return proyecto.id === this.props.data.proyecto.id
    })

    let proyecto = arrProyecto[0]

    let newInformes = proyecto.informesSEP.filter( inf => inf.idInformeSEP !== informe.idInformeSEP)

    if(newInformes.length === 0){
      newInformes = null
    }

    //proyecto.informesSEP = newInformes

    let data = {
      idProyecto: proyecto.id,
      informesSEP: newInformes,
      informeId: informe.idInformeSEP
    }

    DeleteReporteSEPFetch(this, data)

    this.setState({
      loading: true
    })

  }

  handleShowInformeSEP(informe){

    if(this.state.route !== "PDF"){

      this.setState({
        loading: true
      })

      let informeId = informe.idInformeSEP,
          string = `{
            PDF {
              reporteSEP(idReporteSEP: "${informeId}")
            }
          }`,
          options = {
            method: 'post',
            headers: {
              "Content-type": "application/json"
            },
            body: JSON.stringify({"query": string}),
            credentials: 'include'
          }

      fetch(url, options)
      .then(res => res.json())
      .then( res => {

        
        
        let stringSEP = res.data.PDF.reporteSEP/*,
            fileName = `${informeId}.pdf`*/

        //let b64Str = `data:application/pdf;base64,${stringSEP}`
        //console.log(b64Str)

        /*var byteCharacters = atob(stringSEP);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);*/
        //var blob = new Blob([byteArray], {type: 'application/pdf'});

        this.setState({
          route: "PDF",
          loading: false,
          pdf: stringSEP
        })


      })

    }else{
      this.setState({
        route: "DataProyecto",
        loading: false,
        pdf: ""
      })
    }

  }

  handleShowPlano(plano){

    if(this.state.route !== "PDF"){

      this.setState({
        loading: true
      })

      let planoId = plano.id,
          string = `{
            PDF {
              plano(idPlano: "${planoId}")
            }
          }`,
          options = {
            method: 'post',
            headers: {
              "Content-type": "application/json"
            },
            body: JSON.stringify({"query": string}),
            credentials: 'include'
          }

      fetch(url, options)
      .then(res => res.json())
      .then( res => {

        
        
        let stringPlano = res.data.PDF.plano/*,
            fileName = `${informeId}.pdf`*/

        //let b64Str = `data:application/pdf;base64,${stringSEP}`
        //console.log(b64Str)

        /*var byteCharacters = atob(stringSEP);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);*/
        //var blob = new Blob([byteArray], {type: 'application/pdf'});

        this.setState({
          route: "PDF",
          loading: false,
          pdf: stringPlano
        })


      })

    }else{
      this.setState({
        route: "DataProyecto",
        loading: false,
        pdf: ""
      })
    }

  }

  handleChangeRoute(route){
    this.setState({
      route
    })
  }

  downloadInformeSEP(informe){

    this.setState({
      loading: true
    })

    let informeId = informe.idInformeSEP

    const string = `{
        PDF {
        reporteSEP(idReporteSEP: "${informeId}")
        }
    }`

    const options = {
        method: 'post',
        headers: {
        "Content-type": "application/json"
        },
        body: JSON.stringify({"query": string}),
        credentials: 'include'
    }

    fetch(url, options)
    .then(res => res.json())
    .then( res => {

        
        
        let stringSEP = res.data.PDF.reporteSEP,
            fileName = `${informeId}.pdf`

        let b64Str = `${stringSEP}`

        //console.log(b64Str)

        //const { Filesystem } = Plugins;

        async function fileWrite() {

        const info = await Device.getInfo();
        //console.log(info);

        if(info.platform === 'web'){

            const link = document.createElement('a');
            link.href = b64Str;
            link.download = `${informeId}.pdf`
            link.click();

            //console.log('Estoy en Web')
            //alert(`Se guardo el informe`)

        }else if((info.platform === 'android') || (info.platform === 'ios')){

            try {
                await Filesystem.writeFile({
                path: fileName,
                data: b64Str,
                directory: Directory.Documents
                }).then(writeFileResult => {

                //console.log(writeFileResult)

                Filesystem.getUri({
                    directory: Directory.Documents,
                    path: fileName
                }).then((getUriResult) => {

                    const path = getUriResult.uri;

                    alert(`Se guardo el informe en: ${path}`)

                }, (error) => {
                    console.log(error);
                })
                })

            } catch(e) {
                console.error('Unable to write file', e);
            }

        }

        }

        fileWrite()

        this.setState({
          loading: false
        })

    })
  }

  downloadPlano(plano){

    this.setState({
      loading: true
    })

    let planoId = plano.id

    const string = `{
        PDF {
          plano(idPlano: "${planoId}")
        }
    }`

    const options = {
        method: 'post',
        headers: {
        "Content-type": "application/json"
        },
        body: JSON.stringify({"query": string}),
        credentials: 'include'
    }

    fetch(url, options)
    .then(res => res.json())
    .then( res => {

        
        
        let stringPlano = res.data.PDF.plano,
            fileName = `${planoId}.pdf`

        let b64Str = `${stringPlano}`

        //console.log(b64Str)

        //const { Filesystem } = Plugins;

        async function fileWrite() {

        const info = await Device.getInfo();
        //console.log(info);

        if(info.platform === 'web'){

            const link = document.createElement('a');
            link.href = b64Str;
            link.download = `${planoId}.pdf`
            link.click();

            //console.log('Estoy en Web')
            //alert(`Se guardo el informe`)

        }else if((info.platform === 'android') || (info.platform === 'ios')){

            try {
                await Filesystem.writeFile({
                path: fileName,
                data: b64Str,
                directory: Directory.Documents
                }).then(writeFileResult => {

                //console.log(writeFileResult)

                Filesystem.getUri({
                    directory: Directory.Documents,
                    path: fileName
                }).then((getUriResult) => {

                    const path = getUriResult.uri;

                    alert(`Se guardo el informe en: ${path}`)

                }, (error) => {
                    console.log(error);
                })
                })

            } catch(e) {
                console.error('Unable to write file', e);
            }

        }

        }

        fileWrite()

        this.setState({
          loading: false
        })

    })
  }

  handleChangeCarpetaRoute(route, type){
    this.setState({
      route,
      carpetaType: type
    })
  }

  handleDeletePlano(idProyecto, idPlano){

    let data ={
      idProyecto,
      idPlano
    }

    DeletePlanoFetch(this, data)

  }

  resetPDFViewer(){
    this.setState({
      pdf: ""
    })
  }

  render(){

    let data = this.props.data,
      proyecto = data.proyecto,
      empresaProyecto = data.empresaProyecto,
      modalVisible = this.props.modalVisible,
      handleCloseModal = this.props.handleCloseModal

  return(
      <ModalContainer>
        <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
              <img className="brand-modal" src="/img/svg/LogoBlanco.svg" alt="LogoBiolabBlanco" />
              <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                <div></div>
                <div></div>
              </button>
              </div>

              <div className="modal-body container-fluid">
                <div className="row text-center">

                  <div className="col-12 mx-auto">
                    <div className="row">

                      {
                        empresaProyecto[0].logoEmpresa !== null ? (
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" style={{marginTop: "25px"}}>
                            <img className="img-fluid mx-auto" src={`data:image/png;base64, ${empresaProyecto[0].logoEmpresa}`} alt="logoEmpresa" style={{maxHeight: "150px"}}/>
                          </div>
                        ) : (
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" style={{marginTop: "25px"}}>
                            <img className="img-fluid mx-auto" src="/img/svg/LogoEmpresa.svg" alt="logoEmpresa" style={{maxHeight: "150px"}} />
                          </div>
                        )
                      }

                      {
                        proyecto.logoProyecto !== null ? (
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" style={{marginTop: "25px"}}>
                            <img className="img-fluid" src={`data:image/png;base64, ${proyecto.logoProyecto}`} alt="logoProyecto" style={{maxWidth: "150px"}}/>
                          </div>
                        ) : (
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" style={{marginTop: "25px"}}>
                            <img className="img-fluid" src="/img/svg/LogoProyecto.svg" alt="logoProyecto" style={{maxWidth: "150px"}}/>
                          </div>
                        )
                      } 

                    </div>
                    <div className="row">
                      <div className="col" style={{marginTop: "25px"}}>
                        {/*<FontAwesomeIcon icon="city" style={{'fontSize': '400%', 'marginTop': '25px'}} />*/}
                        <h1>PROYECTO: {proyecto.nombre}</h1>
                      </div>
                    </div>


                  </div>

                  {
                    this.state.route === "DataProyecto" ? <DataProyecto proyecto={proyecto} empresaProyecto={empresaProyecto[0]} handleChangeRoute={this.handleChangeRoute} handleShowInformeSEP={this.handleShowInformeSEP} /> : null
                  }
                  
                  {
                    this.state.route === "TableInformesSEP" ? (
                      <TableInformesSEP proyecto={proyecto} usuario={this.props.usuario} handleShowInformeSEP={this.handleShowInformeSEP} handleDeleteInformeSEP={this.handleDeleteInformeSEP} downloadInformeSEP={this.downloadInformeSEP} handleChangeRoute={this.handleChangeRoute} />
                    ) : null
                  }

                  {
                    this.state.route === "PDF" ? <PDFInformeSEP pdf={this.state.pdf} handleChangeRoute={this.handleChangeRoute} resetPDFViewer={this.resetPDFViewer} /> : null
                  }

                  {
                    this.state.route === "Galeria" ? <Galeria handleChangeRoute={this.handleChangeRoute} proyecto={proyecto} /> : null
                  }

                  {
                    this.state.route === "MinGaleria" ? <MinGaleria handleChangeRoute={this.handleChangeRoute} proyecto={proyecto} usuario={this.props.usuario} /> : null
                  }

                  {
                    this.state.route === "ViewPlanosCarpetas" ? <ViewPlanosCarpetas handleChangeRoute={this.handleChangeRoute} proyecto={proyecto} usuario={this.props.usuario} handleChangeCarpetaRoute={this.handleChangeCarpetaRoute}/> : null
                  }

                  {
                    this.state.route === "TablePlanos" ? (
                      <TablePlanos proyecto={proyecto} usuario={this.props.usuario} handleShowPlano={this.handleShowPlano} handleDeletePlano={this.handleDeletePlano} downloadPlano={this.downloadPlano} handleChangeRoute={this.handleChangeRoute} carpetaType={this.state.carpetaType} />
                    ) : null
                  }

                  {
                    this.state.loading === true ? (
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-5 col-lg-3 mx-auto">
                            <Loader />
                          </div>
                        </div>
                      </div>
                    ) : null
                  }

                </div>

                {
                    this.state.deleteReporteSEPMensaje !== null ? (
                      <div className="row" style={{marginTop: '25px'}}>
                        {
                          (this.state.deleteReporteSEPMensaje.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.deleteReporteSEPMensaje.mensaje}</div>
                          ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.deleteReporteSEPMensaje.mensaje}</div>
                          )
                        }
                      </div>
                    ) : null
                }

              </div>
              <div className="modal-footer">
              {/*<button type="button" className="btn btn-default mx-auto">Descargar Informe <FontAwesomeIcon icon="file-download" style={{marginLeft: '15px'}}/></button>*/}
                <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
              </div>
            </div>
          </div>
        </ModalB4>
      </ModalContainer>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
    usuario: state.logIn.usuario,
    dataProyectos: state.dataProyectos
  }
}

export default connect(mapStateToProps)(ProyectoView);