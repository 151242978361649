let consulta = (data) => {

  const string =
    `mutation {
      insertPlano(
        idProyecto: "${data.idProyecto}",
        nombreDePlano: "${data.nombreDePlano}",
        tipoPlano: "${data.tipoPlano}",
        plano: "${data.plano}"
      ){
        plano{
          id
          idProyecto
          nombreDePlano
          tipoPlano
          fecha
        }
        mensaje
      }
    }`

    return {
      "query": string
    }

}

export default consulta
