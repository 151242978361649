import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PDFViewer from 'pdf-viewer-reactjs';

import './css/PDF.css';

let NextButton = props => {

    if(props.page !== props.pages) {
      return(
        <button style={{width: '28px', height: '28px', backgroundColor: '#FF9E00', borderRadius: '50%', border: 'none', marginLeft: '15px'}} onClick={props.handleNextClick} className="mx-auto">  
            <FontAwesomeIcon icon='angle-right' style={{color: '#FFFFFF'}} />
        </button>
      )  
    }else{
        return null
    }

}

let PrevButton = props => {

    if(props.page !== 1) {
      return(
        <button style={{width: '28px', height: '28px', backgroundColor: '#FF9E00', borderRadius: '50%', border: 'none', marginRight: '15px'}} onClick={props.handlePrevClick} className="mx-auto">  
            <FontAwesomeIcon icon='angle-left' style={{color: '#FFFFFF'}} />
        </button>
      )  
    }else{
        return null
    }

}

let RotateRight = props => {
    return (
        <button style={{width: '28px', height: '28px', backgroundColor: '#FF9E00', borderRadius: '50%', border: 'none', marginRight: '15px', transform: 'scaleX(-1)'}} onClick={props.handleRotateRight}>  
            <FontAwesomeIcon icon='undo' style={{color: '#FFFFFF'}} />
        </button>
    )
}

let ResetRotation = props => {
    return (
        <button style={{width: '28px', height: '28px', backgroundColor: '#FF9E00', borderRadius: '50%', border: 'none', marginRight: '15px'}} onClick={props.handleResetRotation}>  
            <FontAwesomeIcon icon='arrows-alt-h' style={{color: '#FFFFFF'}} />
        </button>
    )
}

let RotateLeft = props => {
    return (
        <button style={{width: '28px', height: '28px', backgroundColor: '#FF9E00', borderRadius: '50%', border: 'none', marginRight: '15px'}} onClick={props.handleRotateLeft}>  
            <FontAwesomeIcon icon='undo' style={{color: '#FFFFFF'}} />
        </button>
    )
}

let CustomNavigation = props => {
    return(
        <div className="container">
            <div className="row" style={{marginTop: '25px'}}>
                <PrevButton page={props.page} handlePrevClick={props.handlePrevClick} />
                    <p className="mx-auto text-center">{props.page}/{props.pages}</p>
                <NextButton page={props.page} pages={props.pages} handleNextClick={props.handleNextClick}/>
            </div>
            <div>
                <RotateLeft handleRotateLeft={props.handleRotateLeft} />
                <ResetRotation handleResetRotation={props.handleResetRotation}/>
                <RotateRight handleRotateRight={props.handleRotateRight} />
            </div>
            <div className="row" style={{marginTop: '25px'}}>

                <div className="col-12">
                    <div className="btn-group" role="group">
                        <button type="button" className="btn" style={{backgroundColor: '#FF9E00', color: '#FFFFFF'}}
                            onClick={props.handleZoomOut}
                        >
                            <FontAwesomeIcon icon='minus' style={{color: '#FFFFFF'}} />
                        </button>
                        <button type="button" className="btn" style={{backgroundColor: '#FF9E00', color: '#FFFFFF'}}
                            onClick={props.handleZoomIn}
                        >
                            <FontAwesomeIcon icon='plus' style={{color: '#FFFFFF'}} />
                        </button>
                    </div>
                </div>

            </div>

        </div>
    )
}

class PDF extends Component {

    componentWillUnmount(){
        this.props.resetPDFViewer()
    }

    render(){
        
        const base64toBlob = (data) => {
            // Cut the prefix `data:application/pdf;base64` from the raw base 64
            const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
        
            const bytes = atob(base64WithoutPrefix);
            let length = bytes.length;
            let out = new Uint8Array(length);
        
            while (length--) {
                out[length] = bytes.charCodeAt(length);
            }
        
            return new Blob([out], { type: 'application/pdf' });
        };

        // `base64String` is the given base 64 data
        const blob = base64toBlob(this.props.pdf);
        const url = URL.createObjectURL(blob);

        return(
            <div className="col-11 col-md-10 mx-auto">
                <div className="float-left">
                    <button style={{width: '28px', height: '28px', backgroundColor: '#FF9E00', borderRadius: '50%', border: 'none'}} onClick={ () =>{
                        let route ="DataProyecto"
                        this.props.handleChangeRoute(route)
                    }}>  
                        <FontAwesomeIcon icon='angle-left' style={{color: '#FFFFFF'}} />
                    </button>
                </div>
                <div className="col-11 col-md-10 mx-auto">
                    <PDFViewer document={{
                            //base64: this.props.pdf,
                            url: url
                        }} 
                        scale={1}
                        scaleStep={0.1}
                        minScale={0.1}
                        navigation={CustomNavigation}
                        rotationAngle={0}
                        css='pdfWrapper'
                        canvasCss='canvasCss'
                        hideNavbar={false}
                    />
                </div>
            </div>
        )
    }
}

export default PDF