import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		loading: false,
		insertProyectoDataMensaje: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const updateDataProyecto = (This, data) => {
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		//console.log(res)

		This.props.dispatch({
			type: 'CHANGE_DATA_PROYECTOS_INSERT_EXCEL',
			payload: {
				dataProyecto: res.data.insertDataProyecto
			}
		})

		This.setState({
			loading: false,
			insertProyectoDataMensaje: {
				response: 'success',
				mensaje: res.data.insertDataProyecto.mensaje
			}
		})
		return res

	})
	.then(() => {
		setTimeout( () => {
			This.setState({
				insertProyectoDataMensaje: null
			}) 
		}, 3000);
	})
	.catch(err => handleError(err, This))
	.then(() => {
		setTimeout( () => {
			This.setState({
				insertProyectoDataMensaje: null
			}) 
		}, 3000);
	});

	//termina fetch
}

export default updateDataProyecto;
