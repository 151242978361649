import React, { Component } from 'react';
import Loader from '../../../../Components/Loader.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { connect } from 'react-redux';

import FetchNewPlano from '../NewPlanoFetch/FetchNewPlanoPromise';

class SubirPlano extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            insertPlanoMensaje: null,
            nombreDePlano: "",
            tipoPlano: "General",
            plano: ""
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputFileChangePlano = this.handleInputFileChangePlano.bind(this);
        this.handleSavePlano = this.handleSavePlano.bind(this);

    }

    handleInputChange(event, type){
        if(type === "nombreDePlano"){
            this.setState({
                nombreDePlano: event.target.value
            })
        }else if(type === "tipoPlano"){
            this.setState({
                tipoPlano: event.target.value
            })
        }
    }

    handleInputFileChangePlano(){
        let This = this
        function convertToBase64() {
            //Read File
            var selectedFile = document.getElementById("archivo").files;
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                var fileToLoad = selectedFile[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    //console.re.log(base64);
                    //console.log(base64);
                    This.setState({
                        plano: base64
                    })
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
            }
        }
        convertToBase64()
    }

    handleSavePlano(event){

        event.preventDefault()

        let dataPlano = {
            idProyecto: this.props.proyecto.id,
            nombreDePlano: this.state.nombreDePlano,
            tipoPlano: this.state.tipoPlano,
            plano: this.state.plano
        }

        this.setState({
            loading: true
        })

        FetchNewPlano(this, dataPlano)

    }

    render(){
        return(
            <div className="modal-body container-fluid">
                <div className="row text-center">
                    <div className="col-11 col-md-10 mx-auto">
                        <div className="float-left">
                            <button style={{width: '28px', height: '28px', backgroundColor: '#FF9E00', borderRadius: '50%', border: 'none'}} onClick={ () =>{
                                let route ="DataProyecto"
                                this.props.handleChangeRoute(route)
                            }}>  
                                <FontAwesomeIcon icon='angle-left' style={{color: '#FFFFFF'}} />
                            </button>
                        </div>
                        <div className="col-12" style={{marginTop: '50px'}}>

                            <form className="row text-center" style={{marginBottom: '40px'}} onSubmit={(event) => {
                                this.handleSavePlano(event)
                            }}>

                                <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                    <label htmlFor="nombreDePlano" className="col"><b>Nombre del Plano:</b></label>
                                    <input type="text"  className="form-control" id="nombreDePlano" value={this.state.nombreDePlano} onChange={ (event) => {
                                        let type="nombreDePlano"
                                        this.handleInputChange(event, type)
                                    }} />
                                    <small id="nombreDePlanoSmall" className="form-text text-muted">Nombre del Plano.</small>
                                </div>

                                <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                    <label htmlFor="tipoPlano" className="col"><b>Tipo de Plano:</b></label>
                                    <select id="tipoPlano" className="form-control" value={this.state.tipoPlano} onChange={ (event) => {
                                        let type="tipoPlano"
                                        this.handleInputChange(event, type)
                                    }}>
                                        <option value='General'>General</option>
                                        <option value='Materiales Peligrosos'>Materiales Peligrosos</option>
                                        <option value='Topografia'>Topografía</option>
                                        <option value='Geotecnico'>Geotécnico</option>
                                        <option value='Civil'>Civil</option>
                                        <option value='Paisaje'>Paisaje</option>
                                        <option value='Estructural'>Estructural</option>
                                        <option value='Arquitectonico'>Arquitectónico</option>
                                        <option value='Interiores'>Interiores</option>
                                        <option value='Equipo'>Equipo</option>
                                        <option value='Proteccion contra Fuego'>Protección contra Fuego</option>
                                        <option value='Plomeria'>Plomería</option>
                                        <option value='Agua Potable'>Agua Potable</option>
                                        <option value='Drenaje Pluvial'>Drenaje Pluvial</option>
                                        <option value='Drenaje Sanitario'>Drenaje Sanitario</option>
                                        <option value='Proceso'>Proceso</option>
                                        <option value='Mecanico'>Mecánico</option>
                                        <option value='Electrico'>Eléctrico</option>
                                        <option value='Energia Distribuida'>Energía Distribuida</option>
                                        <option value='Telecomunicaciones'>Telecomunicaciones</option>
                                        <option value='Recurso'>Recurso</option>
                                        <option value='Otras Disciplinas'>Otras Disciplinas</option>
                                        <option value='Dibujante'>Dibujante</option>
                                        <option value='Administracion'>Administración</option>
                                    </select>
                                    <small id="tipoPlanoSmall" className="form-text text-muted">Tipo de Plano.</small>
                                </div>

                                <div className="form-group col-12 custom-file mx-auto">
                                    <label htmlFor="archivo">Archivo: </label><br/>
                                    <input type="file" className="archivo" id="archivo" name="archivo" required style={{width: '1px', height: '1px', opacity: '0', overflow: 'hidden', position: 'absolute', zIndex: '-1'}} onChange={this.handleInputFileChangePlano}/>
                                    <label className="btn btn-outline-primary mx-auto" htmlFor="archivo" style={{cursor: 'pointer'}}><span>Seleccionar Plano</span></label>
                                </div>

                                <div className="form-group col-12 mx-auto" style={{marginTop: '50px'}}>
                                    <button type="submit" className="btn btn-outline-info"><FontAwesomeIcon icon="save" style={{'fontSize': '150%'}} /></button>
                                </div>


                            </form>

                            {
                                this.state.loading === true ? (
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-3 mx-auto">
                                        <Loader />
                                        </div>
                                    </div>
                                ) : null
                            }

                            {
                                this.state.insertPlanoMensaje !== null ? (
                                <div className="row" style={{marginTop: '25px'}}>
                                    {
                                    (this.state.insertPlanoMensaje.response === 'success') ? (
                                        <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.insertPlanoMensaje.mensaje}</div>
                                    ) : (
                                        <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.insertPlanoMensaje.mensaje}</div>
                                    )
                                    }
                                </div>
                                ) : null
                            }

                        </div>
                    </div>
                </div>
            </div>
        ) 
    }
}

export default connect()(SubirPlano);