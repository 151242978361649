import React, { Component } from 'react';

//Importando Jquery
import $ from 'jquery';

import NavBarComponent from '../Components/NavBar.jsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Importando Link de React Router
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

let toScroll = (el) => {
  //console.log(el.id);
    $('html, body').stop().animate({
      scrollTop: $(`#${el.id}`).offset().top
    }, 1500, 'easeInOutExpo');
}

class NavBar extends Component {

  componentDidMount(){
    // Closes the Responsive Menu on Menu Item Click
    document.querySelectorAll('.navbar-nav > li > a').forEach(function(This){
      This.addEventListener('click', function(){
        $('.navbar-collapse').collapse('hide');
      });
    });
  }

  render(){
    return(
    <NavBarComponent>
      <div className="container-fluid">
				<NavHashLink exact to="/#inicio" id="logoNavbar" activeClassName="active" className="nav-link active" scroll={this.toScroll} >
					<img src="img/svg/LogoBlanco.svg" style={{height: '2.8em', paddingLeft: '25px'}} alt="logoimg"/>
				</NavHashLink>

				  <button className="navbar-toggler x collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="sr-only">Toggle navigation</span>
				  </button>

				  <div className="collapse navbar-collapse justify-content-center justify-content-md-end" id="navbarSupportedContent">
						<ul className="navbar-nav">
							<li className="nav-item text-center text-md-right">
								<NavHashLink exact to="/#inicio" activeClassName="active" className="nav-link" scroll={toScroll} >Inicio</NavHashLink>
							</li>
							<li className="nav-item text-center text-md-right">
								<NavHashLink exact to="/#servicios" activeClassName="active" className="nav-link" scroll={toScroll} >Servicios</NavHashLink>
							</li>
							<li className="nav-item text-center text-md-right">
								<NavHashLink exact to="/#proyectos" activeClassName="active" className="nav-link" scroll={toScroll} >Proyectos</NavHashLink>
							</li>
							<li className="nav-item text-center text-md-right">
								<NavHashLink exact to="/#contacto" activeClassName="active" className="nav-link" scroll={toScroll} >Contacto</NavHashLink>
							</li>
							<li className="nav-item text-center text-md-right">
								<NavLink exact to="/login" activeClassName="active" className="nav-link" >Login</NavLink>
							</li>
							{/*<li className="nav-item dropdown d-none d-md-block">
								<span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{paddingLeft: '0px', cursor: 'pointer'}}>
								</span>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown" style={{left: '-100px'}}>
										<NavLink to="/desarrollo-organizacional" className="dropdown-item nav-link">Desarrollo Organizacional</NavLink>
									<div className="dropdown-divider"></div>
									<NavLink to="/formacion-capacitacion-y-adiestramiento" className="dropdown-item nav-link">Capacitación</NavLink>
									<div className="dropdown-divider"></div>
									<NavLink to="/proceso-de-seleccion" className="dropdown-item nav-link">Proceso de Selección</NavLink>
								</div>
							</li>*/}
						</ul>
					</div>

					<div id="contenedorIconsNavBar" className="row justify-content-center d-md-flex d-none">
						<a className="NavBarIcon" href="https://www.facebook.com/pm502/">
							<FontAwesomeIcon icon={['fab', 'facebook-f']} />
						</a>
						<a className="NavBarIcon" href="#instagram">
							<FontAwesomeIcon icon={['fab', 'instagram']} />
						</a>
						<a className="NavBarIcon" href="#linkedin">
							<FontAwesomeIcon icon={['fab', 'linkedin-in']} />
						</a>
					</div>
					
			</div>
    </NavBarComponent>
    )
  }

}

export default NavBar
