import React, { Component } from 'react';

import url from '../../../../../apiService/ApiUrl';

class Img extends Component {

    constructor(props){

        super(props)
    
        this.state = {
          img: ""
        }

    }

    componentDidMount(){

        let consulta = {
            "query": `{
                appInfo{
                  imagenProyecto(idImagen: "${this.props.idImagen}")
                }
            }`
        }

        const options = {
            method: 'post',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(consulta),
            credentials: 'include'
        }

        fetch(url, options)
        .then(res => res.json())
        .then( (res) => {
            this.setState({
                img: res.data.appInfo.imagenProyecto
            })
        })

    }

    render(){
        if(this.state.img === ""){
            return null
        }else if(this.props.type === 'maxHeight'){
            return(
                <img style={{maxHeight: '200px', cursor: 'pointer'}}  alt={`${this.props.idImagen}`} src={`${this.state.img}`} className="mx-auto img-fluid" crossOrigin="use-credentials" />
            )
        }else if(this.props.type === 'minGaleria'){

            return(
                <img style={{cursor: 'pointer'}} alt={`${this.props.idImagen}`} src={`${this.state.img}`} className="img-fluid" 
                onClick={ ()=> {
                    this.props.handleOpenImageClick(this.props.imgObj.img)
                } } crossOrigin="use-credentials" />
            )


        }else{
            return(
                <img alt={`${this.props.idImagen}`} src={`${this.state.img}`} className="mx-auto img-fluid" crossOrigin="use-credentials" />
            )
        }
    }
}

export default Img