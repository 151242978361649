let consulta = () => {
  let string = `{
    appInfo{
      colaboradores{
        id
        puesto
        NIT_colaborador
        contrato
        FK_usuario
        ids_proyectos
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
