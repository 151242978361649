let HandleInsertInputChangeUsuario = (event, This) => {
  let value = event.target.value,
      usuario = event.target.dataset.usuario,
      type = event.target.dataset.valueType

  if((This.props.dataUsers.nuevoUsuario.id === JSON.parse(usuario).id) === true){

    if(type === 'usuario'){

      This.props.dataUsers.nuevoUsuario.usuario = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })

    }else if(type === 'password'){
      This.props.dataUsers.nuevoUsuario.password = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'nombre'){
      This.props.dataUsers.nuevoUsuario.nombre = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'apellidos'){
      This.props.dataUsers.nuevoUsuario.apellidos = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'telefono'){
      This.props.dataUsers.nuevoUsuario.telefono = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'email'){
      This.props.dataUsers.nuevoUsuario.email = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'fecha_de_nacimiento'){

      //var string = "1993-04-01";
      var d = Date.parse(value);
      var d2 = d.toString();
      var d3 = new Date(parseInt(d2));
      d3.setDate(d3.getDate() + 1)

      var d4 = Date.parse(d3);

      This.props.dataUsers.nuevoUsuario.fecha_de_nacimiento = d4

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'direccion'){
      This.props.dataUsers.nuevoUsuario.direccion = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'sexo'){
      This.props.dataUsers.nuevoUsuario.sexo = value

      This.props.dispatch({
        type:'CHANGE_DATA_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'tipo'){
      This.props.dataUsers.nuevoUsuario.tipo = value

      This.props.dispatch({
        type:'CHANGE_DATA_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'permisos'){

      //console.log(event.target.options.length)

      if (value === "") {
        This.props.dataUsers.nuevoUsuario.permisos = []
      } else { 

        var opts = [], opt;

      // loop through options in select list
      for (var i=0, len=event.target.options.length; i<len; i++) {

          opt = event.target.options[i];

          // check if selected
          if ( opt.selected ) {
              // add to array of option elements to return from this function
              opts.push(opt.value);

              // invoke optional callback function if provided
              /*if (fn) {
                  fn(opt);
              }*/
          }
      }

      //console.log(opts)

      // return array containing references to selected option elements
      This.props.dataUsers.nuevoUsuario.permisos = opts
      //return opts;

      //console.log(This.props.dataUsers.nuevoUsuario)

      }

      This.props.dispatch({
        type:'CHANGE_DATA_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })

    }else if(type === 'EmpresaAsociada'){
      //obj.empresa_asociada.nombre = value

      //This.props.dataUsers.nuevoUsuario.tipo = value

      let empresa = This.props.dataEmpresas.empresas.filter( empresa => empresa.nombre_comercial === value )

      empresa[0] === undefined ? This.props.dataUsers.nuevoUsuario.empresa_asociada.id = null : This.props.dataUsers.nuevoUsuario.empresa_asociada.id = empresa[0].id

      empresa[0] === undefined ? This.props.dataUsers.nuevoUsuario.empresa_asociada.nombre = null : This.props.dataUsers.nuevoUsuario.empresa_asociada.nombre = empresa[0].nombre_comercial

      empresa[0] === undefined ? This.props.dataUsers.nuevoUsuario.empresa_asociada.telefono = null : This.props.dataUsers.nuevoUsuario.empresa_asociada.telefono = empresa[0].telefono

      empresa[0] === undefined ? This.props.dataUsers.nuevoUsuario.empresa_asociada.correo = null : This.props.dataUsers.nuevoUsuario.empresa_asociada.correo = empresa[0].correo

      //console.log(empresa[0].nombre_comercial)

      This.props.dispatch({
        type:'CHANGE_DATA_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if (type === 'puesto') {

      if (value === "") {
        value = null
      }

      //obj.colaborador.puesto = value

      This.props.dataUsers.nuevoUsuario.colaborador.puesto = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })

    } else if (type === 'NIT_colaborador') {
      
      if (value === "") {
        value = null
      }
      
      This.props.dataUsers.nuevoUsuario.colaborador.NIT_colaborador = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })

    } else if (type === 'contrato') {
      
      if (value === "") {
        value = null
      }
      
      This.props.dataUsers.nuevoUsuario.colaborador.contrato = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })

    }else if(type === 'proyectos'){

      //console.log(event.target.options.length)
      //console.log(event.target.options)
      //console.log(obj)

      var optsProyectos = [], optProyecto;

      // loop through options in select list
      for (var iproy=0, leng=event.target.options.length; iproy<leng; iproy++) {
        optProyecto = event.target.options[iproy];

          // check if selected
          if ( optProyecto.selected ) {
              // add to array of option elements to return from this function
              optsProyectos.push(optProyecto.value);

              // invoke optional callback function if provided
              /*if (fn) {
                  fn(opt);
              }*/
          }
      }

      // return array containing references to selected option elements
      This.props.dataUsers.nuevoUsuario.colaborador.ids_proyectos = optsProyectos
      //console.log(obj)
      //return opts;

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })

    }

  }
}

export default HandleInsertInputChangeUsuario
