import React, { Component } from 'react';

//import $ from 'jquery';

import '../Components/css/Proyectos.css';

import Slider from "react-slick";

let settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	autoplay: true,
	autoplaySpeed: 4000,
	initialSlide: 0,
	arrows: false
}

class NuestrosProyectos extends Component {

    componentDidMount() {

        //funcionamiento de las cardsProyectos
        /*let arrayCards = document.querySelectorAll("#CardsProyectos .slick-cloned");
        if (arrayCards.length < 3) {
            arrayCards.forEach(card => {
                card.style.display = "none";
            });
        }*/

    }

    render() {

        return(
            <section id="proyectos" data-jarallax-element="0 0">
                <div className="container">

                    <div className="row">
                        <div id="tiutloProyectos" className="col-12">
                            <h1 className="text-center text-md-left">Nuestros Proyectos</h1>
                        </div>
                    </div>
                    <div className="row">

                        <div id="galleryProyectos" className="col-12 carousel slide">
							<Slider {...settings} className="carousel-inner">

                                <div className="carousel-item galleryCard active">
                                    <div className="container">
                                        <div className="row align-items-center">
                                            <img src="/img/jpg/proyectos/vidali.jpg" alt="First" className="center-block img-fluid imageBack col-12 col-md-6 order-md-2" />
                                            <div className="card col-12 col-md-6 order-md-1">
                                                <div className="card-body">
                                                    <h5 className="card-title text-right">Vidali <div /></h5>
                                                    <p className="card-text text-justify">Ubicado al lado del Campo Marte, zona 5, ciudad de Guatemala. Proyecto a cargo de la planificación, comercialización, ejecución y cierre del proyecto.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item galleryCard active">
                                    <div className="container">
                                        <div className="row align-items-center">
                                            <img src="/img/jpg/proyectos/iglesiaSantaMaria.jpg" alt="First" className="center-block img-fluid imageBack col-12 col-md-6 order-md-2" />
                                            <div className="card col-12 col-md-6 order-md-1">
                                                <div className="card-body">
                                                    <h6 className="card-title text-right">Iglesia Santa María Reina de la familia <div /></h6>
                                                    <p className="card-text text-justify">Ubicado en Boulevard Rafael Landívar 10-05, Ciudad Cayalá, zona 16 en la ciudad de Guatemala. Proyecto a cargo de la Planificación, Ejecución y Cierre del proyecto.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item galleryCard active">
                                    <div className="container">
                                        <div className="row align-items-center">
                                            <img src="/img/jpg/Inicio.jpg" alt="First" className="center-block img-fluid imageBack col-12 col-md-6 order-md-2" />
                                            <div className="card col-12 col-md-6 order-md-1">
                                                <div className="card-body">
                                                    <h6 className="card-title text-right">Estrategia de negocio RE IDEAMSA <div /></h6>
                                                    <p className="card-text text-justify">Unidad de Negocio de Desarrollo Inmobiliario de Grupo EPM Guatemala</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

							</Slider>
						</div>
                    </div>

                    <div id="CardsProyectos" className="col-12" style={{ marginTop: '12%', display: 'block' }}>
                        <div className="row">

                            <a className="col-12 col-md-4 col-lg-3 mx-auto" href="#hola">
                                <div className="card">
                                    <img src="/img/jpg/proyectos/vidali.jpg" className="card-img-top" alt="First" />
                                    <div className="card-body">
                                        <h6 className="card-title text-center">Vidali</h6>
                                    </div>
                                </div>
                            </a>

                            <a className="col-12 col-md-4 col-lg-3 mx-auto" href="#hola">
                                <div className="card">
                                    <img src="/img/jpg/proyectos/iglesiaSantaMaria.jpg" className="card-img-top" alt="First" />
                                    <div className="card-body">
                                        <h6 className="card-title text-center">Iglesia Santa María Reina de la familia</h6>
                                    </div>
                                </div>
                            </a>

                            <a className="col-12 col-md-4 col-lg-3 mx-auto" href="#hola">
                                <div className="card">
                                    <img src="/img/jpg/Inicio.jpg" className="card-img-top" alt="First" />
                                    <div className="card-body">
                                        <h6 className="card-title text-center">Estrategia de negocio RE IDEAMSA</h6>
                                    </div>
                                </div>
                            </a>

                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default NuestrosProyectos