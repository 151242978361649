import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let EmpresaTable = props => { 
  let usuario = props.usuario

  let proyectosDeEmpresaUsuario = props.dataProyectos.proyectos.filter(proyecto => {
    return proyecto.idEmpresa === props.usuario.empresa_asociada.id
  })

  let proyectosDeProyectManager = ((props.usuario.colaborador !== null) && (props.usuario.colaborador.ids_proyectos !== null)) ? (props.dataProyectos.proyectos.filter(proyecto => {
    return props.usuario.colaborador.ids_proyectos.includes(proyecto.id)
  })) : null

  let numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="table-responsive">
      {
        ((usuario.tipo === 'colaborador') && (usuario.colaborador.puesto === 'Gerente')) ? (
          <div className="justify-content-end">
              <button onClick={() => {
                  let typeModal = 'InsertProyecto';
                  props.handleOpenModal(props.dataProyectos.nuevoProyecto, typeModal)
              }}
              type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'float': 'right', 'color': '#1A3B65'}}>
                <FontAwesomeIcon icon="plus-circle" /> Nuevo Proyecto <FontAwesomeIcon icon="city" />
              </button>
          </div>
        ) : null
      }
      <table className="table text-center table-bordered table-hover table-sm">
        <thead className="thead-light">
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col" className="d-none d-md-table-cell">Presupuesto</th>
            <th scope="col" className="d-none d-md-table-cell">Presupuesto Gastado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        {
          (usuario.tipo === 'cliente') ? (
            <tbody>
              {
                proyectosDeEmpresaUsuario.map(proyecto => {
                  let empresaProyecto = props.dataEmpresas.empresas.filter(empresa => {
                    return empresa.id === proyecto.idEmpresa
                  }),
                  proyectoData = {
                    proyecto,
                    empresaProyecto
                  }

                  return (
                    <tr key={proyecto.id}>
                      {/*<th scope="row">{usuario.nombre}</th>*/}
                      <td>{proyecto.nombre}</td>
                      <td className="d-none d-md-table-cell">{proyecto.divisa === "Quetzales" ? "Q." : null} {proyecto.divisa === "Dolares" ? "$." : null} {numberWithCommas(proyecto.presupuesto)}</td>
                      <td className="d-none d-md-table-cell">{proyecto.divisa === "Quetzales" ? "Q." : null} {proyecto.divisa === "Dolares" ? "$." : null} {numberWithCommas(proyecto.presupuestoGastado)}</td>
                      <td>
                        <button type="button" onClick={() => {
                            let typeModal = 'ProyectoModalView';
                            props.handleOpenModal(proyectoData, typeModal)
                        }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65'}}>
                          <FontAwesomeIcon icon="eye" />
                        </button> {/*ref={props.setButtonViewUser}*/}

                        {
                          ((usuario.tipo === 'cliente') || ((usuario.tipo === 'colaborador') && (usuario.colaborador.puesto === 'Secretaria'))) ? null : (
                            <button type="button" onClick={() => {
                                let typeModal = 'ProyectoModalEdit';
                                props.handleOpenModal(proyectoData, typeModal)
                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65'}}>
                              <FontAwesomeIcon icon="edit" />
                            </button>
                          )
                        }
      

                        {
                          ((usuario.tipo === 'colaborador') && (usuario.colaborador.puesto === 'Gerente')) ? (
                            <button onClick={ () => {
                                  props.handleDeleteProyecto(proyecto)
                              }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65'}}>
                              <FontAwesomeIcon icon="trash-alt" />
                            </button>
                          ) : null
                        }
      
      
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          ) : null
        }
        {
          ((usuario.tipo === 'colaborador') && ((usuario.colaborador.puesto === 'Gerente') || (usuario.colaborador.puesto === 'Secretaria'))) ? (
            <tbody>
              {
                props.dataProyectos.proyectos.map(proyecto => {
                  let empresaProyecto = props.dataEmpresas.empresas.filter(empresa => {
                    return empresa.id === proyecto.idEmpresa
                  }),
                    proyectoData = {
                      proyecto,
                      empresaProyecto
                    }
                  return (
                    <tr key={proyecto.id}>
                      {/*<th scope="row">{usuario.nombre}</th>*/}
                      <td>{proyecto.nombre}</td>
                      <td className="d-none d-md-table-cell">{proyecto.divisa === "Quetzales" ? "Q." : null} {proyecto.divisa === "Dolares" ? "$." : null} {numberWithCommas(proyecto.presupuesto)}</td>
                      <td className="d-none d-md-table-cell">{proyecto.divisa === "Quetzales" ? "Q." : null} {proyecto.divisa === "Dolares" ? "$." : null} {numberWithCommas(proyecto.presupuestoGastado)}</td>
                      <td>
                        <button type="button" onClick={() => {
                            let typeModal = 'ProyectoModalView';
                            props.handleOpenModal(proyectoData, typeModal)
                        }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65'}}>
                          <FontAwesomeIcon icon="eye" />
                        </button> {/*ref={props.setButtonViewUser}*/}

                        {
                          ((usuario.tipo === 'cliente') || ((usuario.tipo === 'colaborador') && (usuario.colaborador.puesto === 'Secretaria'))) ? null : (
                            <button type="button" onClick={() => {
                                let typeModal = 'ProyectoModalEdit';
                                props.handleOpenModal(proyectoData, typeModal)
                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65'}}>
                              <FontAwesomeIcon icon="edit" />
                            </button>
                          )
                        }
      

                        {
                          ((usuario.tipo === 'colaborador') && (usuario.colaborador.puesto === 'Gerente')) ? (
                            <button onClick={ () => {
                                  props.handleDeleteProyecto(proyecto)
                              }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65'}}>
                              <FontAwesomeIcon icon="trash-alt" />
                            </button>
                          ) : null
                        }
      
      
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          ) : null
        }
        {
          ((usuario.tipo === 'colaborador') && (usuario.colaborador.puesto === 'Proyect Manager')) ? (
            <tbody>
              {
                proyectosDeProyectManager.map(proyecto => {
                  let empresaProyecto = props.dataEmpresas.empresas.filter(empresa => {
                    return empresa.id === proyecto.idEmpresa
                  }),
                    proyectoData = {
                      proyecto,
                      empresaProyecto
                    }
                  return (
                    <tr key={proyecto.id}>
                      {/*<th scope="row">{usuario.nombre}</th>*/}
                      <td>{proyecto.nombre}</td>
                      <td className="d-none d-md-table-cell">{proyecto.divisa === "Quetzales" ? "Q." : null} {proyecto.divisa === "Dolares" ? "$." : null} {numberWithCommas(proyecto.presupuesto)}</td>
                      <td className="d-none d-md-table-cell">{proyecto.divisa === "Quetzales" ? "Q." : null} {proyecto.divisa === "Dolares" ? "$." : null} {numberWithCommas(proyecto.presupuestoGastado)}</td>
                      <td>
                        <button type="button" onClick={() => {
                            let typeModal = 'ProyectoModalView';
                            props.handleOpenModal(proyectoData, typeModal)
                        }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65'}}>
                          <FontAwesomeIcon icon="eye" />
                        </button> {/*ref={props.setButtonViewUser}*/}

                        {
                          ((usuario.tipo === 'cliente') || ((usuario.tipo === 'colaborador') && (usuario.colaborador.puesto === 'Secretaria'))) ? null : (
                            <button type="button" onClick={() => {
                                let typeModal = 'ProyectoModalEdit';
                                props.handleOpenModal(proyectoData, typeModal)
                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65'}}>
                              <FontAwesomeIcon icon="edit" />
                            </button>
                          )
                        }
      

                        {
                          ((usuario.tipo === 'colaborador') && (usuario.colaborador.puesto === 'Gerente')) ? (
                            <button onClick={ () => {
                                  props.handleDeleteProyecto(proyecto)
                              }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#1A3B65'}}>
                              <FontAwesomeIcon icon="trash-alt" />
                            </button>
                          ) : null
                        }
      
      
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          ) : null
        }
      </table>
    </div>
  )

}

export default EmpresaTable
