import React from 'react';

import { Route, Switch } from 'react-router-dom'; //Agregar Redirect despues

//Components
import AppLayout from './AppLayout.jsx';

/*Error404*/

import Error404 from './Error404.jsx';

/*Inicio*/
import WebPage from '../WebPage/Components/WebPage.jsx';

/*Import Log In*/
import LogIn from '../Dashboard/LogIn/Containers/LogIn.jsx';

/*Import Dashboard*/
import Dashboard from '../Dashboard/dashboard/containers/Dashboard.jsx';

/*Import QR Scanner*/
//import DashboardMensajeriaQR from '../Dashboard/Mensajeria/containers/QRScanner/DashboardQR.jsx';
/*Import RealizarOrdenMensajeria*/
//import RealizarOrdenMensajeria from '../Dashboard/Mensajeria/containers/RealizarOrden/DashboardRealizarOrden.jsx';

/*Import AdminUserDashboard*/
import AdminUserDashboard from '../Dashboard/AdminUser/containers/AdminUserDashboard.jsx';
/*Import AdminEmpresaDashboard*/
import AdminEmpresaDashboard from '../Dashboard/AdminEmpresa/containers/AdminEmpresaDashboard.jsx';
/*Import MiCuenta*/
/*Import AdminEmpresaDashboard*/
import AdminProyectosDashboard from '../Dashboard/AdminProyectos/containers/AdminProyectosDashboard.jsx';
/*Import MiCuenta*/
//import MiCuentaDashboard from '../Dashboard/MiCuenta/containers/MiCuentaDashboard.jsx';


const PrincipalRoutes = props => (
	<AppLayout>
		<Switch>

			{/*Inicio*/}
			{/*Route Inicio*/}
			<Route path="/" exact render={ ({ location }) => <WebPage path={location.pathname} native={props.native}/> }/>

			{/*Route LogIn*/}
			<Route path="/login" exact render={() => <LogIn />} />

			{/*Route Dashboard*/}
			<Route path="/dashboard" exact render={() => <Dashboard />} />
			
			{/*Route Dashboard*/}
			<Route path="/dashboard/admin-user" exact render={() => <AdminUserDashboard />} />
			{/*Route Dashboard*/}
			<Route path="/dashboard/admin-company" exact render={() => <AdminEmpresaDashboard />} />
			{/*Route Dashboard*/}
			<Route path="/dashboard/admin-proyectos" exact render={ () => <AdminProyectosDashboard /> }/>

			{/*Route Error404*/}
			<Route component={Error404} />

		</Switch>

	</AppLayout>
)

export default PrincipalRoutes;
