import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		loading: false,
		insertReporteSEPMensaje: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const updateProyecto = (This, data) => {
	const options = {
		method: 'POST',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		//console.log(res)

		var dataInformesSEP = res.data.insertInformeSEP.informesSEP.map(data => {

			let dataArray = {
				idInformeSEP: data.idInformeSEP,
				fecha: new Date(parseInt(data.fecha))
			}
			return dataArray
  
		})

		This.props.dispatch({
			type: 'CHANGE_STATE_REPORTE_SEP',
			payload: {
				nuevosReportes: dataInformesSEP,
				proyectoId: res.data.insertInformeSEP.id
			}
		})

		This.setState({
			loading: false,
			insertReporteSEPMensaje: {
				response: 'success',
				mensaje: res.data.insertInformeSEP.mensaje
			}
		})
		return res

	})
	.then(() => {
		setTimeout( () => {
			This.setState({
				insertReporteSEPMensaje: null
			}) 
		}, 3000);
	})
	.catch(err => handleError(err, This))
	.then(() => {
		setTimeout( () => {
			This.setState({
				insertReporteSEPMensaje: null
			}) 
		}, 3000);
	});

	//termina fetch
}

export default updateProyecto;
