import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		loading: false,
		insertImagenProyectoMensaje: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

let insertImagenProyecto = (This, data) => {



	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}



	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		let imageUpNewVal = This.state.imageUp + 1

		//console.log(res)

		if(This.state.imageTotal === imageUpNewVal){
			This.setState({
				loading: false,
				imageUp: imageUpNewVal,
				insertImagenProyectoMensaje: {
					response: 'success',
					mensaje: res.data.insertImagenProyecto.mensaje
				}
			})
	
			This.props.dispatch({
				type: 'ADD_IMAGEN_TO_GALERIA',
				payload: {
					idProyecto: res.data.insertImagenProyecto.idProyecto,
					imagenProyecto: {
						idImagen: res.data.insertImagenProyecto.imagenProyecto,
						descripcion: res.data.insertImagenProyecto.descripcion,
						fecha: res.data.insertImagenProyecto.fecha
					}
				}
			})
		}else{
			This.setState({
				imageUp: imageUpNewVal,
				insertImagenProyectoMensaje: {
					response: 'success',
					mensaje: res.data.insertImagenProyecto.mensaje
				}
			})
	
			This.props.dispatch({
				type: 'ADD_IMAGEN_TO_GALERIA',
				payload: {
					idProyecto: res.data.insertImagenProyecto.idProyecto,
					imagenProyecto: {
						idImagen: res.data.insertImagenProyecto.imagenProyecto,
						descripcion: res.data.insertImagenProyecto.descripcion,
						fecha: res.data.insertImagenProyecto.fecha
					}
				}
			})
		}


		return res

	})
	.then(() => {
		setTimeout( () => {
			if(This.state.imageTotal === This.state.imageUp){
				This.setState({
					insertImagenProyectoMensaje: null,
					imageTotal: 0,
					imageUp: 0,
					imgsArr: [],
					loading: false
				})
			}else{
				This.setState({
					insertImagenProyectoMensaje: null
				}) 
			}
		}, 3000);
	})
	.catch(err => handleError(err, This))
	.then(() => {
		setTimeout( () => {
			This.setState({
				insertImagenProyectoMensaje: null
			}) 
		}, 3000);
	});

	//termina fetch
}

export default insertImagenProyecto;
