let consulta = (empresaJSON) => {

  //console.log(empresaJSON)

  const string =
    `mutation insertEmpresa {
      insertEmpresa(
        insertEmpresa: {
          nombre_comercial: "${empresaJSON.nombre_comercial}"
          nombre_fiscal: "${empresaJSON.nombre_fiscal}"
          telefono: ${empresaJSON.telefono}
          correo: "${empresaJSON.correo}"
          NIT: "${empresaJSON.NIT}"
          direccion: "${empresaJSON.direccion}"
          tipo_empresa: "${empresaJSON.tipo_empresa}"
          regimen_fiscal: "${empresaJSON.regimen_fiscal}"
          cuenta_banco: "${empresaJSON.cuenta_banco}"
        }
      ) {
        mensaje
        empresa{
          id
          nombre_comercial
          nombre_fiscal
          telefono
          correo
          NIT
          direccion
          tipo_empresa
          regimen_fiscal
          cuenta_banco
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
