let consulta = (usuarioJSON) => {

  //console.log(usuarioJSON.empresa_asociada)

  let empresa_asociada,
      colaborador

  if (usuarioJSON.empresa_asociada.id !== null) {
    empresa_asociada = `empresa_asociada: {
      id: "${usuarioJSON.empresa_asociada.id}"
      nombre: "${usuarioJSON.empresa_asociada.nombre}"
      telefono: ${usuarioJSON.empresa_asociada.telefono}
      correo: "${usuarioJSON.empresa_asociada.correo}"
    }`
  } else { 
    empresa_asociada = "empresa_asociada: null"
  }

  if ((usuarioJSON.colaborador.puesto !== null)) {

    /*let ids_empresas

    if ((usuarioJSON.colaborador.ids_empresas === null) || (usuarioJSON.colaborador.ids_empresas[0] === "")) { 
      ids_empresas = null
    } else {
      ids_empresas = usuarioJSON.colaborador.ids_empresas
      console.log(ids_empresas)
    }*/
    
    colaborador = `colaborador: {
      puesto: "${usuarioJSON.colaborador.puesto}"
      NIT_colaborador: "${usuarioJSON.colaborador.NIT_colaborador}"
      contrato: "${usuarioJSON.colaborador.contrato}"
      ids_proyectos: "${usuarioJSON.colaborador.ids_proyectos}"
    }`
  } else { 
    colaborador = "colaborador: null"
  }

  const string =
    `mutation insertUser {
      insertUser(
        insertUser: {
          usuario: "${usuarioJSON.usuario}"
          password: "${usuarioJSON.password}"
          email: "${usuarioJSON.email}"
          nombre: "${usuarioJSON.nombre}"
          apellidos: "${usuarioJSON.apellidos}"
          telefono: ${usuarioJSON.telefono}
          fecha_de_nacimiento: "${usuarioJSON.fecha_de_nacimiento}"
          direccion:"${usuarioJSON.direccion}"
          sexo: "${usuarioJSON.sexo}"
          tipo: "${usuarioJSON.tipo}"
          permisos: "${usuarioJSON.permisos}"
          ${empresa_asociada}
          ${colaborador}
        }
      ) {
      	mensaje
        usuario{
          id
          usuario
          nombre
          apellidos
          email
          telefono
          fecha_de_nacimiento
          direccion
          sexo
          tipo
          permisos
          empresa_asociada{
            id
            nombre
            telefono
            correo
          }
          colaborador{
            puesto
            NIT_colaborador
            contrato
            FK_usuario
            ids_proyectos
          }
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
