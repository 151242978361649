import HandleInputChangeUsuario from './HandleInputChangeUsuario.js';
import HandleInputChangeEmpresa from './HandleInputChangeEmpresa.js';
import HandleInputChangeProyecto from './HandleInputChangeProyecto.js';

let handleInputChange = (event, This) => {

  let usuario = event.target.dataset.usuario,
      empresa = event.target.dataset.empresa,
      proyecto = event.target.dataset.proyecto

  /*if(This.props.dataUsers.length === 0){


    if(JSON.parse(usuario).id === This.props.usuario.id){

      if(type === 'usuario'){

        let obj = JSON.parse(usuario)

        obj.usuario = value

        console.log(obj)

        This.props.dispatch({
          type:'LOGIN',
          payload: {
            logIn: {
              conexion: true,
              usuario: obj
            }
          }
        })

      }

    }
  }*/

  if(usuario !== undefined){
    HandleInputChangeUsuario(event, This)
  }

  if(empresa !== undefined){
    HandleInputChangeEmpresa(event, This)
  }

  if(proyecto !== undefined){
    HandleInputChangeProyecto(event, This)
  }


}

export default handleInputChange;
