let handleInputChange = (event, This) => {

  let value = event.target.value,
      proyecto = event.target.dataset.proyecto,
      type = event.target.dataset.valueType

  if((This.props.dataProyectos.nuevoProyecto.id === JSON.parse(proyecto).id) === true){

      //console.log(empresa)

      if(type === 'nombreProyecto'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataProyectos.nuevoProyecto.nombre = value

        This.props.dispatch({
          type:'NEW_PROYECTO',
          payload: {
            dataProyecto: This.props.dataProyectos.nuevoProyecto
          }
        })

      }else if(type === 'empresaId'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataProyectos.nuevoProyecto.idEmpresa = value

        This.props.dispatch({
          type:'NEW_PROYECTO',
          payload: {
            dataProyecto: This.props.dataProyectos.nuevoProyecto
          }
        })

      }else if(type === 'presupuesto'){

        //console.log(obj)

        This.props.dataProyectos.nuevoProyecto.presupuesto = parseInt(value)

        This.props.dispatch({
          type:'NEW_PROYECTO',
          payload: {
            dataProyecto: This.props.dataProyectos.nuevoProyecto
          }
        })

      }else if(type === 'presupuestoGastado'){

        //console.log(obj)

        This.props.dataProyectos.nuevoProyecto.presupuestoGastado = parseInt(value)

        This.props.dispatch({
          type:'NEW_PROYECTO',
          payload: {
            dataProyecto: This.props.dataProyectos.nuevoProyecto
          }
        })

      }else if(type === 'paisProyecto'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataProyectos.nuevoProyecto.pais = value

        This.props.dispatch({
          type:'NEW_PROYECTO',
          payload: {
            dataProyecto: This.props.dataProyectos.nuevoProyecto
          }
        })

      }else if(type === 'direccionProyecto'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataProyectos.nuevoProyecto.direccion = value

        This.props.dispatch({
          type:'NEW_PROYECTO',
          payload: {
            dataProyecto: This.props.dataProyectos.nuevoProyecto
          }
        })

      }else if(type === 'telefonoProyecto'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataProyectos.nuevoProyecto.telefono = value

        This.props.dispatch({
          type:'NEW_PROYECTO',
          payload: {
            dataProyecto: This.props.dataProyectos.nuevoProyecto
          }
        })

      }else if(type === 'correoProyecto'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataProyectos.nuevoProyecto.correo = value

        This.props.dispatch({
          type:'NEW_PROYECTO',
          payload: {
            dataProyecto: This.props.dataProyectos.nuevoProyecto
          }
        })

      }else if(type === 'estadoProyecto'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataProyectos.nuevoProyecto.estado = value

        This.props.dispatch({
          type:'NEW_PROYECTO',
          payload: {
            dataProyecto: This.props.dataProyectos.nuevoProyecto
          }
        })

      }else if(type === 'faseProyecto'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataProyectos.nuevoProyecto.fase = value

        This.props.dispatch({
          type:'NEW_PROYECTO',
          payload: {
            dataProyecto: This.props.dataProyectos.nuevoProyecto
          }
        })

      }else if(type === 'divisaProyecto'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataProyectos.nuevoProyecto.divisa = value

        This.props.dispatch({
          type:'NEW_PROYECTO',
          payload: {
            dataProyecto: This.props.dataProyectos.nuevoProyecto
          }
        })

      }

    }

}

export default handleInputChange;
