import React, { Component } from 'react';

import { connect } from 'react-redux';

import UserView from './UserView.jsx';
import UserEdit from './UserEdit.jsx';
import InsertUser from './InsertUser.jsx';
import PassWordEdit from './PassWordEdit.jsx';

import EmpresaView from './EmpresaView.jsx';
import EmpresaEdit from './EmpresaEdit.jsx';
import InsertEmpresa from './InsertEmpresa.jsx';

import ProyectoView from './ProyectoView';
import ProyectoEdit from './ProyectoEdit';
import InsertProyecto from './InsertProyecto.jsx';

import HandleUserYEmpresaInputChange from '../../../../Service/HandleInputChange';

import FetchUpdateUserPromise from './UserEditFetch/FetchUpdateUserPromise.js';
import FetchNewUserPromise from './NewUserFetch/FetchNewUserPromise.js';

import FetchUpdateEmpresaPromise from './EmpresaEditFetch/FetchUpdateEmpresaPromise.js';
import FetchNewEmpresaPromise from './NewEmpresaFetch/FetchNewEmpresaPromise.js';

import FetchUpdateProyectoPromise from './ProyectoEditFetch/FetchUpdateProyectoPromise.js';
import FetchNewProyectoPromise from './NewProyectoFetch/FetchNewProyectoPromise.js';

import FetchUpdatePass from './ChangePassFetch/FetchUpdatePass.js';

import GraphQLDataInfo from '../../../../apiService/FetchGraphQLDataInfo.js';

import HandleInsertInputChange from '../../../../Service/HandleInsertInputChange';

class ModalUser extends Component {

  constructor(props){
    super(props)
    
    this.state = {
      updateUserMensaje: null,
      insertUserMensaje: null,
      updateUserContrasenaMensaje: null,
      updateEmpresaMensaje: null,
      insertEmpresaMensaje: null,
      updateProyectoMensaje: null,
      insertProyectoMensaje: null
    }

    // This binding is necessary to make `this` work in the callback
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSaveUser = this.handleSaveUser.bind(this);
    this.handleChangePassWord = this.handleChangePassWord.bind(this);
    this.handleInputChangeInsert = this.handleInputChangeInsert.bind(this);
    this.handleSaveNewUser = this.handleSaveNewUser.bind(this);
    this.handleSaveEmpresa = this.handleSaveEmpresa.bind(this);
    this.handleSaveNewEmpresa = this.handleSaveNewEmpresa.bind(this);
    this.handleSaveProyecto = this.handleSaveProyecto.bind(this);
    this.handleSaveNewProyecto = this.handleSaveNewProyecto.bind(this);
    //this.setInputRef = this.setInputRef.bind(this);
	}

  componentDidMount() {
    GraphQLDataInfo(this)
  }

  handleSaveUser(){

    let usuarioJSON = this.props.Modal.data;

    //console.log(usuarioJSON)

    let existColaborador = this.props.dataUsers.colaboradores.filter(colaborador => {
      return colaborador.FK_usuario === parseInt(usuarioJSON.id)
    })

    if ((existColaborador.length === 1) === true) {
      existColaborador = true
    } else { 
      existColaborador = false
    }
    
    //console.log(newColaborador)

    FetchUpdateUserPromise(this, usuarioJSON, existColaborador)

  }

  handleSaveNewUser(){

    let usuarioJSON = this.props.dataUsers.nuevoUsuario;

    //console.log(usuarioJSON)

    FetchNewUserPromise(this, usuarioJSON)

    this.props.dispatch({
			type: 'CLEAN_NEW_USER'
		})

  }

  handleSaveEmpresa(){

    let empresaJSON = this.props.Modal.data;

    //console.log(empresaJSON)

    FetchUpdateEmpresaPromise(this, empresaJSON)

    //FetchUpdateUserPromise(this, usuarioJSON)

  }

  handleSaveProyecto(){

    let proyectoJSON = this.props.Modal.data.proyecto;

    //console.log(empresaJSON)

    FetchUpdateProyectoPromise(this, proyectoJSON)

    //FetchUpdateUserPromise(this, usuarioJSON)

  }

  handleSaveNewEmpresa(){

    let empresaJSON = this.props.dataEmpresas.nuevaEmpresa;

    //console.log(empresaJSON)

    FetchNewEmpresaPromise(this, empresaJSON)

    this.props.dispatch({
			type: 'CLEAN_NEW_EMPRESA'
		})

  }

  handleSaveNewProyecto(){

    let proyectoJSON = this.props.dataProyectos.nuevoProyecto;

    //console.log(empresaJSON)

    FetchNewProyectoPromise(this, proyectoJSON)

    this.props.dispatch({
			type: 'CLEAN_NEW_PROYECTO'
		})

  }

  handleChangePassWord(){
    let newPass = document.querySelector('#NuevaContraseña').value,
        idUser = this.props.Modal.data.id

    FetchUpdatePass(this, newPass, idUser)

    //console.log(newPass)
  }

  handleInputChange(event){

    HandleUserYEmpresaInputChange(event, this)

  }

  handleInputChangeInsert(event){

    HandleInsertInputChange(event, this)
  }

  /*setInputRef = (element) => {

    //let type = element.dataset.valueType;

    this.input = element

    /*if(type === 'usuario'){
      this.input = element
    }else if(type === 'nombre'){
      this.input = element
    }*/

    //console.log(this.input.dataset.valueType)

  //}*/

  handleCloseModal() {
    
    this.setState({
      updateUserMensaje: null,
      insertUserMensaje: null,
      updateUserContrasenaMensaje: null,
      updateEmpresaMensaje: null,
      insertEmpresaMensaje: null,
      updateProyectoMensaje: null,
      insertProyectoMensaje: null
    })

    this.props.dispatch({
			type: `CLOSE_MODAL`
    })
    
    this.props.dispatch({
			type: 'CLEAN_NEW_USER'
    })
    
    this.props.dispatch({
			type: 'CLEAN_NEW_EMPRESA'
    })
    
    this.props.dispatch({
			type: 'CLEAN_NEW_PROYECTO'
		})

    /*this.props.dispatch({
			type: `UNFETCH_USERS`,
      payload: {
        dataUsers: []
      }
		})*/

  }

  handleOpenModal(data, typeModal){
    this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				data,
				typeModal
			}
		})

  }

  render() {

    let data = this.props.Modal.data,
        modalVisible = this.props.Modal.visible,
        typeModal = this.props.Modal.typeModal

    if (data === null) {
      return null
    } else if (typeModal === 'UserModalView') {

      return <UserView usuario={data} modalVisible={modalVisible} handleCloseModal={this.handleCloseModal} />

    } else if (typeModal === 'UserModalEdit') {
      
      return <UserEdit usuario={data} logInUser={this.props.usuario} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} dataUsers={this.props.dataUsers} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChange} handleSaveUser={this.handleSaveUser} updateUserMensaje={this.state.updateUserMensaje} dataEmpresas={this.props.dataEmpresas} dataProyectos={this.props.dataProyectos} />

    }else if(typeModal === 'InsertUser'){

      return <InsertUser usuario={this.props.dataUsers.nuevoUsuario} logInUser={this.props.usuario} modalVisible={modalVisible} handleCloseModal={this.handleCloseModal} dataUsers={this.props.dataUsers} handleInputChange={this.handleInputChangeInsert} handleSaveNewUser={this.handleSaveNewUser} dataEmpresas={this.props.dataEmpresas} insertUserMensaje={this.state.insertUserMensaje} dataProyectos={this.props.dataProyectos} />

    }else if(typeModal === 'UserModalPass'){

      return <PassWordEdit usuario={data} modalVisible={modalVisible} handleCloseModal={this.handleCloseModal} handleChangePassWord={this.handleChangePassWord} updateUserContrasenaMensaje={this.state.updateUserContrasenaMensaje}/>

    }else if(typeModal === 'EmpresaModalView'){

      return <EmpresaView empresa={data} modalVisible={modalVisible} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChange} />

    }else if(typeModal === 'EmpresaModalEdit'){

      return <EmpresaEdit empresa={data} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChange} handleSaveEmpresa={this.handleSaveEmpresa} dataEmpresas={this.props.dataEmpresas} updateEmpresaMensaje={this.state.updateEmpresaMensaje}/>

    }else if(typeModal === 'InsertEmpresa'){

      return <InsertEmpresa empresa={this.props.dataEmpresas.nuevaEmpresa} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChangeInsert} handleSaveEmpresa={this.handleSaveEmpresa} handleSaveNewEmpresa={this.handleSaveNewEmpresa} dataEmpresas={this.props.dataEmpresas} insertEmpresaMensaje={this.state.insertEmpresaMensaje} />

    }else if(typeModal === 'ProyectoModalView'){

      return <ProyectoView data={data} modalVisible={modalVisible} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChange} />

    }else if(typeModal === 'ProyectoModalEdit'){

      return <ProyectoEdit data={data} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChange} handleSaveProyecto={this.handleSaveProyecto} dataProyectos={this.props.dataProyectos} dataEmpresas={this.props.dataEmpresas} updateProyectoMensaje={this.state.updateProyectoMensaje} />

    }else if(typeModal === 'InsertProyecto'){

      return <InsertProyecto proyecto={this.props.dataProyectos.nuevoProyecto} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChangeInsert} handleSaveProyecto={this.handleSaveProyecto} handleSaveNewProyecto={this.handleSaveNewProyecto} dataEmpresas={this.props.dataEmpresas} dataProyectos={this.props.dataProyectos} insertProyectoMensaje={this.state.insertProyectoMensaje} />

    }

  }
}

let mapStateToProps = (state, props) => {
  return {
		conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    Modal: state.Modal,
    dataUsers: state.dataUsers,
    dataEmpresas: state.dataEmpresas,
    dataProyectos: state.dataProyectos
  }
}

export default connect(mapStateToProps)(ModalUser);
