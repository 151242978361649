import React, { Component } from 'react';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Slider from "react-slick";

import Img from './Img.jsx';

class Galeria extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        };

        this.onWindowResize = this.onWindowResize.bind(this);

    }

    componentDidMount() {

        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });

        window.addEventListener('resize', this.onWindowResize)
    }

    onWindowResize(){
        this.setState({
            window: {
                ...this.state.window,
                height: window.innerHeight,
                width: window.innerWidth
            }
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }
    
    render(){

        let settinsGal1 = {
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        swipeToSlide: true,
                        slidesToShow: 1,
                        infinite: true,
                        dots: true
                    }
                }
            ]
        },
        settingsGal2 = {
            slidesToShow : 1,
            swipeToSlide: true,
            focusOnSelect: true,
            dots: true,
            centerMode: true,
            variableWidth: true,
            centerPadding: '0px',
            infinite: true
        },
        displayGal2 = ((this.state.window.width > 576) && (this.props.proyecto.galImgs.length > 1)) ? "block" : "none"

        return(
            <div className="col-11 col-md-10 mx-auto">
                <div className="float-left">
                    <button style={{width: '28px', height: '28px', backgroundColor: '#FF9E00', borderRadius: '50%', border: 'none'}} onClick={ () =>{
                        let route ="DataProyecto"
                        this.props.handleChangeRoute(route)
                    }}>  
                        <FontAwesomeIcon icon='angle-left' style={{color: '#FFFFFF'}} />
                    </button>
                </div>
                <div className="col-12" style={{marginTop: '50px'}}>
                    <div className="row">
                        <div className="col-12">
                            <Slider asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)} {...settinsGal1}>
                                {
                                this.props.proyecto.galImgs.map( img => {
                                    return (
                                        <div key={img.idImagen} style={{padding: '15px'}}>
                                            <div className="col-12 mx-auto" style={{padding: '15px', top: '-40px'}}>

                                                <Img idImagen={img.idImagen}/>

                                                <p className="text-center">{moment(parseInt(img.fecha)).format("DD/MM/YYYY")}</p>
                                                {
                                                    img.descripcion !== null ? (
                                                        <p><b>Descripcion: </b>{img.descripcion}</p>
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                    )
                                })  
                                }
                            </Slider>
                        </div>
                        <div className="col-12" style={{marginTop: '50px', display: displayGal2}}>
                            <Slider asNavFor={this.state.nav1} ref={slider => (this.slider2 = slider)} {...settingsGal2}>
                                {
                                this.props.proyecto.galImgs.map( img => {
                                    return (
                                        <div key={img.idImagen} className="mx-auto">
                                            {
                                                this.state.window.width > 576 ? (
                                                    <div className="col-12 mx-auto" style={{padding: '15px', top: '-25px'}}>
                                                        <Img idImagen={img.idImagen} type='maxHeight' />
                                                    </div>
                                                ) : (
                                                    <div className="col-12 mx-auto" style={{padding: '15px', top: '-25px'}}>
                                                        <Img idImagen={img.idImagen} type='maxHeight' />
                                                        <p className="text-center">{moment(parseInt(img.fecha)).format("DD/MM/YYYY")}</p>
                                                        {
                                                            img.descripcion !== null ? (
                                                                <p><b>Descripcion: </b>{img.descripcion}</p>
                                                            ) : null
                                                        }
                                                    </div> 
                                                )
                                            }
                                        </div>
                                    )
                                })  
                                }
                            </Slider>
                        </div>
                        <div className="col-12 col-md" style={{marginTop: '15px'}}>
                            <button type="button" className="btn btn-outline-info mx-auto" onClick={ () =>{
                                let route ="MinGaleria"
                                this.props.handleChangeRoute(route)
                            }}>Mostrar Galería en Miniaturas</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Galeria