import React, { Component } from 'react';

import './css/AvancesCard.css';

import { RadialGauge } from 'chartjs-2-react';

class AvancesCard extends Component {

  render() {
    let fecha = new Date(),
      dia = fecha.getDate(),
      mes = fecha.getMonth() + 1,
      year = fecha.getFullYear()
    
    let porcentaje = (this.props.porcentaje * 100).toFixed(2),
    porcentajeTexto = `${porcentaje} %`
    
    return (
      <div className="avancesCard col-12 col-sm-6 mx-auto" style={{marginTop: '50px'}}>
        <div className="card">
          <div className="card-header">
            <h5 className="card-title text-center">{this.props.title}</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <h5 className="text-center col-12">Fecha: {`${dia}/${mes}/${year}`}</h5>
              <div className="col-12 col-lg-8 col-xl-5 mx-auto">
                
                <RadialGauge 
                  config={{
										data: {
											datasets: {
                        data: porcentaje,
                        backgroundColor: this.props.backgroundColor !== undefined ? this.props.backgroundColor : "#2A969E"
											}
										},
										options: {
											centerArea: {
												text: porcentajeTexto
											}
										}
									}} 
                />

              </div>
              <div className="col-12 col-xl-6 mx-auto d-flex align-items-center">
                <h4 className="text-center col-12">{this.props.title}</h4>
              </div>
            </div>

          </div>
          {/*<div className="card-footer bg-transparent">
            <h5 className="card-title text-center">Proyecto: Vidali</h5>
          </div>*/}
        </div>
      </div>
    )
  }
}

export default AvancesCard