import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let TableInformeSEP = props => (
    <div className="col-11 col-md-10 mx-auto">
        <div className="float-left">
            <button style={{width: '28px', height: '28px', backgroundColor: '#FF9E00', borderRadius: '50%', border: 'none'}} onClick={ () =>{
                let route ="DataProyecto"
                props.handleChangeRoute(route)
              }}>  
                <FontAwesomeIcon icon='angle-left' style={{color: '#FFFFFF'}} />
            </button>
        </div>
        <div className="table-responsive" style={{marginTop: '25px'}}>
            <h2 className="text-center">Informes SEP</h2>
            <table className="table text-center table-bordered table-hover table-sm">
                <thead className="thead-light">
                    <tr>
                        <th scope="col" className="d-none d-md-table-cell" >Id de Informe SEP</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.proyecto.informesSEP.map( informe => {
                            
                            let fecha = informe.fecha,
                            year = fecha.getFullYear()
                            let mes
                            (fecha.getMonth()+1) < 10 ? mes = `0${fecha.getMonth()+1}` : mes = `${fecha.getMonth()+1}`
                            let dia
                            fecha.getDate() < 10 ? dia = `0${fecha.getDate()}` : dia = `${fecha.getDate()}`
                            let hora
                            fecha.getHours() < 10 ? hora = `0${fecha.getHours()}` : hora = `${fecha.getHours()}`
                            let minutos
                            fecha.getMinutes() < 10 ? minutos = `0${fecha.getMinutes()}` : minutos = `${fecha.getMinutes()}`
                            let seg
                            fecha.getSeconds() < 10 ? seg = `0${fecha.getSeconds()}` : seg = `${fecha.getSeconds()}`

                            let fechaString = `Fecha: ${dia}/${mes}/${year} a las ${hora}:${minutos}:${seg} horas`

                            return(
                            <tr key={informe.idInformeSEP}>
                                <td className="d-none d-md-table-cell">{informe.idInformeSEP}</td>
                                <td>{fechaString}</td>
                                <td>

                                    <button onClick={ () => {
                                        props.handleShowInformeSEP(informe)
                                    }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#1A3B65'}}>
                                    <FontAwesomeIcon icon="eye" />
                                    </button>

                                    <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={ () => {
                                        props.downloadInformeSEP(informe)
                                    }} >
                                    <FontAwesomeIcon icon="file-download" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#1A3B65'}}/>
                                    </button>

                                    {
                                        props.usuario.tipo === 'colaborador' ? (
                                        <button onClick={ () => {
                                            props.handleDeleteInformeSEP(informe)
                                        }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#1A3B65'}}>
                                        <FontAwesomeIcon icon="trash-alt" />
                                        </button>
                                    ) : null
                                    }
                                    
                                    
                                </td>
                            </tr> 
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    </div>
)

export default TableInformeSEP