let consulta = (usuarioJSON, existColaborador) => {

  //console.log(usuarioJSON)

  const string =
    `mutation updateUser {
      updateUser(
        userData: {
          id: "${usuarioJSON.id}",
          usuario: "${usuarioJSON.usuario}",
          email: "${usuarioJSON.email}",
          nombre: "${usuarioJSON.nombre}",
          apellidos: "${usuarioJSON.apellidos}",
          telefono: ${usuarioJSON.telefono},
          fecha_de_nacimiento: "${usuarioJSON.fecha_de_nacimiento}",
          direccion: "${usuarioJSON.direccion}",
          sexo: "${usuarioJSON.sexo}",
          tipo: "${usuarioJSON.tipo}",
          permisos: "${usuarioJSON.permisos}"
          empresa_asociada: {
            id: "${usuarioJSON.empresa_asociada.id}",
            nombre: "${usuarioJSON.empresa_asociada.nombre}",
            telefono: ${usuarioJSON.empresa_asociada.telefono},
            correo: "${usuarioJSON.empresa_asociada.correo}"
          }
          colaborador: {
            puesto: "${usuarioJSON.colaborador.puesto}"
            NIT_colaborador: "${usuarioJSON.colaborador.NIT_colaborador}"
            contrato: "${usuarioJSON.colaborador.contrato}"
            ids_proyectos: "${usuarioJSON.colaborador.ids_proyectos}"
          }
          existColaborador: ${existColaborador}
        }
      ) {
        id
        usuario{
          id
          usuario
          email
          nombre
          apellidos
          telefono
          fecha_de_nacimiento
          direccion
          sexo
          tipo
          permisos
          empresa_asociada{
            id
            nombre
            telefono
            correo
          }
          colaborador{
            id
            puesto
            NIT_colaborador
            contrato
            FK_usuario
            ids_proyectos
          }
        }
        mensaje
      }
    }`

    return {
      "query": string
    }

}

export default consulta
