let consulta = (data) => {

  //console.log(proyectoData)

  let informesSEP

  if(data.informesSEP !== null){

    let arrayOfString = data.informesSEP.map( informe => {
      return JSON.stringify(informe).replace(/{"/g, '{').replace(/":/g, ':').replace(/"idInformeSEP/g, 'idInformeSEP').replace(/"fecha/g, 'fecha')
    })

    informesSEP = `[${arrayOfString.toString()}]`

  }else{
    informesSEP = data.informesSEP
  }

  const string =
    `mutation deleteInformeSEP {
      deleteInformeSEP(
        idProyecto: "${data.idProyecto}",
        informesSEP: ${informesSEP},
        informeId: "${data.informeId}"
      ){
        id
        mensaje
      }
    }`

    return {
      "query": string
    }

}

export default consulta
