let consulta = (data) => {

  const string =
    `mutation insertLogoProyecto {
      insertLogoProyecto(idProyecto:"${data.idProyecto}", logoProyecto: "${data.logoProyecto}")
      {
        idProyecto
        logoProyecto
        mensaje
      }
    }`

    return {
      "query": string
    }

}

export default consulta
