import React, { Component } from 'react';

import AvanceRealVrsProyectado from './AvanceRealVrsProyectado.jsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { connect } from 'react-redux';

import Flujo from './Flujo.jsx';

import AvancesCard from '../../../Components/Cards/AvancesCard.jsx';
import ProgresoCard from '../../../Components/Cards/ProgresoCard.jsx';
import FaseCard from '../../../../Dashboard/dashboard/components/Cards/FaseCard.jsx';
import EstadoCard from '../../../../Dashboard/dashboard/components/Cards/EstadoCard.jsx';

import Loader from '../../../../Components/Loader.jsx';

import FetchNewImagenProyecto from '../NewImagenProyectoFetch/FetchNewImagenProyecto.js';

class DataProyecto extends Component {

  constructor(props){

    super(props)

    this.state = {
      insertImagenProyectoMensaje: null,
      loading: false
    }

    // This binding is necessary to make `this` work in the callback
    this.handleInputFileChangeImagen = this.handleInputFileChangeImagen.bind(this);
  }

  componentDidMount(){
    
  }

  handleInputFileChangeImagen(){
    let This = this,
    proyecto = this.props.proyecto,
    imgProyectoData = {
      idProyecto: proyecto.id
    }

    function convertToBase64() {
        //Read File
        var selectedFile = document.getElementById("imgProyecto").files;
        //Check File is not Empty
        if (selectedFile.length > 0) {

            This.setState({
              loading: true
            })

            // Select the very first file from list
            var fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            var fileReader = new FileReader();
            var base64;
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                //console.re.log(base64);
                //console.log(base64);
                imgProyectoData.imagenProyecto = base64

                //console.log(imgProyectoData.logoProyecto)
                
                FetchNewImagenProyecto(This, imgProyectoData)
                
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }
    convertToBase64()
  }

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render(){
    
    //Empieza Sacar Valores para PorcentajeEstimado y PorcentajeReal
    let porcentajeEstimadoVal,
        porcentajeRealVal

    if(this.props.proyecto.dataProyecto !== null){

      let dataProyectoPorcEstiYPorcRealArr = this.props.proyecto.dataProyecto.filter( proyectoData =>{
        return (proyectoData.PorcentajeEstimado !== null) && (proyectoData.PorcentajeReal !== null)
      })
  
      let mayorSemanaValue = 0
  
      dataProyectoPorcEstiYPorcRealArr.forEach(data => {
        if(parseInt(data.Semana) > mayorSemanaValue){
          mayorSemanaValue = parseInt(data.Semana)
        }
      });
  
      let dataProyectoPorcEstiYPorcReal = dataProyectoPorcEstiYPorcRealArr.filter(data => {
        return data.Semana === mayorSemanaValue.toString()
      })
  
      porcentajeEstimadoVal = dataProyectoPorcEstiYPorcReal[0].PorcentajeEstimado
      porcentajeRealVal = dataProyectoPorcEstiYPorcReal[0].PorcentajeReal
    }

    let proyectManagers = this.props.dataUsers.colaboradores.filter( colaborador => colaborador.puesto === "Proyect Manager"),
        proyectManager = proyectManagers.filter( proyectManager => proyectManager.ids_proyectos.includes(this.props.proyecto.id) )[0],
        proyectManagerUsuario = proyectManager !== undefined ? this.props.dataUsers.usuarios.filter( usuario =>  parseInt(usuario.id) === proyectManager.FK_usuario)[0] : undefined

    return(
      <div className="col-12" style={{'marginTop': '25px'}}>

        <div className="row">
          <div className="col-md-4 mx-auto">
            <p className="col" id="idProyecto" style={{'marginTop': '0px'}}><b>Id Proyecto:</b> {this.props.proyecto.id}</p>
          </div>

          <div className="col-md-4 mx-auto">
            <p className="col" id="nombreProyecto" style={{'marginTop': '0px'}}><b>Nombre:</b> {this.props.proyecto.nombre}</p>
          </div>
          
          <div className="col-md-4 mx-auto">
            <p className="col" id="empresaAsociada" style={{'marginTop': '0px'}}><b>Proyecto de Empresa con Nombre:</b> {this.props.empresaProyecto.nombre_comercial}</p>
          </div>

          <div className="col-md-4 mx-auto">
            <p className="col" id="pais" style={{'marginTop': '0px'}}><b>País:</b> {this.props.proyecto.pais}</p>
          </div>

          <div className="col-md-4 mx-auto">
            <p className="col" id="direccion" style={{'marginTop': '0px'}}><b>Dirección:</b> {this.props.proyecto.direccion}</p>
          </div>

          <div className="col-md-4 mx-auto">
            <p className="col" id="telefono" style={{'marginTop': '0px'}}><b>Teléfono:</b> {this.props.proyecto.telefono}</p>
          </div>

          <div className="col-md-4 mx-auto">
            <p className="col" id="correo" style={{'marginTop': '0px'}}><b>Correo:</b> {this.props.proyecto.correo}</p>
          </div>

          <div className="col-md-4 mx-auto">
            <p className="col" id="divisa" style={{'marginTop': '0px'}}><b>Divisa:</b> {this.props.proyecto.divisa}</p>
          </div>
          
          <div className="col-md-4 mx-auto">
            <p className="col" id="presupuesto" style={{'marginTop': '0px'}}><b>Presupuesto:</b> {this.props.proyecto.divisa === "Quetzales" ? "Q." : null} {this.props.proyecto.divisa === "Dolares" ? "$." : null} {this.numberWithCommas(this.props.proyecto.presupuesto)}</p>
          </div>
          
          <div className="col-md-4 mx-auto">
            <p className="col" id="presupuestoGastado" style={{'marginTop': '0px'}}><b>Presupuesto Gastado:</b> {this.props.proyecto.divisa === "Quetzales" ? "Q." : null} {this.props.proyecto.divisa === "Dolares" ? "$." : null} {this.numberWithCommas(this.props.proyecto.presupuestoGastado)}</p>
          </div>

        </div>

        <div className="row">

          <div className="col-md-3 col-lg-4 mx-auto">
            <EstadoCard proyecto={this.props.proyecto}/>
          </div>

          <div className="col-md-9 col-lg-8 mx-auto">
            <FaseCard fase={this.props.proyecto.fase}/>
          </div>

        </div>

        {
          proyectManagerUsuario !== undefined ? (
            <div className="row" style={{marginTop: '25px'}}>

              <h4 className="col-12 text-center">Project Manager</h4>

              <div className="col-md-4 mx-auto">
                <p className="col" id="PMNombre" style={{'marginTop': '0px'}}><b>Nombre:</b> {proyectManagerUsuario.nombre} {proyectManagerUsuario.apellidos}</p>
              </div>

              <div className="col-md-4 mx-auto">
                <p className="col" id="PMMail" style={{'marginTop': '0px'}}><b>Correo:</b> {proyectManagerUsuario.email}</p>
              </div>

              <div className="col-md-4 mx-auto">
                <p className="col" id="PMTel" style={{'marginTop': '0px'}}><b>Teléfono:</b> {proyectManagerUsuario.telefono}</p>
              </div>

            </div>
          ) : null
        }

        <div className="row" style={{paddingLeft: '10px', paddingRight: '10px'}}>
          {
            this.props.proyecto.galImgs !== null ? (
              <div className="progresoCard col-12 col-sm-6 col-lg-4 mx-auto" style={{marginTop: '50px', cursor: 'pointer'}} onClick={ () => {
                let route ="Galeria"
                this.props.handleChangeRoute(route)
              }}>
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title text-center">Mostrar Galería</h5>
                  </div>
                  <div className="card-body">

                    <div className="row">
                      <div className="col-12 text-center">
                        <h1><FontAwesomeIcon icon="images" style={{fontSize: "80px", color: '#17a2b8'}} /></h1>
                      </div>
                    </div>
                    

                  </div>
                </div>
              </div>
            ) : null 
          }
          {
            this.props.proyecto.informesSEP === null ? null : (
            <div className="progresoCard col-12 col-sm-6 col-lg-4 mx-auto" style={{marginTop: '50px', cursor: 'pointer'}} onClick={ () => {
                let route ="TableInformesSEP"
                this.props.handleChangeRoute(route)
              }}>
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title text-center">Mostrar Historial de Informes SEP</h5>
                  </div>
                  <div className="card-body">

                    <div className="row">
                      <div className="col-12 text-center">
                        <h1><FontAwesomeIcon icon="history" style={{fontSize: "80px", color: '#17a2b8', marginRight: '10px'}} /><FontAwesomeIcon icon="file-invoice-dollar" style={{fontSize: "80px", color: '#17a2b8'}} /></h1>
                      </div>
                    </div>
                    

                  </div>
                </div>
              </div>
            )
          }
          {
            this.props.proyecto.informesSEP === null ? null : (
            <div className="progresoCard col-12 col-sm-6 col-lg-4 mx-auto" style={{marginTop: '50px', cursor: 'pointer'}} onClick={ () => {
                let arrInformesSEP = this.props.proyecto.informesSEP

                let valorMayor = Math.max.apply(null, arrInformesSEP.map(function(informe) {
                  return informe.fecha;
                }))

                let filterInforme = arrInformesSEP.filter( informe => {
                  return informe.fecha.getTime() === valorMayor
                })

                this.props.handleShowInformeSEP(filterInforme[0])
              }}>
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title text-center">Mostrar Último Informe SEP</h5>
                  </div>
                  <div className="card-body">

                    <div className="row">
                      <div className="col-12 text-center">
                        <h1><FontAwesomeIcon icon="file-invoice-dollar" style={{fontSize: "80px", color: '#17a2b8'}} /></h1>
                      </div>
                    </div>
                    

                  </div>
                </div>
              </div>
            )
          }
          {
            this.props.proyecto.planos !== null ? (
              <div className="progresoCard col-12 col-sm-6 col-lg-4 mx-auto" style={{marginTop: '50px', cursor: 'pointer'}} onClick={ () => {
                let route ="ViewPlanosCarpetas"
                this.props.handleChangeRoute(route)
              }}>

                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title text-center">Mostrar Planos</h5>
                  </div>
                  <div className="card-body">

                    <div className="row">
                      <div className="col-12 text-center">
                        <h1><FontAwesomeIcon icon="layer-group" style={{fontSize: "80px", color: '#17a2b8'}} /></h1>
                      </div>
                    </div>
                    

                  </div>
                </div>
              </div>
            ) : null 
          }
        </div>

        <div className="row" style={{paddingLeft: '25px', paddingRight: '25px'}}>

          {
            this.props.proyecto.dataProyecto !== null ? (
              <AvancesCard title="Porcentaje Avance Estimado" porcentaje={porcentajeEstimadoVal} proyecto={this.props.proyecto} backgroundColor="#4bc0c0" />
            ) : null
          }

          {
            this.props.proyecto.dataProyecto !== null ? (
              <AvancesCard title="Porcentaje Avance Real" porcentaje={porcentajeRealVal} proyecto={this.props.proyecto} backgroundColor="#4bc000" />
            ) : null
          }

          
          <ProgresoCard proyecto={this.props.proyecto} />

          <div className="col-12">
            <div className="row">
              <AvanceRealVrsProyectado proyecto={this.props.proyecto}/>
            </div>
          </div>

          <Flujo proyecto={this.props.proyecto} />

        </div>

        {/*
          this.props.proyecto.informesSEP === null ? null : (
            <div className="col-12" style={{marginTop: '25px'}}>
              <div className="row">
                <div className="col-12 col-md" style={{marginTop: '15px'}}>
                  <button type="button" className="btn btn-outline-info mx-auto" onClick={ () =>{
                    let route ="TableInformesSEP"
                    this.props.handleChangeRoute(route)
                  }}>Mostrar Historial de Informes SEP</button>
                </div>
                <div className="col-12 col-md" style={{marginTop: '15px'}}>
                  <button type="button" className="btn btn-outline-info mx-auto" onClick={ () => {

                    let arrInformesSEP = this.props.proyecto.informesSEP

                    let valorMayor = Math.max.apply(null, arrInformesSEP.map(function(informe) {
                      return informe.fecha;
                    }))

                    let filterInforme = arrInformesSEP.filter( informe => {
                      return informe.fecha.getTime() === valorMayor
                    })

                    this.props.handleShowInformeSEP(filterInforme[0])
                    
                  }}>Mostrar Último Informe SEP</button>
                </div>
              </div>
            </div>
          )
        */}

        <div className="col-12" style={{marginTop: '25px'}}>
          <div className="row">

            {/*<div className="col-12 custom-file mx-auto" style={{marginTop: '35px', marginBottom: '45px'}}>
              <label htmlFor="archivo">Archivo: </label><br/>
              <input type="file" className="archivo1" id="imgProyecto" name="imgProyecto" required style={{width: '1px', height: '1px', opacity: '0', overflow: 'hidden', position: 'absolute', zIndex: '-1'}} onChange={this.handleInputFileChangeImagen}/>
              <label className="btn btn-outline-primary mx-auto" htmlFor="imgProyecto" style={{cursor: 'pointer'}}><span>Selecciona una Imagen para Subir a Galería</span></label>
            </div>*/}

            {
              this.state.insertImagenProyectoMensaje !== null ? (
                <div className="col-12">
                  <div className="row" style={{marginTop: '25px'}}>
                    {
                      (this.state.insertImagenProyectoMensaje.response === 'success') ? (
                        <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.insertImagenProyectoMensaje.mensaje}</div>
                      ) : (
                        <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.insertImagenProyectoMensaje.mensaje}</div>
                      )
                    }
                  </div>
                </div>
              ) : null
            }

          </div>
        </div>

        {
          this.state.loading === true ? (
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-3 mx-auto">
                  <Loader />
                </div>
              </div>
            </div>
          ) : null
        }

      </div>
    )
  }

  
}

let mapStateToProps = (state, props) => {
  return {
    usuario: state.logIn.usuario,
    dataUsers: state.dataUsers
  }
}

export default connect(mapStateToProps)(DataProyecto);