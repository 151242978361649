import React, { Component } from 'react';

import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';

import GraphQLEmpresas from '../../../../apiService/FetchGraphQLEmpresas.js';

import GraphQLProyectos from '../../../../apiService/FetchGraphQLProyectos.js';

import deleteProyecto from './DeleteProyectoFetch/FetchDeleteProyecto.js';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import ProyectosTable from '../components/ProyectosTable.jsx';

class AdminProyectosDashboard extends Component{

	constructor(props) {
    super(props);

		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleDeleteProyecto = this.handleDeleteProyecto.bind(this);
  }

	handleOpenModal(data, typeModal){
    this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				data,
				typeModal
			}
		})

  }

	handleDeleteProyecto(proyecto) {

		deleteProyecto(this, proyecto)

	}

	componentDidMount() {

			if(this.props.conexion === false){
			   return <Redirect to='/login' />
			}else if(this.props.usuario.permisos.includes('adminProyectos') === false){
				return <Redirect to='/dashboard' />
				//console.log(this.usuario.conexion)
			}

			GraphQLEmpresas(this);
			GraphQLProyectos(this)

  	}

		setButton = element => {
			if(element.dataset.modalType === 'viewEmpresa'){
				this.button = element.id;
				//console.log(element.id);
			}else if(element.dataset.modalType === 'editEmpresa'){
				this.button = element.id;
				//console.log(element);
			}

		}

	render(){
		if (this.props.conexion === false || this.props.usuario.permisos.includes('adminProyectos') === false) {
			return <Redirect to='/login' />
		}else{
			return(
				<DashboardComponent>
					<div className="container-fluid" style={{minHeight: '78vh'}}>
						<ProyectosTable usuario={this.props.usuario} setButtonViewProyecto={this.setButton} setButtonEditProyecto={this.setButton} dataEmpresas={this.props.dataEmpresas} dataProyectos={this.props.dataProyectos} handleOpenModal={this.handleOpenModal} handleDeleteProyecto={this.handleDeleteProyecto}/>
		      </div>
				</DashboardComponent>
			)
		}
	}
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
		usuario: state.logIn.usuario,
		dataEmpresas: state.dataEmpresas,
		dataProyectos: state.dataProyectos
  }
}

export default connect(mapStateToProps)(AdminProyectosDashboard)
