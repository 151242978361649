let handleInputChange = (event, This) => {

  let value = event.target.value,
      usuario = event.target.dataset.usuario,
      type = event.target.dataset.valueType

  This.props.dataUsers.usuarios.filter( obj => {

    if((obj.id === JSON.parse(usuario).id) === true){

      if(type === 'usuario'){

        obj.usuario = value

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })

      }else if(type === 'nombre'){
        obj.nombre = value

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'apellidos'){
        obj.apellidos = value

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'telefono'){
        obj.telefono = value

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'email'){
        obj.email = value

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })

      }else if(type === 'fecha_de_nacimiento'){

        //var string = "1993-04-01";
        var d = Date.parse(value);
        var d2 = d.toString();
        var d3 = new Date(parseInt(d2));
        d3.setDate(d3.getDate() + 1)

        var d4 = Date.parse(d3);

        obj.fecha_de_nacimiento = d4

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'direccion'){
        obj.direccion = value

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'sexo'){
        obj.sexo = value

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })

      }else if(type === 'tipo'){
        obj.tipo = value

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'permisos'){

        //console.log(event.target.options.length)

        var opts = [], opt;

        // loop through options in select list
        for (var i=0, len=event.target.options.length; i<len; i++) {
            opt = event.target.options[i];

            // check if selected
            if ( opt.selected ) {
                // add to array of option elements to return from this function
                opts.push(opt.value);

                // invoke optional callback function if provided
                /*if (fn) {
                    fn(opt);
                }*/
            }
        }

        // return array containing references to selected option elements
        obj.permisos = opts
        //return opts;

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'EmpresaAsociada'){
        //obj.empresa_asociada.nombre = value

        let empresa = This.props.dataEmpresas.empresas.filter(empresa => empresa.nombre_comercial === value)

        empresa[0] === undefined ? obj.empresa_asociada.id = null : obj.empresa_asociada.id = empresa[0].id

        empresa[0] === undefined ? obj.empresa_asociada.nombre = null : obj.empresa_asociada.nombre = empresa[0].nombre_comercial

        empresa[0] === undefined ? obj.empresa_asociada.telefono = null : obj.empresa_asociada.telefono = empresa[0].telefono

        empresa[0] === undefined ? obj.empresa_asociada.correo = null : obj.empresa_asociada.correo = empresa[0].correo

        //console.log(empresa[0].id)

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })

      } else if (type === 'puesto') {

        if (value === "") {
          value = null
        }

        obj.colaborador.puesto = value

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })

      } else if (type === 'NIT_colaborador') {
        
        if (value === "") {
          value = null
        }
        
        obj.colaborador.NIT_colaborador = value

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })

      } else if (type === 'contrato') {
        
        if (value === "") {
          value = null
        }
        
        obj.colaborador.contrato = value

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })

      }else if(type === 'proyectos'){

        //console.log(event.target.options.length)
        //console.log(event.target.options)
        //console.log(obj)

        var optsProyectos = [], optProyecto;

        // loop through options in select list
        for (var iproy=0, leng=event.target.options.length; iproy<leng; iproy++) {
          optProyecto = event.target.options[iproy];

            // check if selected
            if ( optProyecto.selected ) {
                // add to array of option elements to return from this function
                optsProyectos.push(optProyecto.value);

                // invoke optional callback function if provided
                /*if (fn) {
                    fn(opt);
                }*/
            }
        }

        // return array containing references to selected option elements
        obj.colaborador.ids_proyectos = optsProyectos
        //console.log(obj)
        //return opts;

        This.props.dispatch({
          type:'UPDATE_MODAL',
          payload: {
            data: obj
          }
        })

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }

    }

    return null

  })

}

export default handleInputChange;
