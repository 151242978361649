import React, { Component } from 'react';

import { connect } from 'react-redux';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DataProyecto from './DataProyecto.jsx';
import SubirImagenes from  './SubirImagenes.jsx';
import SubirPlano from './SubirPlano.jsx';

import FetchNewReporteSEP from '../NewReporteSEPFetch/FetchNewReporteSEPPromise.js';

import xlsxParser from 'xlsx-parse-json';

import numeral from 'numeral'

import FetchNewProyectoData from '../NewProyectoDataFetch/FetchNewProyectoDataPromise.js';

import FetchNewProyectoFlujoData from '../NewProyectoFlujoDataFetch/FetchNewProyectoFlujoDataPromise.js';

import FetchNewLogoProyecto from '../NewLogoProyectoFetch/FetchNewLogoProyecto.js';

class ProyectoEdit extends Component {

  constructor(props){
    super(props)
    
    this.state = {
      route: "DataProyecto",
      subirReporte: false,
      informeSEP: null,
      loading: false,
      insertReporteSEPMensaje: null,
      insertProyectoDataMensaje: null,
      insertProyectoLogoMensaje: null
    }

    // This binding is necessary to make `this` work in the callback
    this.handleInputFileChangeSEP = this.handleInputFileChangeSEP.bind(this);
    this.handleInputFileChangeLogo = this.handleInputFileChangeLogo.bind(this);
    this.handleChangeSubirReporte = this.handleChangeSubirReporte.bind(this);
    this.handleSaveReporteSEP = this.handleSaveReporteSEP.bind(this);
    this.handleInputFileChangeAvanceExcel = this.handleInputFileChangeAvanceExcel.bind(this);
    this.handleInputFileChangeFlujoExcel = this.handleInputFileChangeFlujoExcel.bind(this);
    this.handleChangeRoute = this.handleChangeRoute.bind(this);
  }

  handleChangeRoute(route){
    this.setState({
      route
    })
  }

  handleChangeSubirReporte(){
    if(this.state.subirReporte === false){
      this.setState({
        subirReporte: true
      })
    }else{
      this.setState({
        subirReporte: false
      })
    }
  }
  
  handleInputFileChangeSEP(){
    let This = this
    function convertToBase64() {
        //Read File
        var selectedFile = document.getElementById("archivo").files;
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            var fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            var fileReader = new FileReader();
            var base64;
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                //console.re.log(base64);
                //console.log(base64);
                This.setState({
                  informeSEP: base64
                })
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }
    convertToBase64()
  }

  handleInputFileChangeLogo(){
    let This = this,
    data = this.props.data,
    proyecto = data.proyecto,
    logoProyectoData = {
      idProyecto: proyecto.id
    }

    function convertToBase64() {
        //Read File
        var selectedFile = document.getElementById("logo").files;
        //Check File is not Empty
        if (selectedFile.length > 0) {

            This.setState({
              loading: true
            })

            // Select the very first file from list
            var fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            var fileReader = new FileReader();
            var base64;
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                //console.re.log(base64);
                //console.log(base64);
                logoProyectoData.logoProyecto = base64
                
                FetchNewLogoProyecto(This, logoProyectoData)
                
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }
    convertToBase64()
  }

  handleInputFileChangeAvanceExcel(){

    let This = this,
        data = this.props.data,
        proyecto = data.proyecto,
        proyectoData = {
          idProyecto: proyecto.id
        }

    //Read File
    var selectedFile = document.getElementById("excelAvance").files;
    //Check File is not Empty
    if (selectedFile.length > 0) {

        This.setState({
          loading: true
        })

        // Select the very first file from list
        var fileToLoad = selectedFile[0];

        xlsxParser
        .onFileSelection(fileToLoad, { showNullProperties: true} )
        .then( data => {

          let newData = data.dataProyecto.map( dataProyecto => {
            dataProyecto.PorcentajeEstimado = dataProyecto.PorcentajeEstimado === null ? null : numeral(dataProyecto.PorcentajeEstimado)._value
            dataProyecto.PorcentajeReal = dataProyecto.PorcentajeReal === null ? null : numeral(dataProyecto.PorcentajeReal)._value
            dataProyecto.Diferencia = dataProyecto.Diferencia === null ? null : numeral(dataProyecto.Diferencia)._value
            return dataProyecto
          })

          proyectoData.dataProyecto = newData

          FetchNewProyectoData(This, proyectoData)

          //console.log(proyectoData)

        });
        
    }
    
  }

  handleInputFileChangeFlujoExcel(){

    let This = this,
        data = this.props.data,
        proyecto = data.proyecto,
        proyectoFlujoData = {
          idProyecto: proyecto.id
        }

    //Read File
    var selectedFile = document.getElementById("excelFlujo").files;
    //Check File is not Empty
    if (selectedFile.length > 0) {

        This.setState({
          loading: true
        })

        // Select the very first file from list
        var fileToLoad = selectedFile[0];

        xlsxParser
        .onFileSelection(fileToLoad, { showNullProperties: true} )
        .then( data => {

          let newData = data.dataFlujoProyecto.map( dataFlujoProyecto => {
            dataFlujoProyecto.PresupuestoProyectado = dataFlujoProyecto.PresupuestoProyectado === null ? null : numeral(dataFlujoProyecto.PresupuestoProyectado)._value
            dataFlujoProyecto.PresupuestoAcumulado = dataFlujoProyecto.PresupuestoAcumulado === null ? null : numeral(dataFlujoProyecto.PresupuestoAcumulado)._value
            dataFlujoProyecto.PresupuestoEjecutado = dataFlujoProyecto.PresupuestoEjecutado === null ? null : numeral(dataFlujoProyecto.PresupuestoEjecutado)._value
            dataFlujoProyecto.PresupuestoRealAcumulado = dataFlujoProyecto.PresupuestoRealAcumulado === null ? null : numeral(dataFlujoProyecto.PresupuestoRealAcumulado)._value
            dataFlujoProyecto.PresupuestoAjustado = dataFlujoProyecto.PresupuestoAjustado === null ? null : numeral(dataFlujoProyecto.PresupuestoAjustado)._value
            dataFlujoProyecto.PresupuestoAjustadoAcumulado = dataFlujoProyecto.PresupuestoAjustadoAcumulado === null ? null : numeral(dataFlujoProyecto.PresupuestoAjustadoAcumulado)._value
            return dataFlujoProyecto
          })

          //console.log(newData)

          proyectoFlujoData.dataFlujoProyecto = newData

          //console.log(proyectoFlujoData)

          FetchNewProyectoFlujoData(This, proyectoFlujoData)

        });
        
    }
    
  }

  handleSaveReporteSEP(){

    let proyecto = this.props.data.proyecto,
        proyectoId = proyecto.id,
        informesSEP = proyecto.informesSEP === undefined ? proyecto.informesSEP = null : proyecto.informesSEP,
        empresa = this.props.dataEmpresas.empresas.filter( empresa => {
          return empresa.id === proyecto.idEmpresa
        }),
        proyectoData = {
          id: proyectoId,
          nombre: proyecto.nombre,
          empresa: empresa[0].nombre_comercial,
          informesSEP
        },
        informeSEP = this.state.informeSEP

    if(informeSEP === null){

      let This = this

      this.setState({
        insertReporteSEPMensaje: {
          response: 'failure',
          mensaje: `Debes de incluir un Reporte SEP`
        }
      })

      setTimeout( () => {
        This.setState({
          insertReporteSEPMensaje: null
        }) 
      }, 3000);

    }else{

      let data = {
        proyectoData,
        informeSEP
      }

      console.log(data)

      FetchNewReporteSEP(this, data)
      this.setState({
        loading: true
      })

    }

  }

  render(){
    let data = this.props.data,
      proyecto = data.proyecto,
      proyectoString = JSON.stringify(proyecto),
      modalVisible = this.props.modalVisible,
      handleCloseModal = this.props.handleCloseModal

    //Empieza Sacar Valores para PorcentajeEstimado y PorcentajeReal
    let porcentajeEstimadoVal,
        porcentajeRealVal

    if(proyecto.dataProyecto !== null){
      let dataProyectoPorcEstiYPorcRealArr = proyecto.dataProyecto.filter( proyectoData =>{
        return (proyectoData.PorcentajeEstimado !== null) && (proyectoData.PorcentajeReal !== null)
      })
  
      let mayorSemanaValue = 0
  
      dataProyectoPorcEstiYPorcRealArr.forEach(data => {
        if(parseInt(data.Semana) > mayorSemanaValue){
          mayorSemanaValue = parseInt(data.Semana)
        }
      });
  
      let dataProyectoPorcEstiYPorcReal = dataProyectoPorcEstiYPorcRealArr.filter(data => {
        return data.Semana === mayorSemanaValue.toString()
      })
  
      porcentajeEstimadoVal = dataProyectoPorcEstiYPorcReal[0].PorcentajeEstimado
      porcentajeRealVal = dataProyectoPorcEstiYPorcReal[0].PorcentajeReal
    }
    //Termina Sacar Valores para PorcentajeEstimado y PorcentajeReal

    let empresaArr = this.props.dataEmpresas.empresas.filter( empresa => {
      return empresa.id === proyecto.idEmpresa
    }),
        empresa = empresaArr[0]

    return(
      <ModalContainer>
        <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
              <img className="brand-modal" src="/img/svg/LogoBlanco.svg" alt="LogoBiolabBlanco" />
              <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                <div></div>
                <div></div>
              </button>
              </div>

              {
                this.state.route === "DataProyecto" ? (
                  <DataProyecto empresa={empresa} proyecto={proyecto} proyectoString={proyectoString} porcentajeEstimadoVal={porcentajeEstimadoVal} porcentajeRealVal={porcentajeRealVal} state={this.state} This={this} />
                ) : null
              }

              {
                this.state.route === "SubirImagenes" ? <SubirImagenes handleChangeRoute={this.handleChangeRoute} proyecto={proyecto} /> : null
              }

              {
                this.state.route === "SubirPlano" ? <SubirPlano handleChangeRoute={this.handleChangeRoute} proyecto={proyecto} /> : null
              }

              {
                this.state.route === "DataProyecto" ? (
                  <div className="modal-footer">
                    {
                      this.state.subirReporte === false ? (
                        <button type="button" className="btn btn-default mx-auto" onClick={this.props.handleSaveProyecto}><FontAwesomeIcon icon="save" style={{'fontSize': '150%'}} /></button>
                      ) : null
                    }
                    {
                      this.state.subirReporte === true ? (
                        <button type="button" className="btn btn-default mx-auto" onClick={this.handleSaveReporteSEP}><FontAwesomeIcon icon="save" style={{'fontSize': '150%'}} /></button>
                      ) : null
                    }
                    <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                  </div>
                ) : (
                  <div className="modal-footer">
                    <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                  </div>
                )
              }

            </div>
          </div>
        </ModalB4>
      </ModalContainer>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
    dataProyectos: state.dataProyectos,
    dataEmpresas: state.dataEmpresas
  }
}

export default connect(mapStateToProps)(ProyectoEdit);
