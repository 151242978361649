const initialState = {
  proyectos: [],
  nuevoProyecto: {
    idEmpresa: "1",
    nombre: "",
    pais: "",
    direccion: "",
    telefono: "",
    correo: "",
    divisa: "Quetzales",
    presupuesto: 0,
    presupuestoGastado: 0,
    estado: "OS",
    fase: "Desarrollo"
  }
}

let dataProyectos = (state = initialState, action) => {
  switch (action.type) {

    case 'FETCH_PROYECTOS': {

      let proyectos = action.payload.dataProyectos

      let newState = {
        ...state,
        proyectos: proyectos
      }

      return newState
    }
      
    case 'DELETE_PROYECTO': {

      let proyecto = action.payload.dataProyecto

      let newProyectos = state.proyectos.filter( proyect => proyect.id !== proyecto.id)

      let newState = {
        ...state,
        proyectos: newProyectos
      }

      return newState

    }
      
    case 'CHANGE_DATA_PROYECTOS': {

      let proyecto = action.payload.dataProyecto

      let proyectoArr = [proyecto]

      let newStateProyectos = state.proyectos.map( obj => proyectoArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        proyectos: newStateProyectos
      }

      return newState
    }
      
    case 'NEW_PROYECTO': {

      let nuevoProyecto = action.payload.dataProyecto

      let newState = {
        ...state,
        nuevoProyecto
      }

      return newState

    }
      
    case 'CLEAN_NEW_PROYECTO': {

      let newState = {
        ...state,
        nuevoProyecto: {
          idEmpresa: "1",
          nombre: "",
          pais: "",
          direccion: "",
          telefono: "",
          correo: "",
          divisa: "Quetzales",
          presupuesto: 0,
          presupuestoGastado: 0,
          estado: "OS",
          fase: "Desarrollo"
        }
      }

      return newState

    }

    case 'CHANGE_STATE_REPORTE_SEP': {

      let nuevosReportes = action.payload.nuevosReportes,
          proyectoId = action.payload.proyectoId

      let proyectoArr = state.proyectos.filter( proyecto=>{
        return proyecto.id === proyectoId
      })

      proyectoArr[0].informesSEP = nuevosReportes

      let newStateProyectos = state.proyectos.map( obj => proyectoArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        proyectos: newStateProyectos
      }

      return newState

    }
      
    case 'ADD_PROYECTO_TO_PROYECTOS': {

      let proyecto = action.payload.dataProyecto,
          proyectoArr = [proyecto]

      let proyectos = state.proyectos.concat(proyectoArr)

      let newState = {
        ...state,
        proyectos
      }

      return newState
      //return state
    }

    case 'CHANGE_DATA_PROYECTOS_INSERT_EXCEL': {

      let proyectoDataExcel = action.payload.dataProyecto

      let proyectoArr = state.proyectos.filter( proyecto => proyecto.id === proyectoDataExcel.idProyecto)

      proyectoArr[0].dataProyecto = proyectoDataExcel.dataProyecto

      let newStateProyectos = state.proyectos.map( obj => proyectoArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        proyectos: newStateProyectos
      }
      return newState
    }

    case 'CHANGE_DATA_FLUJO_PROYECTOS_INSERT_EXCEL': {

      let proyectoFlujoDataExcel = action.payload.dataFlujoProyecto

      let proyectoArr = state.proyectos.filter( proyecto => proyecto.id === proyectoFlujoDataExcel.idProyecto)

      proyectoArr[0].dataFlujoProyecto = proyectoFlujoDataExcel.dataFlujoProyecto

      let newStateProyectos = state.proyectos.map( obj => proyectoArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        proyectos: newStateProyectos
      }
      return newState
    }

    case 'CHANGE_LOGO_PROYECTOS': {

      let idProyecto = action.payload.idProyecto,
          logoProyecto = action.payload.logoProyecto

      let proyectoArr = state.proyectos.filter( proyecto => proyecto.id === idProyecto)

      proyectoArr[0].logoProyecto = logoProyecto

      let newStateProyectos = state.proyectos.map( obj => proyectoArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        proyectos: newStateProyectos
      }
      return newState
    }

    case 'ADD_IMAGEN_TO_GALERIA': {

      let idProyecto = action.payload.idProyecto,
          imagenProyecto = action.payload.imagenProyecto

      let proyectoArr = state.proyectos.filter( proyecto => proyecto.id === idProyecto),
          proyecto = proyectoArr[0],
          newGalImgs = proyecto.galImgs === null ? [imagenProyecto] : proyecto.galImgs.concat([imagenProyecto])

          proyecto.galImgs = newGalImgs

          let newStateProyectos = state.proyectos.map( obj => proyectoArr.find(o => o.id === obj.id) || obj )

          let newState = {
            ...state,
            proyectos: newStateProyectos
          }

      return newState
    }

    case 'DELETE_IMAGEN_PROYECTO': {

      let idProyecto = action.payload.idProyecto,
          idImagen = action.payload.idImagen

      let proyectosSinProyecto = state.proyectos.filter( proyecto => proyecto.id !== idProyecto)

      let proyecto = state.proyectos.filter( proyecto => proyecto.id === idProyecto)[0]

      let newGalImgs = proyecto.galImgs.filter( galImgs => galImgs.idImagen !== idImagen)

      let newProyectoArr = [{
        ...proyecto,
        galImgs: newGalImgs
      }]

      let newProyectos = proyectosSinProyecto.concat(newProyectoArr)

      let newState = {
        ...state,
        proyectos: newProyectos
      }

      return newState

    }

    case 'ADD_PLANO_TO_PLANOS': {

      let newPlano = action.payload.plano,
          idProyecto = action.payload.idProyecto,
          proyecto = state.proyectos.filter( proyecto => proyecto.id === idProyecto)[0]

      if(proyecto.planos !== null){
        proyecto.planos.push(newPlano)
      }else{
        proyecto.planos = [newPlano]
      }

      let proyectoArr = [proyecto]

      let newStateProyectos = state.proyectos.map( obj => proyectoArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        proyectos: newStateProyectos
      }

      return newState

    }

    case 'DELETE_PLANO_PROYECTO': {

      let idProyecto = action.payload.idProyecto,
          idPlano = action.payload.idPlano

      let proyectosSinProyecto = state.proyectos.filter( proyecto => proyecto.id !== idProyecto)

      let proyecto = state.proyectos.filter( proyecto => proyecto.id === idProyecto)[0]

      console.log(proyecto)

      let newPlanos = proyecto.planos.filter( plano => plano.id !== idPlano)

      let newProyectoArr = [{
        ...proyecto,
        planos: newPlanos
      }]

      console.log(newProyectoArr)

      let newProyectos = proyectosSinProyecto.concat(newProyectoArr)

      let newState = {
        ...state,
        proyectos: newProyectos
      }

      return newState

    }


    //break;
    default:
      return state
  }
}

export default dataProyectos
