import React from 'react';
import './css/logIn.min.css';

import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let LogIn = props => (
  <div id="LogInContainer" className="bg-dark" style={{ minHeight: '100vh' }}>
    <nav id="navLogIn" className="navbar navbar-expand">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item active">
            <NavLink exact to="/" activeClassName="active" className="nav-link" >Inicio</NavLink>
          </li>
        </ul>
        <div id="contenedorIconsNavBar" className="row justify-content-center">
						<a className="NavBarIcon" href="https://www.facebook.com/pm502/">
							<FontAwesomeIcon icon={['fab', 'facebook-f']} />
						</a>
						<a className="NavBarIcon" href="#instagram">
							<FontAwesomeIcon icon={['fab', 'instagram']} />
						</a>
						<a className="NavBarIcon" href="#linkedin">
							<FontAwesomeIcon icon={['fab', 'linkedin-in']} />
						</a>
					</div>
      </div>
    </nav>
    {props.children}
  </div>
)

export default LogIn
