// JavaScript Document
import GraphQL from './ConsultaGraphQLProyectos';

import moment from 'moment';

import url from './ApiUrl.js';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchProyectos = (This) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL()),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		var data = res.data.appInfo.proyectos.map(data => {

			let planos = null

			if(data.planos !== null){
				if(data.planos.length > 0){
					planos = data.planos.map( plano => {
						let newPlano = {
							id: plano.id,
							nombreDePlano: plano.nombreDePlano,
							tipoPlano: plano.tipoPlano,
							fecha: new Date(parseInt(plano.fecha))
						}
						return newPlano
					})
				}
			}

			let dataArray = {
			  id: data.id,
			  idEmpresa: data.idEmpresa,
			  nombre: data.nombre,
			  pais: data.pais,
			  direccion: data.direccion,
			  telefono: data.telefono,
		   	  correo: data.correo,
			  presupuesto: data.presupuesto,
			  presupuestoGastado: data.presupuestoGastado,
			  informesSEP: data.informesSEP !== null ? data.informesSEP.map( informe =>{
				  let dataArrayInformeSEP = {
					idInformeSEP: informe.idInformeSEP,
					fecha: new Date(parseInt(informe.fecha))
				  }
				  return dataArrayInformeSEP
			  }) : data.informesSEP,
			  dataProyecto: data.dataProyecto,
			  dataFlujoProyecto: data.dataFlujoProyecto !== null ? (data.dataFlujoProyecto.filter( dataProyecto => {

				dataProyecto.Fecha = moment(parseInt(dataProyecto.Fecha)).format("DD/MM/YYYY")
			  
				return dataProyecto.Fecha !== null
			
			  })) : null,
			  logoProyecto: data.logoProyecto,
			  estado: data.estado,
			  fase: data.fase,
			  divisa: data.divisa !== null ? data.divisa : "Quetzales",
			  galImgs: data.galImgs,
			  planos
			}
			return dataArray
  
		})

		This.props.dispatch({
			type: 'FETCH_PROYECTOS',
			payload: {
				dataProyectos: data
			}
		})

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchProyectos;
