let consulta = (data) => {

  let proyectoData = JSON.stringify(data.proyectoData).replace(/{"/g, '{').replace(/":/g, ':').replace(/"informesSEP/g, 'informesSEP').replace(/"fecha/g, 'fecha').replace(/"nombre/g, 'nombre').replace(/"empresa/g, 'empresa')

  //console.log(proyectoData)

  const string =
    `mutation insertInformeSEPMessage{
      insertInformeSEP(
        proyectoData: ${proyectoData},
        informeSEP: "${data.informeSEP}"
      ){
        id
        informesSEP {
          idInformeSEP
          fecha
        }
        mensaje
      }
    }`

    return {
      "query": string
    }

}

export default consulta
