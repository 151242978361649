import React, { Component } from 'react';

import '../Components/css/Servicios.css';

import DesarrolloSVG from '../Components/Servicios/DesarrolloSVG.jsx';

import PlanificacionSVG from '../Components/Servicios/PlanificacionSVG.jsx';

import EjecucionSVG from '../Components/Servicios/EjecucionSVG.jsx';

import CierreSVG from '../Components/Servicios/CierreSVG.jsx';

class Servicios extends Component {

  render(){
    return (
      <section id="servicios" data-jarallax-element="0 0">{/*data-speed="1.5"*/}

        <div className="container">
          <div className="row">

            <div className="col-12" style={{marginTop: '100px'}}>
              <h1 className="text-center">Nuestros Servicios</h1>
              <p className="text-justify" style={{marginTop: '25px'}}>Nuestros principales clientes son inmobiliarias boutique e inversionistas. Desarrollamos estrategias a la medida de las necesidades de nuestros clientes, buscando de forma continua la innovación y la eficiencia.</p>
            </div>

            <div className="col-12">
              <div className="card-decks row">

                <div className="col-12 col-md-4 col-lg-3 mx-auto">
                  <div className="card">
                    <img src="/img/jpg/servicios/desarrollo.jpg" className="card-img" alt="desarrollos" />
                    <div className="card-body">
                      <h4 className="card-title text-center"><DesarrolloSVG /> Desarrollo</h4>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-lg-3 mx-auto">
                  <div className="card">
                    <img src="/img/jpg/servicios/planificacion.jpg" className="card-img" alt="planificacion" />
                    <div className="card-body">
                      <h4 className="card-title text-center"><PlanificacionSVG /> Planificación</h4>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-lg-3 mx-auto">
                  <div className="card">
                    <img src="/img/jpg/servicios/ejecucion.jpg" className="card-img" alt="ejecucion" />
                    <div className="card-body">
                      <h4 className="card-title text-center"><EjecucionSVG /> Ejecución</h4>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-lg-3 mx-auto">
                  <div className="card">
                    <img src="/img/jpg/servicios/cierre.jpg" className="card-img" alt="cierre" />
                    <div className="card-body">
                      <h4 className="card-title text-center"><CierreSVG /> Cierre</h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>
    )
  }

}

export default Servicios;
