import React from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let EmpresaEdit = props => {

  let empresa = props.empresa,
      empresaString = JSON.stringify(empresa),
      modalVisible = props.modalVisible,
      handleCloseModal = props.handleCloseModal

  return(
      <ModalContainer>
        <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
              <img className="brand-modal" src="/img/svg/LogoBlanco.svg" alt="LogoBiolabBlanco" />
              <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                <div></div>
                <div></div>
              </button>
              </div>

              <div className="modal-body sin-margen-y-padding container-fluid">
                <div className="row text-center">


                  <div className="col-12">
                    <FontAwesomeIcon icon="building" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                    <h1>Empresa: {empresa.nombre_comercial}</h1>
                  </div>
                  <div className="col-12 row" style={{'marginTop': '25px'}}>

                    <div className="col-md-4 row">
                      <label htmlFor="nombreComercialEmpresa" className="col"><b>Nombre Comercial:</b></label>
                      <input id="nombreComercialEmpresa" ref={props.setRef} data-empresa={empresaString} data-value-type="nombre_comercial" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.nombre_comercial} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="nombreFiscal" className="col"><b>Nombre Fiscal:</b></label>
                      <input id="nombreFiscal" ref={props.setRef} data-empresa={empresaString} data-value-type="nombre_fiscal" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.nombre_fiscal} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="TelefonoEmpresa" className="col"><b>Teléfono:</b></label>
                      <input id="TelefonoEmpresa" ref={props.setRef} data-empresa={empresaString} data-value-type="telefono" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.telefono} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="correo" className="col"><b>Correo:</b></label>
                      <input id="correo" ref={props.setRef} data-empresa={empresaString} data-value-type="correo" onChange={props.handleInputChange} type="email"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.correo} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="NIT" className="col"><b>NIT:</b></label>
                      <input id="NIT" ref={props.setRef} data-empresa={empresaString} data-value-type="NIT" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.NIT} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="Direccion" className="col"><b>Dirección:</b></label>
                      <input id="Direccion" ref={props.setRef} data-empresa={empresaString} data-value-type="direccion" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.direccion} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="tipoEmpresa" className="col"><b>Tipo de Empresa:</b></label>
                      <select id="tipoEmpresa" ref={props.setRef} data-empresa={empresaString}className="inputUserVal" onChange={props.handleInputChange} data-value-type="tipoEmpresa" value={empresa.tipo_empresa}>
                        <option value='proveedor'>proveedor</option>
                        <option value='consumidor'>consumidor</option>
                      </select>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="regimenFiscal" className="col"><b>Regimen Fiscal:</b></label>
                      <select id="regimenFiscal" ref={props.setRef} data-empresa={empresaString}className="inputUserVal" onChange={props.handleInputChange} data-value-type="regimenFiscal" value={empresa.regimen_fiscal}>
                        <option value='Regimen 5%'>Regimen 5%</option>
                        <option value='Regimen 25%'>Regimen 25%</option>
                      </select>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="CuentaDeBanco" className="col"><b>Cuenta De Banco:</b></label>
                      <input id="CuentaDeBanco" ref={props.setRef} data-empresa={empresaString} data-value-type="cuenta_banco" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.cuenta_banco} style={{'top': '-3px'}}/>
                    </div>


                  </div>

                </div>
                {
                    props.insertEmpresaMensaje !== null ? (
                      <div className="row" style={{marginTop: '25px'}}>
                        {
                          (props.insertEmpresaMensaje.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.insertEmpresaMensaje.mensaje}</div>
                          ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.insertEmpresaMensaje.mensaje}</div>
                          )
                        }
                      </div>
                    ) : null
                  }
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default mx-auto" onClick={props.handleSaveNewEmpresa}><FontAwesomeIcon icon="save" style={{'fontSize': '150%'}} /></button>
                <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
              </div>
            </div>
          </div>
      </ModalB4>
    </ModalContainer>
  )
}

export default EmpresaEdit
