import './globals'; // <-- first line

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/Containers/App.jsx';
import * as serviceWorker from './serviceWorker';

import { createStore } from 'redux';

import { Provider } from 'react-redux';

import reducer from './reducers/index.js';

let store = createStore(
  reducer,//(state) => state,
  {},//initialState,
  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

/*var consolere = {
  channel:'pm502-dev',
  api:'https://console.re/connector.js',
  ready: function(c) {var d=document,s=d.createElement('script'),l;s.src=this.api;s.id='consolerescript';s.setAttribute('data-channel', this.channel);s.onreadystatechange=s.onload=function(){if(!l){c();}l=true;};d.getElementsByTagName('head')[0].appendChild(s);}
};

consolere.ready( () => {} );*/

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
