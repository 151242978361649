import React from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../Components/Modal.jsx';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let EmpresaView = props => {

  let empresa = props.empresa,
      modalVisible = props.modalVisible,
      handleCloseModal = props.handleCloseModal

  return(
      <ModalContainer>
        <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
              <img className="brand-modal" src="/img/svg/LogoBlanco.svg" alt="LogoBiolabBlanco" />
              <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                <div></div>
                <div></div>
              </button>
              </div>

              <div className="modal-body sin-margen-y-padding container-fluid">
                <div className="row text-center">


                  <div className="col-12">

                    <div className="row">
                      {
                          empresa.logoEmpresa !== null ? (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" style={{marginTop: "25px"}}>
                              <img className="img-fluid" src={`data:image/png;base64, ${empresa.logoEmpresa}`} alt="logoEmpresa" style={{maxWidth: "150px"}}/>
                            </div>
                          ) : (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mx-auto" style={{marginTop: "25px"}}>
                              <img className="img-fluid" src="/img/svg/LogoEmpresa.svg" alt="logoEmpresa" style={{maxWidth: "150px"}} />
                            </div>
                          )
                        }
                      </div>

                      <div className="row">
                        <div className="col" style={{marginTop: "25px"}}>
                          {/*<FontAwesomeIcon icon="building" style={{'fontSize': '400%', 'marginTop': '25px'}} />*/}
                          <h1>Empresa: {empresa.nombre_comercial}</h1>
                        </div>
                      </div>

                  </div>
                  <div className="col-12 row" style={{'marginTop': '25px'}}>

                    <div className="col-md-4 row">
                      <p className="col" id="nombreEmpresa" style={{'marginTop': '0px'}}><b>Nombre Comercial:</b> {empresa.nombre_comercial}</p>
                    </div>

                    <div className="col-md-4 row">
                      <p className="col" id="nombreFiscal" style={{'marginTop': '0px'}}><b>Nombre Fiscal:</b> {empresa.nombre_fiscal}</p>
                    </div>

                    <div className="col-md-4 row">
                      <p className="col" id="TelefonoEmpresa" style={{'marginTop': '0px'}}><b>Teléfono:</b> {empresa.telefono}</p>
                    </div>

                    <div className="col-md-4 row">
                      <p className="col" id="CorreoEmpresa" style={{'marginTop': '0px'}}><b>Correo:</b> {empresa.correo}</p>
                    </div>

                    <div className="col-md-4 row">
                      <p className="col" id="NIT" style={{'marginTop': '0px'}}><b>NIT:</b> {empresa.NIT}</p>
                    </div>

                    <div className="col-md-4 row">
                      <p className="col" id="Direccion" style={{'marginTop': '0px'}}><b>Dirección:</b> {empresa.direccion}</p>
                    </div>

                    <div className="col-md-4 row">
                      <p className="col" id="Tipo" style={{'marginTop': '0px'}}><b>Tipo de Empresa:</b> {empresa.tipo_empresa}</p>
                    </div>

                    <div className="col-md-4 row">
                      <p className="col" id="regimen_fiscal" style={{'marginTop': '0px'}}><b>Régimen Fiscal:</b> {empresa.regimen_fiscal}</p>
                    </div>
                    
                    <div className="col-md-4 row">
                      <p className="col" id="cuenta_banco" style={{'marginTop': '0px'}}><b>Cuenta de Banco:</b> {empresa.cuenta_banco}</p>
                    </div>

                  </div>

                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
              </div>
            </div>
          </div>
      </ModalB4>
    </ModalContainer>
  )
}

export default EmpresaView
