import React, { Component } from 'react';

//import { Parallax } from 'react-scroll-parallax';

import '../Components/css/Inicio.css';

import Slider from "react-slick";

let settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	autoplay: true,
	autoplaySpeed: 4000,
	initialSlide: 0,
	arrows: false
}

class Inicio extends Component {

	render(){
		return(
			<section id="inicioSection" data-jarallax-element="150 0">{/*data-speed="-1"*/}
				{/*<div id="inicio" style={{position: 'relative', top: '0px'}} />*/}
				<div className="container-fluid sin-margen-y-padding">
					<div className="row sin-margen-y-padding">
						<div className="col-12 sin-margen-y-padding carousel slide">
							<Slider {...settings} className="carousel-inner">

								<div className="carousel-item active" style={{maxHeight: '100vh', overflow: 'hidden'}} >
									<div className="overlay"></div>
									<img src="/img/jpg/Inicio.jpg" alt="First" className="center-block img-fluid imageBack" />
									<div className="carousel-caption d-block d-md-block row" style={{color: '#000000'}}>
										<div className="col-7 col-sm-8 col-md-5 col-lg-3 mx-auto">
											<img src="/img/svg/Logo.svg" alt="Logo" className="img-fluid" />
										</div>
						        {/*<h5>First slide label</h5>
						        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
						      </div>
					      </div>

								{/*<div className="carousel-item" style={{maxHeight: '100vh', overflow: 'hidden'}} >
									<div className="overlay"></div>
					        <img src="/img/jpg/Inicio.jpg" alt="First" className="center-block img-fluid imageBack" />
									<div className="carousel-caption d-block d-md-block" style={{color: '#000000'}}>
										 <h5>First slide label</h5>
										 <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
										</div>
					      </div>*/}

							</Slider>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default Inicio;
